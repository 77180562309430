import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// External Libraries
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";

import { preserveOffsetOnSource } from '@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

// Local Utilities and Components
import {
  find,
  getChecklistStatus,
  getTextColor,
  isSafari,
  relative,
  rgbaColor,
  textTransform,
  toSlug,
} from '../../utils/helpers';
import { fetchCardMembers } from '../../redux/Slices/cardMemberSlice';
import { fetchLabels } from '../../redux/Slices/labelSlice';
import useOutsideClick from '../../hooks/useOutsideClick';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import MemberImage from '../Global/MemberImage';
import { cardData, updateCard } from '../../redux/Slices/cardSlice';
import { fetchBoardById } from '../../redux/Slices/boardSlice';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
// import CardPreview from './CardPreview';

const Card = ({ card, moveCard, moveActionList, setBoard }) => {
  // Destructure Card Properties
  const {
    _id,
    title,
    permalink,
    users,
    labels,
    watchers,
    checklists,
    description,
    comments,
    attachments,
    actionList,
    shortId,
    cover,
    order,
    priority,
    archived
  } = card || {};


  const members = users;

  // State Hooks
  const [isEditCard, setIsEditCard] = useState(false);
  const [cardTitle, setCardTitle] = useState(title || '');
  const [isDragging, setDragging] = useState(false);
  const [closestEdge, setClosestEdge] = useState(null);
  const [isHoverEnabled, setIsHoverEnabled] = useState(true);
  const [preview, setPreview] = useState(<div>Hellow world</div>);

  const { handlePopoverClick } = useHandlePopoverClick();

  const isDraggingRef = useRef(false);


  // Refs
  const cardTitleRef = useRef(null);
  const cardRef = useRef(null);

  // Redux and Router
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  // Intersection Observer to toggle hover
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHoverEnabled(entry.isIntersecting);
      },
      { threshold: 1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  // Drag and Drop Setup
  useEffect(() => {
    const element = cardRef.current;
    if (!element) return;

    // Setup Drag and Drop
    const combined = combine(
      // Draggable configuration
      draggable({
        element,
        dragHandle: element,
        getInitialData: () => ({ ...card, type: 'CARD' }),
        onGenerateDragPreview({ source, location, nativeSetDragImage }) {
          const data = source.data;
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: preserveOffsetOnSource({ element, input: location.current.input }),
            render({ container }) {

              const rect = element.getBoundingClientRect();
              const preview = element.cloneNode(true);
              preview.style.width = `${rect.width}px`;
              preview.style.height = `${rect.height}px`;

              // rotation of native drag previews does not work in safari
              if (!isSafari()) {
                preview.style.transform = 'rotate(4deg)';
              }

              container.appendChild(preview);
            },
          });
        },
        onDragStart() {
          isDraggingRef.current = true;
          // setDragging(true);
        },
        onDrag() {
          // console.log('dragging');
          setDragging(true);

        },
        onDragEnd() {
          isDraggingRef.current = false;
          // setDragging(false);
        },
        onDrop() {
          setDragging(false);
        },
      }),

      // Drop target configuration
      dropTargetForElements({
        element,
        // canDrop: (args) => args.source.data.type === "CARD",
        getIsSticky: () => true,
        getData: ({ input, element }) => {
          const data = { ...card, type: 'CARD' };
          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDragEnter: (args) => {


        },
        onDrag(args) {
          const { source, self } = args;

          if (!self || !source) return;

          // Extract closest edge for drag state
          const closestEdge = extractClosestEdge(self.data);
          if (!closestEdge) {
            return;
          }

          const targetListId = self.data.actionList?._id;
          const cardId = source.data._id;

          // Skip if dragging within the same card or invalid type
          if (self.data._id === source.data._id && source.data.type == 'CARD') {
            setDragging(true);
            return;
          }

          if (source.data.type === 'ACTIONLIST') {
            setDragging(false);
            // moveActionList(source.data._id, self.data.actionList?._id);
            return;
          }

          // Determine drag direction (up or down)
          const isMovingDown = source.data.order < self.data.order;
          let newOrder;
          let currentOrder;

          // console.log('Closest edge:', closestEdge);
          // console.log('Current order:', source.data.order, 'Target order:', self.data.order);

          if (isMovingDown && closestEdge === 'bottom') {
            // Moving down: Trigger when reaching the 'bottom' edge
            newOrder = self.data.order;
          } else if (!isMovingDown && closestEdge === 'top') {
            // Moving up: Trigger when reaching the 'top' edge
            newOrder = self.data.order;
          } else {
            return;
          }

          // console.log('New order calculated:', newOrder);


          // Move card only if the calculated order changes
          if (self.data.type === 'CARD' && currentOrder !== newOrder) {
            moveCard(cardId, targetListId, newOrder);
            currentOrder = newOrder; // Update the current order
          }

          // Optional: Maintain drag state for visual feedback
          setClosestEdge(closestEdge);
        },

        onDragLeave: () => {
          setClosestEdge(null);
          // setDragging(false);

        },
        onDrop: () => {
          setClosestEdge(null);
          setDragging(false);
        },
      })
    );

    // Cleanup on component unmount
    return combined;
  }, [card, cardRef.current, moveCard]);


  // Style Definitions
  const draggingStyle = {
    opacity: '0.1',
  };

  const style = {
    // ...(cardHighlight ? { border: '2px solid red' } : {}),
    ...(isDragging ? draggingStyle : {}),
    // transition: 'all 0.3s ease',
  };


  // Adjust Textarea Height and Focus
  useEffect(() => {
    if (isEditCard && cardTitleRef.current) {
      adjustTextareaHeight(cardTitleRef.current, cardTitle, 16);
      cardTitleRef.current.focus();
    }
  }, [isEditCard, cardTitle]);

  // Handle Click Outside to Update and Hide Form
  useOutsideClick(cardTitleRef, () => {
    handleUpdateCard();
    handleHideForm();
  });

  // Early Return if Card is Null or Undefined
  if (!card) return null;

  // Derived Variables
  const cardSlug = toSlug(title || '');
  const checklistsStatus = getChecklistStatus(checklists);

  // Handlers
  const handleUpdateCard = async () => {
    const updatedData = { title: cardTitle };
    await dispatch(updateCard({ cardId: shortId, updatedData })).unwrap();

    // Update the board's state
    setBoard((prevBoard) => ({
      ...prevBoard,
      actionLists: prevBoard.actionLists.map((actionList) => ({
        ...actionList,
        cards: actionList.cards.map((currentCard) => {
          return currentCard.shortId === shortId ? { ...currentCard, title: cardTitle } : currentCard;
        }
        ),
      })),
    }));

    setIsEditCard(false);
  };

  const handleHideForm = () => {
    setIsEditCard(false);
  };

  const adjustTextareaHeight = (textarea, content, minus) => {
    textarea.style.height = '24px';
    const scrollHeight = textarea.scrollHeight - minus;
    textarea.style.height = `${scrollHeight}px`;
  };

  const handleOpenCard = (e, cardId) => {
    navigate(relative(permalink));
  };

  // Determine if Footer Should Be Rendered
  const shouldRenderFooter =
    (watchers?.length > 0 && watchers.some(watcher => watcher._id === user?.user?._id)) ||
    !!description ||
    (comments?.length > 0) ||
    (checklistsStatus[1] > 0) ||
    (attachments?.length > 0) ||
    (members?.length > 0) || priority;

  const priorityText = textTransform(priority, 'lowercase');


  const coverColor = cover?.coverColor || [];

  return (
    <div
      className='card-wrapper'
      ref={cardRef}
    >

      <div
        className={`card-pemalink-wrapper${isEditCard ? ' edit-mode' : ''}`}
        style={style}
      >
        {isEditCard ? (
          <form onSubmit={handleUpdateCard}>
            <textarea
              ref={cardTitleRef}
              name="update-card"
              placeholder="Update card title"
              rows="1"
              value={cardTitle}
              onChange={(e) => setCardTitle(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();

                  // Prevent submission if the title is empty
                  if (cardTitle.trim() === '') {
                    return; // Optionally show a message if necessary
                  }

                  handleUpdateCard();
                }
              }}
              onFocus={(e) => e.target.select()}
              required
            />

          </form>
        ) : (
          <>
            <div className="zoobbe-card">
              {
                cover && cover.url && (
                  <div className="zoobbe-card-cover-image">
                    <img className='cover-image' src={cover.url} alt={cover.name} loading="lazy" />
                  </div>
                )
              }
              {labels && labels.length > 0 && (
                <div className="card-header">
                  <div className="card-labels">
                    {labels.map(label => (
                      <span
                        key={label._id}
                        style={{ backgroundColor: label.color, color: getTextColor(label.color) }}
                        className={`label ${toSlug(label.text)}-label`}
                        onClick={() => console.log(label.text)}
                        data-labelid={label._id}
                      >
                        {label.text}
                      </span>
                    ))}
                  </div>
                </div>
              )}


              <p onClick={() => setIsEditCard(true)}>{title}</p>
              {/* <p onClick={() => setIsEditCard(true)}>{title} <span style={{ color: 'red' }}>{order}</span></p> */}

              {shouldRenderFooter && (
                <div className="zoobbe-card-footer">
                  {members.length > 0 && (
                    <div className="avatars">
                      <MemberImage cardId={_id} members={members} type={'card'} size={28} />
                    </div>
                  )}

                  <div className="zoobbe-card-front-badges">
                    {watchers?.length > 0 && watchers.some(watcher => watcher._id === user?.user?._id) && (
                      <span className="badge-card-subscribe badge-icon">
                        <span className="material-icons-outlined">notifications</span>
                      </span>
                    )}
                    {description && (
                      <span className="badge-card-description badge-icon" >
                        <span className="material-icons-outlined">subject</span>
                      </span>
                    )}
                    {comments?.length > 0 && (
                      <span className="badge-card-comments badge-icon">
                        <span className="material-icons-outlined">mode_comment</span>
                        <span className="comments-count count-number">{comments.length}</span>
                      </span>
                    )}
                    {checklistsStatus[1] > 0 && (
                      <span className="badge-card-checklists badge-icon">
                        <span className="material-symbols-outlined">task_alt</span>
                        <span className="checklists-count count-number">{checklistsStatus[0]}/{checklistsStatus[1]}</span>
                      </span>
                    )}
                    {attachments?.length > 0 && (
                      <span className="badge-card-attachments badge-icon">
                        <span className="material-symbols-outlined">attach_file</span>
                        <span className="attachment-count count-number">{attachments.length}</span>
                      </span>
                    )}

                    <span className="card-priority" id={`popover-card-priority-${shortId}`} data-tooltip-content={priority} data-tooltip-position="top" onClick={(e) => handlePopoverClick(e, 'cardPriority', { cardId: shortId })} data-popover-trigger>
                      <svg className={`zoobbe-flag-${priorityText || 'normal'}`} xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M14.4 6l-.24-1.2c-.09-.46-.5-.8-.98-.8H6c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5.6l.24 1.2c.09.47.5.8.98.8H19c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-4.6z" /></svg>
                    </span>
                  </div>

                </div>
              )}
            </div>
            <div
              title={title}
              onClick={(e) => handleOpenCard(e, _id)}
              className="card-pemalink"
            ></div>
          </>
        )}
        {!isEditCard && (
          <div className="edit-card" onClick={() => setIsEditCard(true)}>
            <span className="material-symbols-outlined">edit</span>
          </div>
        )}
      </div>
    </div>
  );

};

export default Card;
