import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { togglePopover } from "../../redux/Slices/popoverSlice";
import { fetchBoardsByWorkspaceAndMember } from "../../redux/Slices/boardsSlice";

import './scss/popoverBoardList.scss';
import ImagePlaceholder from "../Global/ImagePlaceholder";
import Spinner from "../Global/Spinner";

const PopoverBoardList = ({ member, workspaceId }) => {
    const dispatch = useDispatch();

    // Access boards and status from Redux state
    const { memberBoards, status } = useSelector((state) => ({
        memberBoards: state.boards.memberBoards,
        status: state.boards.status,
    }));

    const { boards } = useSelector((state) => state.boards.memberBoards);



    useEffect(() => {
        // Fetch boards when the component mounts
        dispatch(fetchBoardsByWorkspaceAndMember({ workspaceId, memberId: member._id }));
    }, [dispatch, workspaceId, member._id]);

    return (
        <div className="board-list">
            <h2>Workspace boards</h2>
            <p>
                <strong>@{member.username}</strong> is a member of the following Workspace boards:
            </p>
            <ul>
                {status === "loading" ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <Spinner size={30} color="#3498db" speed={1.5} />
                    </div>
                ) : boards?.length > 0 ? (
                    boards.map((board, index) => (
                        <li key={index} className="board-item">
                            <Link
                                to={board.permalink}
                                onClick={() => {
                                    dispatch(togglePopover({
                                        contentId: null,
                                        position: { top: 0, left: 0 },
                                        targetId: null,
                                    }));
                                }}
                            >
                                <div className="board-thumbnail">
                                    <ImagePlaceholder size={30} text={board.title} onlineIndicator={false} />
                                </div>
                                <div className="board-title">{board.title}</div>
                            </Link>
                        </li>
                    ))
                ) : (
                    // Show message if no boards are found
                    <p>No boards available for this member.</p>
                )}
            </ul>
        </div>
    );
};

export default PopoverBoardList;
