import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { togglePopover } from "../../redux/Slices/popoverSlice";
import useHandlePopoverClick from "../../hooks/useHandlePopoverClick";
import "./scss/MemberRole.scss";
import { config } from "../../config";
import { fetchMembers } from "../../redux/Slices/memberSlice";
import { showToast } from "../../redux/Slices/toastSlice";
import { textTransform } from "../../utils/helpers";

const MemberRole = (props) => {
    const {
        memberId,
        currentRole,
        onSelectRole,
        memberDeleteTarget,
        totalAdmins,
        apiPath,
        typeId,
    } = props;

    const dispatch = useDispatch();
    const { handlePopoverClick } = useHandlePopoverClick();
    const { user } = useSelector((state) => state.user);

    // Determine API path based on the type
    const apiPathMap = {
        board: "boards",
        workspace: "workspace",
    };
    const _apiPath = apiPathMap[apiPath] || "workspace";

    // Role options for members
    const roles = [
        {
            value: "admin",
            label: "Admin",
            description: "Admins must have a confirmed email address.",
        },
        { value: "member", label: "Member" },
        {
            value: "observer",
            label: "Observer",
            description: `Add people with limited permissions to this ${apiPath}.`,
        },
    ];

    // Function to update the member's role
    const updateMemberRole = async (newRole, memberId, type, typeId) => {
        if (!typeId || !memberId) {
            dispatch(
                showToast({
                    message: `${type} Id or Member Id is empty`,
                    type: "error",
                })
            );
            return;
        }

        try {
            const token = localStorage.getItem("accessToken");

            const payload = {
                memberId,
                newRole: newRole.value,
                typeId,
            };

            const response = await fetch(
                `${config.API_URI}/api/${_apiPath}/${typeId}/member`,
                {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    credentials: 'include',
                    body: JSON.stringify(payload),
                }
            );

            const data = await response.json();
            const messageType = response.status === 200 ? "success" : "error";

            dispatch(showToast({ message: data.message, type: messageType }));
            if (response.status === 200) {
                dispatch(fetchMembers({ type, id: typeId }));
            }
        } catch (error) {
            console.error("Error updating member role:", error);
            dispatch(
                showToast({ message: "Error updating member role", type: "error" })
            );
        }
    };


    // Render role options
    const renderRoleOptions = () =>
        roles.map((role) => {
            const isAdminRestricted = totalAdmins < 2 && currentRole === "admin";
            const isDisabled = isAdminRestricted || currentRole === role.value;

            return (
                <div
                    key={role.value}
                    className={`popover-member-role popover-option ${currentRole === role.value ? "selected" : ""
                        } ${isDisabled ? "remove-disabled" : ""}`}
                    onClick={() => {
                        if (isDisabled || currentRole === role.value) return;
                        onSelectRole(role.value, memberId);
                        updateMemberRole(role, memberId, apiPath, typeId);
                        dispatch(
                            togglePopover({
                                contentId: null,
                                position: { top: 0, left: 0 },
                                targetId: null,
                            })
                        );
                    }}
                >
                    <span className="option-title">{role.label}</span>
                    {role.description && (
                        <span className="option-description">{role.description}</span>
                    )}

                    {/* {isAdminRestricted < 2 && (
                        <span className="option-description">
                            Boards must have at least one admin.
                        </span>
                    )} */}

                </div>
            );
        });

    // Render remove/leave option
    const renderRemoveOrLeaveOption = () => {
        const targetElement = document.getElementById(memberDeleteTarget);
        const apiUrl = `${config.API_URI}/api/${_apiPath}/${typeId}/member`;

        const isAdminRestricted = totalAdmins < 2 && currentRole === "admin";

        return (
            <div
                className={`popover-member-role popover-option ${isAdminRestricted ? "remove-disabled" : ""}`}
                onClick={() => {
                    if (isAdminRestricted) return;

                    handlePopoverClick(targetElement, "deleteAction", {
                        type: "MEMBER",
                        id: memberId,
                        url: apiUrl,
                        apiPath: _apiPath === 'boards' ? 'board' : 'workspace',
                        typeId,
                    });
                }}
            >
                {user?.user?._id === memberId ? (
                    <>
                        <span className="option-title">Leave {apiPath}</span>
                        {isAdminRestricted && (
                            <span className="option-description">
                                {textTransform(apiPath, 'capitalize')}s must have at least one admin.
                            </span>
                        )}
                    </>
                ) : (
                    <>
                        <span className="option-title">Remove from {apiPath}</span>
                        {isAdminRestricted && (
                            <span className="option-description">
                                {textTransform(apiPath, 'capitalize')}s must have at least one admin.
                            </span>
                        )}
                    </>
                )}
            </div>
        );
    };


    return (
        <div className="member-role-container">
            {renderRoleOptions()}
            {renderRemoveOrLeaveOption()}
        </div>
    );
};

export default MemberRole;
