import React, { useState } from 'react';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';

const FeedbackButton = () => {

    const { handlePopoverClick } = useHandlePopoverClick();


    return (
        <div className="zoobbe-feedback-container" data-tooltip-content="Feedback" data-tooltip-position="top">
            <button className="feedback-button" id='popover-feedback-button' onClick={(e) => handlePopoverClick(e, 'feedBack')} data-popover-trigger>
                <span className="material-symbols-outlined">
                    quick_phrases
                </span>
            </button>
        </div>
    )
}

export default FeedbackButton;