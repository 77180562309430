import { useEffect } from "react";

import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../services/firebase";
import { config } from "../../config";
import { updatePushToken } from "../../utils/notifications";

const PushNotification = () => {
    useEffect(() => {
        const requestPermission = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === "granted") {
                    const token = await getToken(messaging, { vapidKey: config.PUSH_NOTIFICATION_VAPID_KEY_PAIR_ID });

                    updatePushToken({ pushToken: token });
                    
                    console.log("FCM Token:", token);
                    // Send this token to your backend for later use
                }
            } catch (error) {
                console.error("Push notification error:", error);
            }
        };

        requestPermission();

        const unsubscribe = onMessage(messaging, (payload) => {
            console.log("Message received:", payload);
            new Notification(payload.notification.title, {
                body: payload.notification.body,
                icon: payload.notification.icon,
            });
        });

        return () => unsubscribe(); // Cleanup on unmount
    }, []);

    return null; // This component does not render UI
};

export default PushNotification;
