import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./scss/Settings.scss";

// Custom Components
import ProfileNavbar from "./ProfileNavbar";
import SearchSelect from "../Global/SearchSelect";

// Redux Actions & Utilities
import { showToast } from "../../redux/Slices/toastSlice";
import { fetchUserByUsername } from "../../redux/Slices/userSlice";
import { fetchUser } from "../../redux/Slices/thunks";

// Config & Helpers
import { config } from "../../config";
import { textTransform } from "../../utils/helpers";

// Custom Hooks & Context
import { useTheme } from "../../context/ThemeContext";

const Settings = () => {
    // Redux Hooks
    const dispatch = useDispatch();

    // Theme Context
    const { setTheme } = useTheme();

    // State Management
    const [settings, setSettings] = useState(null);
    const [slackSettings, setSlackSettings] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // Appearance Settings
    const [selectedThemeColor, setSelectedThemeColor] = useState("blue");
    const [selectedTheme, setSelectedTheme] = useState("system");
    const [highContrast, setHighContrast] = useState(false);

    // Localization Settings
    const [language, setLanguage] = useState("english");
    const [region, setRegion] = useState("Europe/London");

    // Notification Settings
    const [pushNotifications, setPushNotifications] = useState(false);
    const [slackNotifications, setSlackNotifications] = useState(false);
    const [emailNotifications, setEmailNotifications] = useState(true);
    const [slackConnected, setSlackConnected] = useState(null);

    // Options Data
    const languageOptions = [
        { value: "en", label: "English" },
        { value: "es", label: "Español" },
        { value: "zh", label: "中文 (Mandarin)" },
        { value: "ar", label: "العربية" },
        { value: "fr", label: "Français" },
        { value: "ru", label: "Русский" },
        { value: "pt", label: "Português" },
        { value: "de", label: "Deutsch" },
        { value: "ja", label: "日本語" },
        { value: "it", label: "Italiano" },
        { value: "ko", label: "한국어" },
        { value: "tr", label: "Türkçe" },
    ];

    const regionOptions = [
        { value: "Pacific/Honolulu", label: "(GMT-10:00) US/Hawaii (HST)" },
        { value: "America/Anchorage", label: "(GMT-09:00) US/Alaska (AKST)" },
        { value: "America/Los_Angeles", label: "(GMT-08:00) US/Pacific (PST)" },
        { value: "America/Denver", label: "(GMT-07:00) US/Mountain (MST)" },
        { value: "America/Chicago", label: "(GMT-06:00) US/Central (CST)" },
        { value: "America/New_York", label: "(GMT-05:00) US/Eastern (EST)" },
        { value: "America/Toronto", label: "(GMT-05:00) Canada/Toronto (EST)" },
        { value: "America/Sao_Paulo", label: "(GMT-03:00) Brazil/Sao Paulo (BRT)" },
        { value: "Atlantic/Azores", label: "(GMT-01:00) Azores (AZOT)" },
        { value: "Europe/London", label: "(GMT+00:00) UK/London (GMT)" },
        { value: "Europe/Berlin", label: "(GMT+01:00) Germany/Berlin (CET)" },
        { value: "Europe/Paris", label: "(GMT+01:00) France/Paris (CET)" },
        { value: "Europe/Athens", label: "(GMT+02:00) Greece/Athens (EET)" },
        { value: "Asia/Dubai", label: "(GMT+04:00) UAE/Dubai (GST)" },
        { value: "Asia/Kolkata", label: "(GMT+05:30) India/Kolkata (IST)" },
        { value: "Asia/Dhaka", label: "(GMT+06:00) Bangladesh/Dhaka (BST)" },
        { value: "Asia/Bangkok", label: "(GMT+07:00) Thailand/Bangkok (ICT)" },
        { value: "Asia/Shanghai", label: "(GMT+08:00) China/Shanghai (CST)" },
        { value: "Asia/Tokyo", label: "(GMT+09:00) Japan/Tokyo (JST)" },
        { value: "Australia/Sydney", label: "(GMT+11:00) Australia/Sydney (AEDT)" },
    ];

    const themeColors = [
        { name: "blue", code: "#0966ff" },
        { name: "purple", code: "#7f77f1" },
        { name: "sky-blue", code: "#1090e0" },
        { name: "pink", code: "#ee5e99" },
        { name: "violet", code: "#b660e0" },
        { name: "light-blue", code: "#6985ff" },
        { name: "orange", code: "#e16b16" },
        { name: "teal", code: "#0f9d9f" },
        { name: "brown", code: "#aa8d80" },
        { name: "green", code: "#3cb88b" },
    ];

    // Effects
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem("accessToken");
                const response = await fetch(`${config.API_URI}/api/users/me/settings`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch settings");
                }

                const { settings, slack } = await response.json();

                // Update all settings states
                setSettings(settings);
                setSlackSettings(slack);
                setSlackConnected(slack.connected);
                setSelectedThemeColor(settings.themeColor);
                setSelectedTheme(settings.preferredTheme);
                setLanguage(settings.language);
                setRegion(settings.region);
                setHighContrast(settings.highContrast);
                setPushNotifications(settings.pushNotifications);
                setSlackNotifications(settings.slackNotifications);
                setEmailNotifications(settings.emailNotifications);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    // Handlers
    const handleLanguage = (selectedOption) => {
        setLanguage(selectedOption.value);
        console.log("Selected language:", selectedOption.value);
    };

    const handleRegion = (selectedOption) => {
        setRegion(selectedOption.value);
        console.log("Selected region:", selectedOption.value);
    };

    const handleThemeColor = (color) => {
        document.documentElement.setAttribute("data-theme-color", color);
        setSelectedThemeColor(color);
    };

    const handleSaveSettings = async () => {
        setLoading(true);
        try {
            const accessToken = localStorage.getItem("accessToken");
            const response = await fetch(`${config.API_URI}/api/users/me/settings`, {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    preferredThemeColor: selectedThemeColor,
                    preferredTheme: selectedTheme,
                    language,
                    region,
                    highContrast,
                    pushNotifications,
                    slackNotifications,
                    emailNotifications,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to update settings");
            }

            dispatch(
                showToast({ message: "Settings saved successfully!", type: "success" })
            );
        } catch (error) {
            console.error("Error updating settings:", error);
            dispatch(
                showToast({ message: "Failed to save settings.", type: "error" })
            );
        } finally {
            setLoading(false);
        }
    };

    const handleSlackConnect = () => {
        window.location.href =
            "https://slack.com/oauth/v2/authorize?client_id=8510357759874.8519400330340&scope=im:write,chat:write,users:read,users:read.email&user_scope=";
    };

    const handleSlackDisconnect = async () => {
        try {
            const token = localStorage.getItem("accessToken");
            if (!token) {
                throw new Error("No access token found");
            }

            const response = await fetch(
                `${config.API_URI}/api/users/slack/auth/disconnect`,
                {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    credentials: 'include',
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to disconnect Slack");
            }

            setSlackConnected(false);
            dispatch(fetchUser());
        } catch (error) {
            console.error("Error disconnecting Slack:", error.message);
            alert(error.message);
        }
    };


    return (
        <>
            <ProfileNavbar />
            <div className="settings-container">
                {/* Theme Color Section */}
                <div className="setting-row">
                    <div className="setting-label">
                        <h2>Theme color</h2>
                        <p>Choose a preferred theme for the app.</p>
                    </div>
                    <div className="theme-colors">


                        {themeColors.map(({ name, code }) => (
                            <div
                                key={name}
                                className={`color-option ${selectedThemeColor === name ? "selected" : ""}`}
                                style={{
                                    backgroundColor: code,
                                    color: selectedThemeColor === name ? code : "inherit", // Ensuring outline matches the color
                                }}
                                onClick={() => handleThemeColor(name)}
                            >
                                {selectedThemeColor === name && (
                                    <span className="material-symbols-outlined checkmark">check</span>
                                )}
                            </div>
                        ))}

                    </div>
                </div>

                {/* Appearance Section */}
                <div className="setting-row">
                    <div className="setting-label">
                        <h2>Appearance</h2>
                        <p>Choose light or dark mode, or switch your mode automatically based on your system settings.</p>
                    </div>
                    <div className="appearance-options">
                        {["light", "dark", "system"].map((mode) => (
                            <div className="theme-mode" key={mode}>
                                <div
                                    className={`appearance-option ${selectedTheme === mode ? "selected" : ""}`}
                                    onClick={() => { setSelectedTheme(mode); setTheme(mode) }}
                                >
                                    <div className={`mode-preview ${mode}`}>{selectedTheme === mode && <span class="material-symbols-outlined checkmark">check</span>}</div>
                                </div>

                                <label className="mode-label">{textTransform(mode, 'capitalize')}</label>
                            </div>

                        ))}
                    </div>
                </div>
                <div className="setting-row">
                    <div className="setting-label">
                        <h2>Contrast</h2>
                        <p>Turn on and off high contrast text and borders.</p>
                    </div>
                    <div className="toggle-container full-deactivated">
                        <label className="toggle-switch">
                            <input type="checkbox" disabled checked={highContrast} onChange={() => setHighContrast(!highContrast)} />
                            <span className="slider"></span>
                        </label>
                        <span>High Contrast for increased accessibility</span>
                    </div>
                </div>

                <div className="setting-row">
                    <div className="setting-label">
                        <h2>Language & Region</h2>
                        <p>Customize your language and region.</p>
                    </div>
                    <div className="language-region-options">
                        <div className="group">
                            <h3>Language</h3>
                            <SearchSelect
                                options={languageOptions}
                                onSelect={handleLanguage}
                                placeholder="Select an option"
                                isGrouped={false}
                                defaultValue={language || 'en'}
                                isDisabled={true} // Disable region selection

                            />
                        </div>
                        <div className="group">
                            <h3>Region</h3>
                            <SearchSelect
                                options={regionOptions}
                                onSelect={handleRegion}
                                placeholder="Select an option"
                                isGrouped={false}
                                defaultValue={region || 'Europe/London'}
                                isDisabled={true} // Disable region selection
                            />
                        </div>
                    </div>
                </div>

                <div className="notification-settings">
                    <h2>Notification Preferences</h2>
                    <p>Choose how you want to stay updated.</p>

                    <div className="settings-inner-container">


                        {/* Push Notifications */}
                        <div className="setting-row">
                            <div className="setting-label">
                                <h3>
                                    <span class="material-symbols-outlined" style={{ color: "var(--brand-color)" }}>
                                        web_asset
                                    </span>
                                    Browser Notification
                                </h3>
                                <p>Turn on browser notifications to stay updated with important alerts and messages.</p>
                            </div>
                            <div className="toggle-container">
                                <label className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        checked={pushNotifications}
                                        onChange={() => setPushNotifications(!pushNotifications)}
                                    />
                                    <span className="slider"></span>
                                </label>
                                <span>Receive notifications for updates and messages</span>

                            </div>
                        </div>

                        {/* Slack Notifications */}
                        <div className="setting-row">
                            <div className="setting-label">
                                <h3>
                                    <svg width="24" height="24" viewBox="0 0 0.48 0.48" xmlns="http://www.w3.org/2000/svg" fill="none"><g fill-rule="evenodd" clip-rule="evenodd"><path fill="#E01E5A" d="M.074.339A.044.044 0 0 0 .118.295V.251H.074a.044.044 0 0 0-.045.044.044.044 0 0 0 .044.044zM.185.251a.044.044 0 0 0-.044.044v.11c0 .024.02.044.044.044A.044.044 0 0 0 .229.406V.295A.044.044 0 0 0 .185.251" /><path fill="#36C5F0" d="M.141.074a.044.044 0 0 0 .044.044h.044V.074A.044.044 0 0 0 .185.029a.044.044 0 0 0-.044.044zm.088.11A.044.044 0 0 0 .185.141H.074a.044.044 0 0 0-.045.044c0 .024.02.044.044.044h.111A.044.044 0 0 0 .228.185z" /><path fill="#2EB67D" d="M.295.229A.044.044 0 0 0 .339.185V.074A.044.044 0 0 0 .295.029a.044.044 0 0 0-.044.044v.111c0 .024.02.044.044.044zM.406.14a.044.044 0 0 0-.044.044v.044h.044A.044.044 0 0 0 .451.185.044.044 0 0 0 .406.141z" /><path fill="#ECB22E" d="M.251.295a.044.044 0 0 0 .044.044h.11A.044.044 0 0 0 .451.295.044.044 0 0 0 .406.251H.295a.044.044 0 0 0-.044.044m.088.11A.044.044 0 0 0 .295.362H.251v.044A.044.044 0 0 0 .295.45.044.044 0 0 0 .339.406" /></g></svg>
                                    Slack Notifications
                                </h3>
                                <p>Get Zoobbe notifications directly in your Slack DMs.</p>
                            </div>
                            <div className="connect-slack-container">

                                <div className={`connect-with-slack-notifications`}>
                                    <button
                                        type="button"
                                        className={`${slackConnected ? "slack-connected" : ""}`}
                                        onClick={slackConnected ? handleSlackDisconnect : handleSlackConnect}
                                    >
                                        <span className="material-symbols-outlined">
                                            {slackConnected ? "link_off" : "open_in_new"}
                                        </span>
                                        {slackConnected ? "Disconnect Slack" : "Connect with Slack"}
                                    </button>
                                </div>


                                {slackConnected && (
                                    <div className="toggle-container">
                                        <label className="toggle-switch">
                                            <input
                                                type="checkbox"
                                                checked={slackNotifications}
                                                onChange={() => setSlackNotifications(!slackNotifications)}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                        <span>Notify me via Slack</span>
                                    </div>
                                )}

                            </div>
                        </div>

                        {/* Email Notifications */}
                        <div className="setting-row">
                            <div className="setting-label">
                                <h3>
                                    <span class="material-symbols-outlined" style={{ color: "#238b5c" }}>
                                        mail
                                    </span>
                                    Email Notifications
                                </h3>
                                <p>Stay informed via email about account activity and updates.</p>
                            </div>
                            <div className="toggle-container full-deactivated">
                                <label className="toggle-switch">
                                    <input
                                        type="checkbox"
                                        checked={emailNotifications}
                                        onChange={() => setEmailNotifications(!emailNotifications)}
                                    />
                                    <span className="slider"></span>
                                </label>
                                <span>Get latest updates via email</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Contrast Section */}
                <div className="setting-row">
                    <div className="setting-label"> </div>
                    <button className="save-btn" onClick={handleSaveSettings} disabled={loading}>
                        {loading ? "Saving..." : "Save"}
                    </button>
                </div>

            </div >
        </>
    );
};

export default Settings;
