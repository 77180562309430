import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

export const fetchCardsByMemberId = createAsyncThunk(
  'cards/fetchCardsByMemberId',
  async (memberId, { getState }) => {
    const token = localStorage.getItem('accessToken');
    const response = await fetch(`${config.API_URI}/api/cards/${memberId}`, {
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch cards');
    }

    const data = await response.json();
    return data;
  }
);

const cardSlice = createSlice({
  name: 'cards',
  initialState: {
    cards: [],
    status: 'idle',
    error: null
  },
  reducers: {
    // You can add other synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCardsByMemberId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCardsByMemberId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cards = action.payload;
      })
      .addCase(fetchCardsByMemberId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default cardSlice.reducer;
