import React, { useState } from 'react';
import './scss/CreateWorkspace.scss';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import { useNavigate } from 'react-router-dom';
import ZoobbeSelect from '../Global/ZoobbeSelect';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { closeModal } from '../../redux/Slices/modalSlice';
import { config } from '../../config';

const CreateWorkspace = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceType, setWorkspaceType] = useState('engineering-it');
  const [workspaceDescription, setWorkspaceDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateWorkspace = async (e) => {
    e.preventDefault();
    if (!workspaceName) {
      dispatch(showToast({ message: 'Workspace name is required', type: 'error' }));
      return;
    }

    setLoading(true);

    const workspaceData = {
      name: workspaceName.trim(),
      type: workspaceType,
      description: workspaceDescription.trim(),
    };

    try {
      const token = localStorage.getItem('accessToken');
      if (!token) throw new Error('Authentication token not found');

      const response = await fetch(`${config.API_URI}/api/workspaces/me`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify(workspaceData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create workspace');
      }

      const data = await response.json();
      dispatch(showToast({ message: data.message, type: 'success' }));
      dispatch(closeModal());
      dispatch(fetchWorkspaces());
      navigate(`${data.workspace.shortLink}/boards`);
    } catch (error) {
      dispatch(showToast({ message: `Error: ${error.message}`, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const workspaceTypes = [
    { value: 'engineering-it', label: 'Engineering-IT' },
    { value: 'education', label: 'Education' },
    { value: 'marketing', label: 'Marketing' },
  ];

  const handleWorkspaceTypeSelect = (type) => {
    setWorkspaceType(type.value);
  };

  return (
    <div className='workspace-container'>
      <div className="create-workspace-form">
        <h1>Let's Build a Workspace</h1>
        <p>Boost your productivity by keeping all boards in one place.</p>
        <form onSubmit={handleCreateWorkspace}>
          {/* Workspace Name */}
          <div className="group">
            <h3 htmlFor="workspace-name">Workspace Name*</h3>
            <input
              type="text"
              id="workspace-name"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              placeholder="Enter Workspace Name"
              className={!workspaceName ? 'error' : ''}
            />
            {!workspaceName && (
              <p className="error-message">Workspace name is required</p>
            )}
          </div>

          {/* Workspace Type */}
          <div className="group">
            <h3 htmlFor="workspace-type">Workspace Type*</h3>
            <ZoobbeSelect
              options={workspaceTypes}
              defaultSelectedOption={0}
              onSelect={handleWorkspaceTypeSelect}
            />
          </div>

          {/* Workspace Description */}
          <div className="group">
            <h3 htmlFor="workspace-description">
              Workspace Description <span>(Optional)</span>
            </h3>
            <textarea
              id="workspace-description"
              value={workspaceDescription}
              onChange={(e) => setWorkspaceDescription(e.target.value)}
              placeholder="Describe your workspace (optional)"
            />
          </div>

          {/* Submit Button */}
          <button type="submit" disabled={!workspaceName || loading} className={(!workspaceName || loading) && 'disabled'}>
            {loading ? 'Creating...' : 'Create Workspace'}
          </button>
        </form>
      </div>
      {/* <div className='workspace-right-content'>
        <img src='/workspace.png' />
      </div> */}
    </div>
  );
};

export default CreateWorkspace;
