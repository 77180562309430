import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Thunk to fetch card members
export const fetchCardMembers = createAsyncThunk(
    'members/fetchCardMembers',
    async (cardId) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/members`, {
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            return { cardId, members: data };

        } catch (error) {
            console.error(error.message);
            return Promise.reject(error.message);
        }
    }
);

// Thunk to add a member to a card
export const addCardMember = createAsyncThunk(
    'members/addCardMember',
    async ({ cardId, memberId }) => {
        try {
            const token = localStorage.getItem('accessToken');

            const response = await fetch(config.API_URI + `/api/cards/${cardId}/addMember`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ memberId })
            });

            if (!response.ok) {
                throw new Error('Failed to add member');
            }

            const data = await response.json();

            return { cardId, member: data };
        } catch (error) {
            console.error(error.message);
            return Promise.reject(error.message);
        }
    }
);

// Thunk to remove a member from a card
export const removeCardMember = createAsyncThunk(
    'members/removeCardMember',
    async ({ cardId, memberId }) => {
        try {
            const token = localStorage.getItem('accessToken');

            const response = await fetch(config.API_URI + `/api/cards/${cardId}/removeMember`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ memberId })
            });

            if (!response.ok) {
                throw new Error('Failed to remove member');
            }

            return { cardId, memberId };
        } catch (error) {
            console.error(error.message);
            return Promise.reject(error.message);
        }
    }
);

const cardMembersSlice = createSlice({
    name: 'cardMembers',
    initialState: {
        membersByCard: {}, // Store members per cardId
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCardMembers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCardMembers.fulfilled, (state, action) => {
                const { cardId, members } = action.payload;
                state.membersByCard[cardId] = members;
                state.loading = false;
            })
            .addCase(fetchCardMembers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addCardMember.fulfilled, (state, action) => {
                const { cardId, member } = action.payload;
                if (!state.membersByCard[cardId]) {
                    state.membersByCard[cardId] = [];
                }
                state.membersByCard[cardId].push(member);
            })
            .addCase(removeCardMember.fulfilled, (state, action) => {
                const { cardId, memberId } = action.payload;
                state.membersByCard[cardId] = state.membersByCard[cardId].filter(member => member._id !== memberId);
            });
    }
});

export default cardMembersSlice.reducer;
