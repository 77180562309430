import React, { createContext, useState, useContext } from 'react';

const BoardContext = createContext();

export const BoardProvider = ({ children }) => {
    const [boardPermalink, setBoardPermalink] = useState('');
    const [boardState, setBoard] = useState({ actionLists: [] });


    return (
        <BoardContext.Provider value={{ boardPermalink, setBoardPermalink, boardState, setBoard }}>
            {children}
        </BoardContext.Provider>
    );
};

export const useBoard = () => useContext(BoardContext);
