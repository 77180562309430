import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './scss/BoardVisibility.scss';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { showToast } from '../../redux/Slices/toastSlice';
import { config } from '../../config';
import { togglePopover } from '../../redux/Slices/popoverSlice';

const BoardVisibility = ({ boardId, visibility, setBoard }) => {
    const [selectedVisibility, setSelectedVisibility] = useState('Private');
    const dispatch = useDispatch();

    const visibilityOptions = [
        {
            name: 'Private',
            description:
                'Only board members can see this board. Workspace admins can close the board or remove members.',
            icon: 'lock', // Replace with actual SVG or icon library
        },
        {
            name: 'Workspace',
            description:
                'All members of the Facebook, Inc Workspace can see and edit this board.',
            icon: 'group', // Replace with actual SVG or icon library
        },
        // {
        //     name: 'Organization',
        //     description:
        //         'All members of the organization can see this board. The board must be added to an enterprise Workspace to enable this.',
        //     icon: '📂', // Replace with actual SVG or icon library
        //     disabled: false,
        // },
        // {
        //     name: 'Public',
        //     description:
        //         'Anyone on the internet can see this board. Only board members can edit.',
        //     icon: 'globe', // Replace with actual SVG or icon library
        // },
    ];

    const handleBoardVisibility = async (option) => {
        if (option.disabled) return; // Prevent action if the option is disabled

        try {
            setSelectedVisibility(option.name); // Update selected visibility immediately

            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/boards/${boardId}/visibility`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ visibility: option.name }), // Send the selected visibility as payload
            });

            if (!response.ok) {
                throw new Error('Failed to update the board visibility');
            }


            if (response.ok) {
                dispatch(showToast({ message: 'Board visibility updated successfully!', type: 'success' }));
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
                setBoard((prevBoard) => ({
                    ...prevBoard,
                    visibility: option.name,
                }));

            }


        } catch (error) {
            dispatch(showToast({ message: 'Error updating board visibility: ' + error.message, type: 'error' }));
        }
    };


    return (
        <div className="change-visibility">
            <header className="change-visibility__header">
                <h3>Change visibility</h3>
            </header>
            <ul className="change-visibility__options">
                {visibilityOptions.map((option) => (
                    <li
                        key={option.name}
                        className={`change-visibility__option ${visibility === option.name ? 'selected' : ''
                            } ${option.disabled ? 'disabled' : ''}`}
                        onClick={() => handleBoardVisibility(option)}
                    >
                        <span className="material-symbols-outlined">{option.icon}</span>
                        <div className="details">
                            <h3>{option.name}</h3>
                            <p>{option.description}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BoardVisibility;
