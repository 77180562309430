import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updatePopoverPosition } from '../redux/Slices/popoverSlice';
import { getScrollableAncestors } from '../utils/helpers';

const usePopoverPositionUpdater = (targetId, popoverRef) => {
    const dispatch = useDispatch();

    const updatePosition = useCallback(() => {
        const target = document.getElementById(targetId);
        if (!target || !popoverRef.current) return;

        const rect = target.getBoundingClientRect(); // Target's position
        const popoverRect = popoverRef.current.getBoundingClientRect(); // Popover's size
        const viewportWidth = window.innerWidth; // Viewport width
        const viewportHeight = window.innerHeight; // Viewport height

        const spacing = 4; // Spacing between the popover and target

        // Calculate available space around the target
        const spaceTop = rect.top;
        const spaceBottom = viewportHeight - rect.bottom;
        const spaceLeft = rect.left;
        const spaceRight = viewportWidth - rect.right;


        // Determine the best position for the popover
        let top = 0;
        let left = 0;

        let positionedAncestor = target;
        while (positionedAncestor && window.getComputedStyle(positionedAncestor).position === "static") {
            positionedAncestor = positionedAncestor.parentElement;
        }

        // If no positioned ancestor found, assume it's not fixed
        const isFixed = positionedAncestor && window.getComputedStyle(positionedAncestor).position === "fixed";

        // Apply scroll offsets only if the element is NOT fixed
        const topOffset = isFixed ? 0 : window.scrollY;
        const leftOffset = isFixed ? 0 : window.scrollX;

        if (spaceBottom >= popoverRect.height) {
            // Prefer opening below
            top = rect.bottom + topOffset + spacing;
        } else if (spaceTop >= popoverRect.height) {
            // Open above if space below is insufficient
            top = rect.top + topOffset - popoverRect.height - spacing;
        } else {
            // If neither fits, fit vertically within viewport
            top = Math.max(topOffset, rect.bottom + topOffset + spacing);
            top = Math.min(top, viewportHeight + topOffset - popoverRect.height);
        }

        if (spaceRight >= popoverRect.width) {
            // Prefer opening to the right
            left = rect.right + leftOffset + spacing - rect.width;
        } else if (spaceLeft >= popoverRect.width) {
            // Open to the left if space on the right is insufficient
            left = rect.left + leftOffset - popoverRect.width - spacing + rect.width;
        } else {
            // If neither fits, fit horizontally within viewport
            left = Math.max(leftOffset, rect.left + leftOffset);
            left = Math.min(left, viewportWidth + leftOffset - popoverRect.width);
        }


        // Dispatch the position update
        dispatch(updatePopoverPosition({ top, left }));
    }, [dispatch, targetId, popoverRef]);




    useEffect(() => {
        if (!targetId || !popoverRef.current) return;

        const target = document.getElementById(targetId);
        const scrollableAncestors = getScrollableAncestors(target);

        updatePosition(); // Update position immediately

        // Attach scroll and resize listeners
        const handleUpdate = () => updatePosition();

        scrollableAncestors.forEach(ancestor => ancestor.addEventListener('scroll', handleUpdate));
        window.addEventListener('resize', handleUpdate);

        // Observe popover size changes
        const observer = new ResizeObserver(handleUpdate);
        observer.observe(popoverRef.current);

        return () => {
            scrollableAncestors.forEach(ancestor => ancestor.removeEventListener('scroll', handleUpdate));
            window.removeEventListener('resize', handleUpdate);
            observer.disconnect();
        };
    }, [targetId, updatePosition]);

    useEffect(() => {
        updatePosition(); // Recalculate position on changes
    }, [targetId, popoverRef, updatePosition]);
};

export default usePopoverPositionUpdater;
