import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideSnackBar } from '../../redux/Slices/snackbarSlice';
import './scss/SnackBar.scss';
import Spinner from './Spinner';

const SnackBar = () => {
  const dispatch = useDispatch();
  const { isOpen, message, type } = useSelector((state) => state.snackbar);
  const [isExiting, setIsExiting] = useState(false);
  const { uploadProgress } = useSelector((state) => state.attachments);
  const { percentage } = uploadProgress;

  useEffect(() => {
    if (isOpen) setIsExiting(false);
  }, [isOpen]);

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => dispatch(hideSnackBar()), 300);
  };

  if (!isOpen && !isExiting) return null;

  return (
    <div className={`snackbar snackbar-${type} ${isExiting ? 'snackbar-exit' : ''}`}>
      <div className="snackbar-content">
        {type === 'uploading' ? (
          <div className="progress-bar-container">
            <div className="progress-bar-header">
              <div className="icon-bar">
                {percentage === '100.00' ? (
                  <span className="material-icons success-icon">check_circle</span>
                ) : (
                  <>
                    <span className="material-icons import-icon">upload</span>
                    <Spinner size={35} color={`var(--brand-color)`} className={'uploading-spinner'} strokeWidth={5} speed={2.5} />
                  </>
                )}
              </div>
            </div>
            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: `${percentage}%` }}></div>
            </div>
            <div className="progress-bar-percentage">{percentage}%</div>
          </div>
        ) : (
          <span>{message}</span>
        )}
      </div>
      <button className="snackbar-close-btn" onClick={handleClose}>&times;</button>
    </div>
  );
};

export default SnackBar;
