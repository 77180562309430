import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { marked } from 'marked';
import socketIOClient from 'socket.io-client';


import { setBoardId, selectBoardId } from '../../redux/Slices/boardIdSlice';


import { find, sanitizeHtml, textTransform, toSlug, uploadFile } from '../../utils/helpers';
import { format } from 'date-fns';

import { config } from '../../config';

import CardDetailsSidebar from '../Workspaces/CardDetailsSidebar';
import SkeletonCardDetails from '../Skeletons/SkeletonCardDetails';

import NotFound from '../400';

import {
    fetchAttachments,
    uploadAttachment,
    editAttachment,
    deleteAttachment,
    resetStatus,
    setUploadProgress,
} from '../../redux/Slices/attachmentSlice';

import {
    mentionedMemberIds,
    resetMentionedIds,
} from "../../redux/Slices/mentionedSlice";

import './scss/editor.style.scss';

import CardMemberSlice, { fetchCardMembers, addCardMember, removeCardMember } from '../../redux/Slices/cardMemberSlice';
import Comments from './Comments';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import PopOver from '../Global/PopOver';
import Checklist from './Checklists';
import IconCardTitle from '../icons/IconCardTitle';
import IconDescription from '../icons/IconDescription';
import Attachments from './Attachments';
import CardMembers from './CardMembers';
import CardLabels from './CardLabels';
import Editor from '../LexicalEditor/Editor';

import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';

import { cardData, updateCard } from '../../redux/Slices/cardSlice';
import { fetchUser } from '../../redux/Slices/thunks';
import Checkbox from '../Global/Checkbox';
import { fetchCardById } from '../../redux/Slices/cardSlice';
import { fetchBoardById } from '../../redux/Slices/boardSlice';
import Activities from './Activities';
import { showToast } from '../../redux/Slices/toastSlice';
import { fetchMembers } from '../../redux/Slices/memberSlice';
import { Helmet } from 'react-helmet';
import MetaTags from '../Global/MetaTags';
import { ActionTypes } from '../../sockets/ActionTypes';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import { fetchChecklists } from '../../redux/Slices/checklistSlice';
import { fetchCardLabels } from '../../redux/Slices/labelSlice';
import { setCardMoveTarget } from '../../redux/Slices/moveCardSlice';
import { fetchBoardLists } from '../../redux/Slices/boardsSlice';
import { showSnackBar } from '../../redux/Slices/snackbarSlice';
import { useLightbox, Lightbox } from '../../hooks/useLightbox';


const CardDetails = ({ board, setBoard }) => {


    const { cardId } = useParams();

    const navigate = useNavigate(); // Use navigate hook
    const [isValid, setIsValid] = useState(true);

    // const [card, setCard] = useState({});
    const { card, status } = useSelector((state) => state.card);

    const [title, setTitle] = useState(card?.title || '');
    const [description, setDescription] = useState(card?.description || '');

    const [isModified, setIsModified] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [isTextareaFocused, setIsTextareaFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const { handlePopoverClick } = useHandlePopoverClick();

    const textAreaRef = useRef(null);
    const fileInputRef = useRef(null);

    const [actionListId, setActionListId] = useState(null);
    const [actionListTitle, setActionListTitle] = useState(null);

    const mentionedIds = useSelector((state) => state.mentioned.mentionedMemberIds);
    const { cardMembers, boardMembers, loading } = useSelector(state => state.member);
    const { labelsByCard } = useSelector((state) => state.labels);
    const labels = labelsByCard[cardId] || [];
    const { checklists } = useSelector((state) => state.checklists);
    const { activities } = useSelector((state) => state.activities);
    const initiators = activities
        .filter(activity => activity.actionType === "ADDED_COMMENT")
        .map(activity => activity.initiator);


    const members = cardMembers;

    const boardId = useSelector(selectBoardId);

    const dispatch = useDispatch();

    const { attachmentStatus, attachments, error } = useSelector((state) => state.attachments);

    const { user } = useSelector((state) => state.user);

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const { images, currentIndex, isOpen, closeLightbox, nextImage, prevImage } = useLightbox();


    useEffect(() => {
        if (status !== 'loading' && !loading) {
            setIsFirstLoad(false);
        }
    }, [status]);


    useEffect(() => {
        if (cardId) {
            dispatch(fetchCardById(cardId));
            setIsFirstLoad(true);
        }
    }, [cardId, dispatch]);

    useEffect(() => {
        if (card && !isEditingDescription) {
            setTitle(card.title);
            setDescription(card.description);
            setActionListId(card.actionList._id);
            setActionListTitle(card.actionList.title);
            dispatch(cardData(card));
            dispatch(setBoardId(card.board));
        }
    }, [card, isEditingDescription, dispatch]);


    useEffect(() => {
        if (!user) {
            dispatch(fetchUser());
        }
    }, []);

    useEffect(() => {
        dispatch(fetchAttachments(cardId)).then(() => {
            dispatch(resetStatus());
        });

    }, [attachmentStatus, dispatch, cardId]);


    useEffect(() => {
        updateCardDetails(cardId, { attachments });
    }, [attachments]);

    useEffect(() => {
        updateCardDetails(cardId, { cover: card?.cover });
    }, [card?.cover]);

    useEffect(() => {
        updateCardDetails(cardId, { users: cardMembers });
    }, [cardMembers]);

    useEffect(() => {
        updateCardDetails(cardId, { labels });
    }, [labelsByCard]);

    useEffect(() => {
        updateCardDetails(cardId, { checklists });
    }, [checklists]);

    useEffect(() => {
        updateCardDetails(cardId, { comments: initiators });
    }, [activities]);


    useEffect(() => {
        if (boardId && !boardMembers.length > 0) {
            dispatch(fetchMembers({ type: 'board', id: boardId }));
        }

    }, [boardId, dispatch]);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '22px';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }

    }, [title, isTextareaFocused]);



    const handleUpdateCard = async () => {
        const token = localStorage.getItem('accessToken');
        const sanitizedHtmlDes = description;

        // Save previous state for rollback if API call fails
        const previousTitle = title;
        const previousDescription = card.description;

        // Optimistically update the UI state
        setIsModified(false);
        dispatch(cardData({
            ...card,
            title,
            description: sanitizedHtmlDes,
        }));

        const updatedData = {
            ...card,
            title,
            description: sanitizedHtmlDes,
            mentionedIds
        }

        dispatch(updateCard({ cardId: card.shortId, updatedData }));
        dispatch(resetMentionedIds());

        updateCardDetails(cardId, { title, description: sanitizedHtmlDes });

    };

    const handleCloseCardPopup = (event) => {
        if (!card) {
            dispatch(fetchCardById(cardId));
        }
        if (event.target.classList.contains('zoobbe-card-modal-container') || event.target.closest('.close-card')) {
            navigate(card.boardLink);
        }
    };

    const [isChecked, setIsChecked] = useState(false);

    // Synchronize isChecked with the card's current status
    useEffect(() => {
        if (card?.dueDate?.status === 'Completed') {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [card]);

    useEffect(() => {
        if (!card) return;

        const currentStatus = card?.dueDate?.status;

        if (card?.dueDate) {
            let newStatus = 'Pending';

            if (isChecked) {
                newStatus = 'Completed';
            } else if (new Date(card.dueDate.date) < new Date()) {
                newStatus = 'Overdue';
            } else if (new Date(card.dueDate.date) >= new Date()) {
                newStatus = 'In-Progress';
            }

            if (currentStatus !== newStatus) {
                updateDueDateStatus(newStatus, card.dueDate);
            }
        }
    }, [isChecked]);

    const updateDueDateStatus = async (status, dueDate) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/duedate`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    ...dueDate,
                    status,
                }),
            });

            if (response.ok) {
                const updatedCard = await response.json();
                dispatch(cardData(updatedCard)); // Update the card in Redux store

            } else {
                console.error('Failed to update due date status');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const [isWatching, setIsWatching] = useState(false);

    useEffect(() => {
        if (card && user) {
            const idExists = card.watchers?.includes(user?.user?._id);
            setIsWatching(idExists);
        }

    }, [card, user]);


    useEffect(() => {
        const fetchBoardData = async () => {
            if (!card?.board || board?.actionLists?.length > 0) return;

            setIsLoading(true);

            try {
                // Fetch board data
                const boardData = await dispatch(fetchBoardById(card.board)).unwrap();

                // Set initial board with existing cards preserved
                setBoard((prevBoard) => ({
                    ...boardData,
                    actionLists: boardData.actionLists.map((list) => ({
                        ...list,
                        cards: prevBoard?.actionLists?.find((prevList) => prevList.id === list.id)?.cards || [],
                    })),
                }));

            } catch (error) {
                console.error("Error fetching the board:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBoardData();
    }, [card?.board]);

    useEffect(() => {
        const handleKeyDown = (event) => {

            if (event.key === 'Escape') {
                navigate(card.boardLink);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [card?.board]);

    const handleWatch = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const endpoint = isWatching ? 'unwatch' : 'watch';
            const response = await fetch(`${config.API_URI}/api/cards/${cardId}/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    cardId,
                }),
            });

            if (!response.ok) {
                dispatch(showToast({ message: `Failed to ${endpoint} the card`, type: 'error' }));
                throw new Error(`Failed to ${endpoint} the card`);
            }

            updateCardDetails(cardId, {
                watchers: isWatching
                    ? card.watchers?.filter((watcher) => watcher._id !== user?.user?._id) // Remove user
                    : [...(card.watchers || []), { _id: user?.user?._id }], // Add user
            });


            // Toggle watch state on success
            setIsWatching(!isWatching);

        } catch (error) {
            console.error(error);
        }
    };

    // Assuming `board` is stored in state and you have a `setBoard` function
    const updateCardDetails = (cardId, updatedCardData) => {
        setBoard((prevBoard) => ({
            ...prevBoard,
            actionLists: prevBoard.actionLists.map((actionList) => ({
                ...actionList,
                cards: actionList.cards?.map((card) =>
                    card.shortId === cardId
                        ? {
                            ...card,
                            ...updatedCardData, // Only updates existing card properties
                        }
                        : card
                ),
            })),
        }));
    };

    useEffect(() => {
        if (!user) return;

        const userId = user?.user?._id;
        const username = user.user.username;
        const socket = socketIOClient(config.API_URI);

        socket.emit('join', userId, username);

        socket.on('user-action', (data) => {
            const { targetListId, targetPosition, card, reqUser } = data;

            if (userId === reqUser) {
                return;
            }

            switch (data.action) {
                case ActionTypes.CARD_UPDATED:
                    dispatch(fetchCardById(cardId));
                    break;
                case ActionTypes.CARD_MOVED:
                    dispatch(fetchCardById(cardId));
                    dispatch(setCardMoveTarget({ targetListId, targetPosition, card, cardAction: 'MOVE' }));
                    dispatch(fetchBoardLists(card.board));
                    break;
                case ActionTypes.COMMENT_ADDED:
                case ActionTypes.COMMENT_DELETED:
                case ActionTypes.COMMENT_UPDATED:
                    dispatch(fetchActivities({ cardId }));
                    break;
                case ActionTypes.CHECKLIST_ADDED:
                case ActionTypes.CHECKLIST_UPDATED:
                case ActionTypes.CHECKLIST_DELETED:
                case ActionTypes.CHECKLIST_ITEM_ADDED:
                case ActionTypes.CHECKLIST_ITEM_DELETED:
                case ActionTypes.CHECKLIST_ITEM_UPDATED:
                    dispatch(fetchChecklists(cardId));
                    break;
                case ActionTypes.MEMBER_ADDED:
                case ActionTypes.MEMBER_REMOVED:
                    dispatch(fetchMembers({ type: 'card', id: cardId }));
                    updateCardDetails(cardId, { users: cardMembers });

                case ActionTypes.ATTACHMENT_ADDED:
                case ActionTypes.ATTACHMENT_UPDATED:
                case ActionTypes.ATTACHMENT_DELETED:
                    dispatch(fetchAttachments(cardId));
                    dispatch(fetchCardById(cardId));

                    break;
                case ActionTypes.LABEL_ADDED:
                case ActionTypes.LABEL_UPDATED:
                case ActionTypes.LABEL_DELETED:
                    dispatch(fetchCardLabels(cardId));
                    break;
                case ActionTypes.DUEDATE_ADDED:
                case ActionTypes.DUEDATE_UPDATED:
                case ActionTypes.DUEDATE_REMOVED:
                    dispatch(fetchCardById(cardId));
                    break;
                default:
                    console.log('Unhandled user action:', data.action);
            }
        });

        socket.on('uploadProgress', (data) => {
            dispatch(setUploadProgress(data));
        });

        return () => {
            socket.disconnect();
        };
    }, [dispatch, user]);

    useEffect(() => {
        if (isTextareaFocused && textAreaRef.current) {
            textAreaRef.current.focus();
            textAreaRef.current.select(); // Select all text
        }
    }, [isTextareaFocused]);


    const memberIds = members?.map(member => member?._id);
    const isUserMember = user && user.user ? memberIds.includes(user?.user?._id) : false;



    if (isFirstLoad && (status === 'loading' || loading)) {
        return <SkeletonCardDetails />;
    }


    if (!isValid) {
        return <NotFound />;
    }

    if (!cardId || !card) {
        return null;
    }

    const coverColor = card.cover.coverColor;


    return (
        <>

            <MetaTags
                title={`${title} on ${boardId} | Zoobbe`}
                ogUrl={card.parmalink}
            />

            <div className="zoobbe-card-wrapper">
                <div className='zoobbe-card-modal-container' onClick={handleCloseCardPopup}>

                    <div className="zoobbe-card-details">
                        <div className="close-card-container">
                            <div className='close-card'>
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>
                        {
                            card.cover.url && (
                                <div className="zoobbe-card-cover-image" style={{ background: coverColor[0] }} >
                                    <img className='cover-image' src={card.cover.url} alt={card.cover.name} loading="lazy" />
                                </div>
                            )
                        }

                        <div className="zoobbe-card-content">
                            <div className="zoobbe-card-details-body">
                                <div className={`zoobbe-card-details-header${card.cover.url ? ' with-cover' : ''}`}>
                                    <div className='card-heading'>
                                        <span className="material-symbols-outlined">
                                            video_label
                                        </span>
                                        <div className={`textarea-wrapper ${isTextareaFocused ? "focused" : ""}`}>
                                            {isTextareaFocused ? (
                                                <textarea
                                                    ref={textAreaRef}
                                                    className="zoobbe-card-title-textarea"
                                                    value={title}
                                                    onChange={(e) => {
                                                        setTitle(e.target.value);
                                                        updateCardDetails(cardId, { title: e.target.value });
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            handleUpdateCard();
                                                            setIsTextareaFocused(false);
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        handleUpdateCard();
                                                        setIsTextareaFocused(false);
                                                    }}
                                                    spellCheck={isTextareaFocused}
                                                />
                                            ) : (
                                                <h1
                                                    className="zoobbe-card-title-textarea"
                                                    onClick={() => setIsTextareaFocused(true)}
                                                >
                                                    {title}
                                                </h1>
                                            )}
                                        </div>
                                    </div>
                                    <div className="zoobbe-card-details-status">
                                        in list
                                        <span
                                            className='actionlist' id='popover-movecard'
                                            onClick={(e) => handlePopoverClick(e, 'moveCard')}
                                            data-tooltip-content="Move"
                                            data-tooltip-position="top"
                                            data-popover-trigger
                                        >{actionListTitle}</span>
                                    </div>
                                </div>
                                <div className="zoobbe-card-details-info">
                                    <div className='zoobbe-card-details-info-left'>
                                        <div className='card-details-top'>
                                            <CardMembers cardId={cardId} />

                                            <div className={`zoobbe-notifications ${isWatching ? 'watching' : ''}`}>
                                                <h5>Notifications</h5>
                                                <div
                                                    className='notifications-content'
                                                    onClick={handleWatch}
                                                >
                                                    <span className="material-icons-outlined">notifications</span>
                                                    <span className="zoobbe-watching">
                                                        {isWatching ? 'Notifying' : 'Notify'}
                                                    </span>
                                                    {isWatching && (
                                                        <div className="watching-checkmark">
                                                            <span className="material-symbols-outlined">check</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="zoobbe-priority">
                                                <h5>Priority</h5>
                                                <div className='priority-content'>

                                                    <div className="priority" id='popover-priority' onClick={(e) => handlePopoverClick(e, 'cardPriority', { cardId })} data-popover-trigger>

                                                        <svg className={`zoobbe-flag-${textTransform(card.priority, 'lowercase')}`} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M14.4 6l-.24-1.2c-.09-.46-.5-.8-.98-.8H6c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5.6l.24 1.2c.09.47.5.8.98.8H19c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-4.6z" /></svg>

                                                        {card.priority}

                                                    </div>
                                                </div>
                                            </div>


                                            {
                                                card?.dueDate?.date && (
                                                    <div className="zoobbe-duedate">
                                                        <h5>Due Date</h5>
                                                        <div className='duedate-content'>
                                                            <Checkbox
                                                                checked={isChecked}
                                                                onChange={(checked) => setIsChecked(checked)}
                                                            />

                                                            <div className="duedate" id='popover-duedate' onClick={(e) => handlePopoverClick(e, 'addDueDate')} data-popover-trigger>
                                                                <span className="zoobbe-watching">
                                                                    {format(card.dueDate.date, 'MMM dd')}, {card.dueDate.dueTime}
                                                                </span>
                                                                {
                                                                    card.dueDate.status === 'Completed' && (
                                                                        <span className="completed-text">Completed</span>
                                                                    )
                                                                }
                                                                {
                                                                    card.dueDate.status === 'Overdue' && (
                                                                        <span className="overdue-text">Overdue</span>
                                                                    )
                                                                }

                                                                <span className="material-icons-outlined">keyboard_arrow_down</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <CardLabels cardId={cardId} />


                                        </div>

                                        <div className={`zoobbe-description ${isEditingDescription ? 'currently-editing' : ''}`}>

                                            <div className="description-header">
                                                <h3>
                                                    <span className="material-symbols-outlined">
                                                        subject
                                                    </span>
                                                    Description
                                                </h3>

                                                <button className="edit-description-button" onClick={() => setIsEditingDescription(true)}>
                                                    <span className="material-symbols-outlined">
                                                        edit_note
                                                    </span>
                                                    Edit
                                                </button>

                                            </div>

                                            {!description && !isEditingDescription && (
                                                <div className="zoobbe-card-no-description" onClick={() => setIsEditingDescription(true)}>Add a more detailed description of the task here... </div>
                                            )}

                                            {!isEditingDescription && description && (
                                                <div
                                                    className="card-description-content"
                                                    dangerouslySetInnerHTML={{ __html: marked(card.description || '') }}
                                                    onClick={(e) => {
                                                        const anchor = e.target.closest('a');
                                                        const image = e.target.closest('img');

                                                        if (anchor) {
                                                            window.open(anchor.href, '_blank');
                                                            e.preventDefault();
                                                        }
                                                        else if (image) {
                                                            e.preventDefault();
                                                        }
                                                        else {
                                                            setIsEditingDescription(true);
                                                        }
                                                    }}
                                                />
                                            )}



                                            {isEditingDescription && card && (
                                                <>

                                                    <Editor
                                                        value={description}
                                                        setDescription={setDescription}
                                                        setIsEditingDescription={setIsEditingDescription}
                                                        handleUpdateCard={handleUpdateCard}
                                                        boardId={boardId}
                                                        cardId={cardId}
                                                    />


                                                </>
                                            )}
                                        </div>

                                        {
                                            attachments?.length > 0 && (
                                                <Attachments cardId={cardId} updateCardDetails={updateCardDetails} />
                                            )
                                        }

                                        <Checklist cardId={cardId} />

                                        <div className="zoobbe-card-details-footer">
                                            <Comments cardId={cardId} isActivityDetails={card.isActivityDetails} />
                                            <Activities activities={card.activities} cardId={cardId} />
                                        </div>
                                    </div>
                                    <div className='zoobbe-card-details-info-right'>
                                        <CardDetailsSidebar
                                            cardId={cardId}
                                            boardId={boardId}
                                            setBoard={setBoard}
                                            isArchived={card?.archived}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="space-bottom"></div>
                    </div>
                </div>

                <Lightbox  images={images} attachments={card?.attachments} currentIndex={currentIndex} isOpen={isOpen} closeLightbox={closeLightbox} nextImage={nextImage} prevImage={prevImage} />
            </div >
        </>
    );

};

export default CardDetails;
