import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Board from './Board';
import CardDetails from './CardDetails';

import './scss/index.scss';
import { useBoard } from '../../context/BoardContext';

function BoardWrapper() {
    const location = useLocation();
    const isCardPath = location.pathname.startsWith('/c/');

    const { boardState, setBoard } = useBoard();
    const { board, isLoading, selectedBackgroundUrl } = useSelector((state) => state.board);
    const { targetListId, targetPosition, card, cardAction } = useSelector((state) => state.moveCard);

    useEffect(() => {
        if (!card || !cardAction) return;

        setBoard((prevBoard) => {
            let cardToProcess = null;

            // Remove or find the card in existing lists
            const updatedActionLists = prevBoard.actionLists.map((list) => {
                if (cardAction === 'MOVE' && list.cards.some((c) => c._id === card._id)) {
                    return {
                        ...list,
                        cards: list.cards.filter((c) => {
                            if (c._id === card._id) {
                                cardToProcess = { ...c, actionList: { _id: targetListId } };
                                return false;
                            }
                            return true;
                        }),
                    };
                } else if (list._id === card?.actionList?._id) {
                    cardToProcess = { ...card, actionList: { _id: targetListId } };
                }
                return list;
            });

            if (!cardToProcess) return prevBoard;

            // Add the card to the target list
            const finalActionLists = updatedActionLists.map((list) => {
                if (list._id === targetListId) {
                    const newCards = [...list.cards];
                    const position = Math.min(targetPosition, newCards.length);
                    newCards.splice(position, 0, cardToProcess);

                    return {
                        ...list,
                        cards: newCards.map((c, index) => ({ ...c, order: index })),
                    };
                }
                return list;
            });

            return { ...prevBoard, actionLists: finalActionLists };
        });
    }, [targetListId, targetPosition, card, cardAction, setBoard]);

    return (
        <div
            id="zoobbe-board"
            style={{
                backgroundImage: isLoading || !board?.cover?.url ? 'none' : `url(${selectedBackgroundUrl || board.cover.url})`,
            }}
        >
            <Board board={boardState} setBoard={setBoard} />
            {isCardPath && <CardDetails board={boardState} setBoard={setBoard} />}
        </div>
    );
}

export default BoardWrapper;
