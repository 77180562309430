import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import './scss/activity.scss';
import ProfileNavbar from './ProfileNavbar';
import { config } from '../../config';
import Comment from '../Workspaces/Comment';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import Spinner from '../Global/Spinner';
import { marked } from 'marked';

const Activity = () => {
    const { user, loading: userLoading } = useSelector((state) => state.user);
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetchingMore, setIsFetchingMore] = useState(false); // New state for pagination loading
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef(null);
    const { userName } = useParams();

    const fetchUserActivities = useCallback(async (pageNumber) => {
        if (!hasMore) return;

        const token = localStorage.getItem('accessToken');
        if (!token) {
            setIsLoading(false);
            return;
        }

        if (pageNumber === 1) {
            setIsLoading(true);
        } else {
            setIsFetchingMore(true); // Show spinner for additional fetches
        }

        try {
            const response = await fetch(`${config.API_URI}/api/users/${userName}/activities?page=${pageNumber}&limit=20`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch activities');
            }

            const data = await response.json();
            setActivities((prev) => [...prev, ...data.activities]);
            setHasMore(data.activities.length > 0); // Stop fetching if no more data
        } catch (error) {
            console.error('Error fetching user activities:', error);
        } finally {
            if (pageNumber === 1) {
                setIsLoading(false);
            }
            setIsFetchingMore(false); // Hide spinner after additional fetches
        }
    }, [userName, hasMore]);

    useEffect(() => {
        fetchUserActivities(page);
    }, [page, fetchUserActivities]);

    const lastActivityRef = useCallback(
        (node) => {
            if (isFetchingMore) return;

            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [isFetchingMore, hasMore]
    );

    if (!user) {
        return <div>User not found.</div>;
    }

    return (
        <div className={`profile-page${isLoading ? ' card-loading' : ''}`}>
            <ProfileNavbar />
            <div className="activity-container">
                <div className="table-body">
                    {(isLoading || userLoading) && <Spinner size={30} color="#3498db" speed={1.5} />}

                    {!(isLoading || userLoading) && activities.length > 0 && (
                        <div className="activities">
                            {activities.map((activity, index) => {
                                if (index === activities.length - 1) {
                                    return (
                                        <div ref={lastActivityRef} key={activity._id} className="activity">
                                            {activity.initiator?.profilePicture ? (
                                                <img
                                                    src={activity.initiator.profilePicture}
                                                    alt={activity.initiator.name}
                                                    className="activity__profile-img"
                                                    loading="lazy"
                                                />
                                            ) : (
                                                <ImagePlaceholder size={35} member={activity.initiator} text={activity.initiator.name} />
                                            )}

                                            <div className="activity__content">
                                                <div className="activity__header">
                                                    <strong>{activity.initiator.name}</strong>
                                                    <div className="activity__details" dangerouslySetInnerHTML={{ __html: marked(activity.details) }} />
                                                </div>

                                                <span>
                                                    {new Date(activity.createdAt).toLocaleString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: true
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={activity._id} className="activity">
                                            {activity.initiator?.profilePicture ? (
                                                <img
                                                    src={activity.initiator.profilePicture}
                                                    alt={activity.initiator.name}
                                                    className="activity__profile-img"
                                                    loading="lazy"
                                                />
                                            ) : (
                                                <ImagePlaceholder size={35} member={activity.initiator} text={activity.initiator.name} />
                                            )}

                                            <div className="activity__content">
                                                <div className="activity__header">
                                                    <strong>{activity.initiator.name}</strong>
                                                    <div className="activity__details" dangerouslySetInnerHTML={{ __html: marked(activity.details) }} />
                                                </div>

                                                <span>
                                                    {new Date(activity.createdAt).toLocaleString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: true
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}

                    {/* Show Spinner when fetching more data */}
                    {isFetchingMore && (
                        <div className="loading-more" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spinner size={30} color="#3498db" speed={1.5} />
                        </div>
                    )}
                </div>
            </div>

            {!isLoading && !userLoading && activities.length === 0 && (
                <div className="empty-activity">
                    <h2>No activities yet</h2>
                    <p>You haven't performed any activities yet.</p>
                </div>
            )}
        </div>
    );
};

export default Activity;
