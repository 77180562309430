import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
    COMMAND_PRIORITY_LOW,
    PASTE_COMMAND,
    $getSelection,
    $createTextNode,
    $insertNodes,
} from "lexical";
import { LinkNode } from "@lexical/link";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isValidFile } from "../../../utils/helpers";
import { config } from "../../../config";
import { togglePopover } from "../../../redux/Slices/popoverSlice";
import { setUploadProgress, uploadAttachment } from "../../../redux/Slices/attachmentSlice";
import { showSnackBar } from "../../../redux/Slices/snackbarSlice";
import { fetchCardById } from "../../../redux/Slices/cardSlice";
import { INSERT_IMAGE_COMMAND } from "./ImagesPlugin";

export default function PasteImagePlugin({ cardId, type }) {
    const [editor] = useLexicalComposerContext();
    const dispatch = useDispatch();

    // const fetchAttachment = async (cardId, attachmentId) => {
    //     try {
    //         const token = localStorage.getItem("accessToken");
    //         const response = await fetch(`${config.API_URI}/api/cards/attachments/${cardId}/${attachmentId}`, {
    //             method: "GET",
    //             headers: { Authorization: `Bearer ${token}` },
    //             credentials: "include",
    //         });

    //         if (!response.ok) throw new Error("Failed to fetch attachment");

    //         const data = await response.json();
    //         return data.attachment.url;
    //     } catch (error) {
    //         console.error("Error fetching attachment:", error);
    //         return null;
    //     }
    // };

    const insertImageInEditor = (imageUrl, fileName) => {
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, { altText: fileName || "Uploaded Image", src: imageUrl });
        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
    };

    const insertAttachmentLink = (fileUrl, fileName) => {
        editor.update(() => {
            const selection = $getSelection();
            if (selection) {
                const textNode = $createTextNode(fileName);
                const linkNode = new LinkNode(fileUrl);
                linkNode.append(textNode);
                $insertNodes([linkNode]);
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
            }
        });
    };

    const fetchAttachment = (cardId, attachmentId) => {
        return `${config.API_URI}/api/static/cards/attachments/${cardId}/${attachmentId}`;
    };

    const handleFileUpload = async (file) => {
        try {
            if (!file) return;

            const validation = isValidFile(file);
            if (!validation.valid) {
                return dispatch(showSnackBar({ message: validation.error, type: "error" }));
            }

            dispatch(showSnackBar({ message: "Uploading files...", type: "uploading" }));
            dispatch(setUploadProgress({ percentage: "0", remainingTime: "" }));

            const response = await dispatch(uploadAttachment({ file, cardId, type }));

            if (response.meta.requestStatus !== "fulfilled") {
                throw new Error(response.payload?.message || "File upload failed.");
            }

            const fetchedUrl = fetchAttachment(cardId, response.payload.attachment._id);
            if (!fetchedUrl) throw new Error("Failed to retrieve uploaded file URL.");

            file.type.startsWith("image/") ? insertImageInEditor(fetchedUrl, file.name) : insertAttachmentLink(fetchedUrl, file.name);

            dispatch(fetchCardById(cardId));
        } catch (error) {
            console.error("File upload error:", error);
            dispatch(showSnackBar({ message: error.message || "An error occurred while uploading the file.", type: "error" }));
        }
    };

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                PASTE_COMMAND,
                (event) => {
                    const items = event.clipboardData?.items;
                    if (!items) return false;

                    for (const item of items) {
                        if (item.type.startsWith("image/")) {
                            event.preventDefault();
                            const file = item.getAsFile();
                            if (file) handleFileUpload(file);
                            return true;
                        }
                    }
                    return false;
                },
                COMMAND_PRIORITY_LOW
            )
        );
    }, [editor, cardId, type, dispatch]);

    return null;
}
