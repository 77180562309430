import React, { useContext } from "react";
import { OnlineStatusContext } from "../../context/OnlineStatusContext";

const MemberOnlineStatus = ({ member }) => {
    const onlineUsers = useContext(OnlineStatusContext);
    const isOnline = onlineUsers[member._id] ?? member.online; // Fallback to initial online status

    return (
        <div className={`online-indicator ${isOnline ? "online" : "offline"}`}></div>
    );
};

export default MemberOnlineStatus;
