import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

const ThemeContext = createContext();

// Apply theme immediately to prevent flickering
const applyTheme = (theme) => {
    if (theme === 'system') {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        document.documentElement.setAttribute('data-theme', prefersDark ? 'dark' : 'light');
    } else {
        document.documentElement.setAttribute('data-theme', theme);
    }
};

// **Preload theme before rendering**
const savedTheme = localStorage.getItem('theme') || 'system';
applyTheme(savedTheme);

export const ThemeProvider = ({ children }) => {
    const { user } = useSelector((state) => state.user);

    // **Handle case where user or user.user is undefined**
    const settings = user?.user?.settings || {};

    // Load theme from localStorage first
    const [theme, setTheme] = useState(savedTheme);

    // Sync theme with Redux settings once available
    useEffect(() => {
        if (settings.preferredTheme && settings.preferredTheme !== theme) {
            setTheme(settings.preferredTheme);
            localStorage.setItem('theme', settings.preferredTheme);
            applyTheme(settings.preferredTheme);
        }
    }, [settings.preferredTheme]);

    useEffect(() => {
        applyTheme(theme);
        localStorage.setItem('theme', theme);

        if (theme === 'system') {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            const handleChange = () => applyTheme('system');
            mediaQuery.addEventListener('change', handleChange);
            return () => mediaQuery.removeEventListener('change', handleChange);
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
