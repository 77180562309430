import { useState, useEffect } from "react";
import { format } from "date-fns";

import "../components/Global/scss/Lightbox.scss";

function useLightbox(selector = ".zoobbe-description:not(.currently-editing), .card-comment-content") {
    const [images, setImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClick = (event) => {
            if (event.target.tagName !== "IMG") return;

            const selectors = selector.split(",").map(s => s.trim());
            const closestMatch = selectors.some(sel => event.target.closest(sel));

            if (!closestMatch) return;

            const allImages = selectors.flatMap(sel =>
                [...document.querySelectorAll(`${sel} img`)].map(img => img.src)
            );

            const index = allImages.indexOf(event.target.src);
            if (index !== -1) {
                setImages(allImages);
                setCurrentIndex(index);
                setIsOpen(true);
            }
        };

        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
    }, [selector]);

    const closeLightbox = () => setIsOpen(false);
    const nextImage = () => setCurrentIndex(i => (i + 1) % images.length);
    const prevImage = () => setCurrentIndex(i => (i - 1 + images.length) % images.length);

    return { images, currentIndex, isOpen, closeLightbox, nextImage, prevImage };
}



function Lightbox({ images, attachments, currentIndex, isOpen, closeLightbox, nextImage, prevImage }) {
    if (!isOpen || images.length === 0) return null;

    const currentAttachment = attachments?.[currentIndex] || {};


    // Extract dynamic values
    const fileName = currentAttachment.name || "Unknown File";
    const dateAdded = currentAttachment.uploadedAt
        ? format(new Date(currentAttachment.uploadedAt), "MMM d, yyyy, h:mm a")
        : "Unknown Date";
    const fileSize = currentAttachment.size
        ? `${(currentAttachment.size / 1024).toFixed(2)} KB`
        : "Unknown Size";

    return (
        <div className="lightbox-overlay" onClick={closeLightbox}>
            <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-btn" onClick={closeLightbox}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    </svg>
                </button>
                <div className="lightbox-header">
                    <span>{currentIndex + 1} / {images.length}</span>
                </div>
                {/* {
                    currentAttachment.fileType === 'IMAGE' ? (
                        <img src={images[currentIndex]} className="lightbox-image" />
                    ) : (
                        <a href={images[currentIndex]} className="lightbox-image">{currentAttachment.name} {currentAttachment.fileType}</a>
                    )
                } */}

                <img src={images[currentIndex]} className="lightbox-image" loading="lazy"/>


                {/* File Information */}
                <div className="lightbox-info">
                    <p className="file-name">{fileName}</p>
                    <p className="file-details">Added {dateAdded}</p>
                </div>

                {
                    images.length > 1 && (
                        <>
                            <button className="prev-btn" onClick={prevImage}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                </svg>
                            </button>
                            <button className="next-btn" onClick={nextImage}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                </svg>
                            </button>
                        </>
                    )
                }

            </div>
        </div >
    );
}


export { useLightbox, Lightbox };
