import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import "./scss/Tooltip.css";

const Tooltip = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [content, setContent] = useState(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [tooltipPosition, setTooltipPosition] = useState("top");
    const timerRef = useRef(null);
    const location = useLocation(); // Detects route changes

    useEffect(() => {
        const handleMouseEnter = (e) => {
            const target = e.target.closest("[data-tooltip-content]");
            if (target) {
                // Clear any previous timer
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }

                // Setup a timer to show the tooltip after 1 second (1000ms)
                timerRef.current = setTimeout(() => {
                    const rect = target.getBoundingClientRect();
                    const tooltipContent = target.getAttribute("data-tooltip-content");
                    const tooltipPos = target.getAttribute("data-tooltip-position") || "bottom";

                    let top = rect.top + window.scrollY;
                    let left = rect.left + window.scrollX + rect.width / 2;

                    switch (tooltipPos) {
                        case "top":
                            top -= 20;
                            break;
                        case "bottom":
                            top += rect.height + 18;
                            break;
                        case "left":
                            left = rect.left + window.scrollX - (rect.width + 8);
                            top = rect.top + window.scrollY + rect.height / 2;
                            break;
                        case "right":
                            left = rect.right + window.scrollX + 18;
                            top = rect.top + window.scrollY + rect.height / 2;
                            break;
                        default:
                            break;
                    }

                    setContent(tooltipContent);
                    setTooltipPosition(tooltipPos);
                    setPosition({ top, left });
                    setIsVisible(true);
                }, 300); // 1 second delay
            }
        };

        const handleMouseLeave = (e) => {
            const target = e.target.closest("[data-tooltip-content]");
            if (target) {
                // Cancel the tooltip display if the timer is active
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = null;
                }
                setIsVisible(false);
            }
        };

        document.addEventListener("mouseover", handleMouseEnter);
        document.addEventListener("mouseout", handleMouseLeave);

        return () => {
            document.removeEventListener("mouseover", handleMouseEnter);
            document.removeEventListener("mouseout", handleMouseLeave);
        };
    }, []);

    // Hide tooltip on route change
    useEffect(() => {
        setIsVisible(false);
    }, [location]);

    if (!isVisible) return null;

    return ReactDOM.createPortal(
        <div
            className={`tooltip tooltip-${tooltipPosition} ${isVisible ? "tooltip-visible" : ""}`}
            style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
                transform: "translate(-50%, -50%)",
            }}
        >
            {content}
        </div>,
        document.body
    );
};

export default Tooltip;
