import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../redux/Slices/thunks';
import ProfileDropdown from '../Profile/ProfileDropdown';
import Notifications from '../Global/Notifications';
import useOutsideClick from '../../hooks/useOutsideClick';
import './index.scss'; // Assuming you have a CSS file for styles
import { config } from '../../config';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import { openModal } from '../../redux/Slices/modalSlice';
import SearchResults from './SearchResults';
import Logo, { LogoLight } from './Logo';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import Tooltip from '../Global/Tooltip';
import { setSelectedBackground } from '../../redux/Slices/boardSlice';
import { useTheme } from '../../context/ThemeContext';
import MemberOnlineStatus from '../Global/MemberOnlineStatus';

// Initialize socket outside of the component to prevent re-initialization on each render
const socket = io(config.API_URI);

// import useOnlineStatus from '../../hooks/useOnlineStatus';


const Header = () => {
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [isShowNotifications, setIsShowNotifications] = useState(false);
  const [newNotification, setNewNotification] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isShowSearchResult, setSearchResult] = useState(false);
  const [query, setQuery] = useState(""); // Search query
  const [activeNav, setActiveNav] = useState(null);

  const location = useLocation();
  const profileRef = useRef(null);
  const notificationsRef = useRef(null);
  const searchRef = useRef(null);
  const searchIconRef = useRef(null);
  const workspaceRef = useRef(null);
  const recentRef = useRef(null);
  const starredRef = useRef(null);

  const { board, isLoading } = useSelector((state) => state.board);
  const { theme } = useTheme();

  const { handlePopoverClick } = useHandlePopoverClick();

  const dispatch = useDispatch();
  const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);

  const [systemTheme, setSystemTheme] = useState('light'); // Default to light


  useEffect(() => {
    // Check the system theme and set it
    const updateSystemTheme = () => {
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setSystemTheme(isDarkMode ? 'dark' : 'light');
    };

    // Initial check
    updateSystemTheme();

    // Add a listener for changes in the system theme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateSystemTheme);

    // Cleanup the event listener on unmount
    return () => {
      mediaQuery.removeEventListener('change', updateSystemTheme);
    };
  }, []);

  // Determine which logo to display
  const currentTheme = theme === 'system' ? systemTheme : theme;


  useEffect(() => {
    if (!user) return;

    const userId = user?.user?._id;
    socket.emit('join', userId);

    const fetchNotificationCount = async () => {
      const count = await getNewNotificationsCount();
      if (count !== undefined) {
        setNewNotification(count);
      }
    };

    fetchNotificationCount();

    socket.on('newNotification', async (notification) => {
      console.log('New notification received:', notification);
      setNewNotification(prevCount => {
        const updatedCount = prevCount + 1;
        addNewNotificationsCount(updatedCount);
        return updatedCount;
      });
    });

    return () => {
      socket.off('newNotification');
    };
  }, [user]);


  useEffect(() => {

    if (!location.pathname.startsWith('/b/')) {
      dispatch(setSelectedBackground(''));
    }
  }, [location.pathname]);

  const handleShowProfile = () => setIsShowProfile(prev => !prev);
  const handleShowSearchResult = () => setSearchResult(prev => !prev);

  const handleShowNotifications = () => {
    setIsShowNotifications(prev => !prev);
    setNewNotification(0);
    addNewNotificationsCount(0);
  };

  const handleCreateWorkspace = () => {
    dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
  };


  const handleNavClick = (e, nav, props = {}) => {
    setActiveNav((prevNav) => (prevNav === nav ? null : nav)); // Toggle active class
    handlePopoverClick(e, nav, props); // Call the existing handlePopoverClick function
  };

  useOutsideClick(notificationsRef, () => setIsShowNotifications(false));
  useOutsideClick(profileRef, () => setIsShowProfile(false));
  useOutsideClick(searchRef, () => {
    setSearchResult(false);
    setIsFocused(false);
  });
  useOutsideClick(workspaceRef, () => setActiveNav(null));
  useOutsideClick(recentRef, () => setActiveNav(null));
  useOutsideClick(starredRef, () => setActiveNav(null));

  const addNewNotificationsCount = async (count) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${config.API_URI}/api/notifications/count`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ newNotificationsCount: count }),
      });

      if (!response.ok) throw new Error('Failed to add new notification count');
      const data = await response.json();
      console.log('Notification count updated successfully:', data);
    } catch (error) {
      console.error('Error adding notification count:', error);
    }
  };

  const getNewNotificationsCount = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${config.API_URI}/api/notifications/count`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Failed to fetch notification count');
      const data = await response.json();
      return data.newNotificationsCount;
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  const handleSearchClick = () => {
    setSearchResult((prev) => !prev);
  }

  useEffect(() => {
    if (isShowSearchResult) {
      setIsFocused(true);
      searchRef.current.querySelector('input').select();
    }
    else {
      setIsFocused(false);
    }
  }, [isShowSearchResult]);


  // if (userLoading) return null;//<div className='loading'>Loading...</div>;
  if (userError) return <div>Error: {userError}</div>;

  return (
    <header className="zoobbe-header" style={{ backgroundUrl: board?.cover?.coverColor[0] || '' }}>
      {/* <header className="zoobbe-header" style={{backgroundImage: `url(${board?.cover.sizes.medium})` || ''}}> */}

      <div className="header-left">
        <div className="zoobbe-header__logo">
          <Link to="/">

            <p>
              {currentTheme === 'dark' && <Logo />}
              {currentTheme === 'light' && <LogoLight />}
              <sup className="logo-beta">Beta</sup></p>
          </Link>
        </div>

        <ul className='header-nav-content'>
          <li
            className={`nav-workspaces ${activeNav === 'navWorkspaces' ? 'active' : ''}`}
            id='popover-nav-workspaces'
            onClick={(e) => handleNavClick(e, 'navWorkspaces', { boardId: board?.shortId })}
            ref={workspaceRef}
            data-tooltip-content="Workspaces"
            data-tooltip-position="bottom"
            data-popover-trigger
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--primary-text-color)"><path d="M240-120q-66 0-113-47T80-280q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm480 0q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm-480-80q33 0 56.5-23.5T320-280q0-33-23.5-56.5T240-360q-33 0-56.5 23.5T160-280q0 33 23.5 56.5T240-200Zm480 0q33 0 56.5-23.5T800-280q0-33-23.5-56.5T720-360q-33 0-56.5 23.5T640-280q0 33 23.5 56.5T720-200ZM480-520q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-680q0-33-23.5-56.5T480-760q-33 0-56.5 23.5T400-680q0 33 23.5 56.5T480-600Zm0-80Zm240 400Zm-480 0Z" /></svg>
          </li>
          <li
            className={`nav-recent ${activeNav === 'navRecent' ? 'active' : ''}`}
            id='popover-nav-recent'
            onClick={(e) => handleNavClick(e, 'navRecent', { boardId: board?.shortId })}
            ref={recentRef}
            data-tooltip-content="Recent"
            data-tooltip-position="bottom"
            data-popover-trigger
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--primary-text-color)"><path d="M480-80q-155 0-269-103T82-440h81q15 121 105.5 200.5T480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-86 0-159.5 42.5T204-640h116v80H88q29-140 139-230t253-90q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm112-232L440-464v-216h80v184l128 128-56 56Z" /></svg>
          </li>
          <li
            className={`nav-starred ${activeNav === 'navStarred' ? 'active' : ''}`}
            id='popover-nav-starred'
            onClick={(e) => handleNavClick(e, 'navStarred', { boardId: board?.shortId })}
            ref={starredRef}
            data-tooltip-content="Starred"
            data-tooltip-position="bottom"
            data-popover-trigger
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--primary-text-color)"><path d="m363-310 117-71 117 71-31-133 104-90-137-11-53-126-53 126-137 11 104 90-31 133ZM480-28 346-160H160v-186L28-480l132-134v-186h186l134-132 134 132h186v186l132 134-132 134v186H614L480-28Zm0-112 100-100h140v-140l100-100-100-100v-140H580L480-820 380-720H240v140L140-480l100 100v140h140l100 100Zm0-340Z" /></svg>
          </li>
        </ul>
      </div>

      <div className={`header-center ${isShowSearchResult ? 'active-search' : ''}`}>
        <div className={`zoobbe-header__search-container ${isShowSearchResult ? 'isOpen' : ''} ${isFocused ? 'focused' : ''}`} ref={searchRef}>
          <div className="search-content">
            <span className="material-symbols-outlined">search</span>
            <input
              className="zoobbe-header__search-input"
              type="text"
              placeholder="Search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onFocus={() => {
                setIsFocused(true);
                setSearchResult(true);
              }} // Add focused class and show SearchResults
              onBlur={() => setIsFocused(false)} // Remove focused class and hide SearchResults
              autoComplete="off"
            />

          </div>
          {isShowSearchResult && <SearchResults boardId={board?.shortId} query={query} setQuery={setQuery} setSearchResult={setSearchResult} />}
        </div>
      </div>

      <div className="header-right">
        {
          user?.user?.verified && (
            <div className="zoobbe-header__menu">
              <button to="/create" onClick={handleCreateWorkspace} className="zoobbe-header__menu-item zoobbe-header__menu-item--create">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                <span className="label-text">Create</span>
              </button>
            </div>
          )
        }

        <div className="zoobbe-header__icons">
          <div className="search-container">
            <div className={`search-icon${(isShowSearchResult) ? ' active' : ''}`}
              onClick={handleSearchClick}
              ref={searchIconRef}
              data-popover-trigger
            >

              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--primary-text-color)"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" /></svg>
            </div>
          </div>
          <div className="notifications-container" ref={notificationsRef} data-popover-trigger>
            <div
              className={`notifications-icon${(isShowNotifications || newNotification) ? ' active' : ''}`}
              data-tooltip-content="Notifications"
              data-tooltip-position="bottom"
              onClick={handleShowNotifications}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" /></svg>

              {newNotification > 0 && <span className='notification-counter'>{newNotification}</span>}
            </div>

            {isShowNotifications && (
              <Notifications setIsShowNotifications={setIsShowNotifications} />
            )}
          </div>

          <div className="profile-container" ref={profileRef}>
            <div className="profile-image" onClick={handleShowProfile}>

              {user?.user?.profilePicture ? (

                <div className="image-placeholder-container">
                  <img
                    className={`zoobbe-header__icons-avatar${isShowProfile ? ' active' : ''}`}
                    src={user?.user?.profilePicture}
                    alt={user?.user?.username}
                    loading="lazy"
                  />
                  <MemberOnlineStatus member={user?.user} />
                </div>
              ) : (
                <ImagePlaceholder
                  member={user?.user}
                  key={user?.user?._id}
                  size={32}
                  text={user?.user?.username}
                  className={`${isShowProfile ? ' active' : ''}`}

                />

              )}
            </div>

            {isShowProfile && (
              <ProfileDropdown
                userInfo={user}
                setIsShowProfile={setIsShowProfile}
              />
            )}
          </div>
        </div>
      </div>

    </header>
  );
};

export default Header;
