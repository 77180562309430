import { createSlice } from "@reduxjs/toolkit";

const cardMoveSlice = createSlice({
    name: "cardMove",
    initialState: {
        targetListId: null,
        targetPosition: null,
        card: null, // Optional: Store the card being moved
        cardAction: null,
    },
    reducers: {
        setCardMoveTarget(state, action) {
            const { targetListId, targetPosition, card, cardAction } = action.payload;
            state.targetListId = targetListId;
            state.targetPosition = targetPosition;
            state.card = card || null; // Card is optional
            state.cardAction = cardAction || null; // Card is optional
        },
        clearCardMoveTarget(state) {
            state.targetListId = null;
            state.targetPosition = null;
            state.card = null;
            state.cardAction = null; // Card is optional

        },
    },
});

export const { setCardMoveTarget, clearCardMoveTarget } = cardMoveSlice.actions;
export default cardMoveSlice.reducer;
