import React, { useState, useEffect, useRef } from 'react';
import './scss/ListAction.scss';
import { config } from '../../config';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import { useBoard } from '../../context/BoardContext';
import { togglePopover } from '../../redux/Slices/popoverSlice';

const MainScreen = ({ onNavigate, listId, boardId }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [isArchivingCards, setIsArchivingCards] = useState(false);
    const [isWatching, setIsWatching] = useState(false);
    const [watchers, setWatchers] = useState([]);

    const { boardState, setBoard } = useBoard();

    const dispatch = useDispatch();


    const onAchiveList = (listId) => setBoard((prev) => ({
        ...prev,
        actionLists: prev.actionLists.filter((list) => list._id !== listId),
    }));

    const onArchiveListAllCards = (listId) => setBoard((prev) => ({
        ...prev,
        actionLists: prev.actionLists.map((list) =>
            list._id === listId ? { ...list, cards: [] } : list
        ),
    }));



    const handleArchivedList = async () => {
        setIsArchiving(true);
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/actionLists/archive/${listId}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ boardId, archivedStatus: true })
            });

            if (!response.ok) throw new Error('Failed to archive the list');

            onAchiveList(listId);

            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));

            dispatch(showToast({ message: 'List archived successfully!', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error archiving the list: ' + error.message, type: 'error' }));
        } finally {
            setIsArchiving(false);
        }
    };

    const handleWatchActionList = async () => {
        setIsWatching(true);
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/actionLists/${listId}/watch`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ boardId })

            });

            if (!response.ok) throw new Error('Failed to toggle watch status');

            const data = await response.json();
            dispatch(showToast({ message: data.message, type: 'success' }));
            setWatchers(data.watchers);
        } catch (error) {
            dispatch(showToast({ message: 'Error toggling watch status: ' + error.message, type: 'error' }));
        } finally {
            setIsWatching(false);
        }
    };

    const handleArchivedAllCards = async () => {
        setIsArchivingCards(true);
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/actionLists/${listId}/cards/archived`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ boardId })

            });

            if (!response.ok) throw new Error('Failed to archive the list');

            onArchiveListAllCards(listId);
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));


            dispatch(showToast({ message: 'All cards archived successfully!', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error archiving the list: ' + error.message, type: 'error' }));
        } finally {
            setIsArchivingCards(false);
        }
    };

    return (
        <>
            <h2>List Actions</h2>
            <ul className="actions-list">
                <li onClick={() => onNavigate('add')}>Add Card</li>
                {/* <li onClick={() => onNavigate('copy')}>Copy List</li> */}
                {/* <li onClick={handleWatchActionList}>{isWatching ? 'Notifying...' : 'Notify'}</li> */}
            </ul>
            <ul className="actions-list">
                <li className="archive" onClick={handleArchivedList}>{isArchiving ? 'Archiving...' : 'Archive this List'}</li>
                <li className="archive" onClick={handleArchivedAllCards}>{isArchiving ? 'Archiving...' : 'Archive all cards in this List'}</li>
            </ul>
        </>
    );
};

const AddCard = ({ onBack, listId, boardId }) => {
    const textareaRef = useRef(null);
    const [title, setTitle] = useState('');
    const { setBoard } = useBoard();
    const dispatch = useDispatch();

    useEffect(() => {
        textareaRef.current?.focus();
    }, []);

    const handleAddCard = async () => {
        if (!title.trim()) return;
        const position = 'top';
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/cards`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    actionListId: listId,
                    title: title,
                    description: '',
                    position,
                    users: [],
                }),
            });
            if (!response.ok) throw new Error('Failed to add card');

            const { card } = await response.json();
            setBoard((prev) => ({
                ...prev,
                actionLists: prev.actionLists.map(list =>
                    list._id === listId
                        ? {
                            ...list,
                            cards: position === 'top'
                                ? [card, ...list.cards]  // Add at the top
                                : [...list.cards, card], // Add at the bottom
                        }
                        : list
                ),
            }));
            dispatch(showToast({ message: 'Card added successfully!', type: 'success' }));
            onBack();
        } catch (error) {
            dispatch(showToast({ message: 'Error adding card: ' + error.message, type: 'error' }));
        }
    };

    return (
        <>
            <h2>
                <button className="back-button" onClick={onBack}>
                    <span className="material-symbols-outlined">keyboard_backspace</span>
                </button>
                Add Card
            </h2>
            <div className="copy-list-form">
                <textarea ref={textareaRef} value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter card title"></textarea>
                <button className="confirm-button" onClick={handleAddCard}>Add Card</button>
            </div>
        </>
    );
};

const CopyListScreen = ({ onBack, listId, boardId }) => {
    const textareaRef = useRef(null);
    const [title, setTitle] = useState('');
    const { setBoard } = useBoard();
    const dispatch = useDispatch();

    useEffect(() => {
        textareaRef.current?.focus();
    }, []);

    const handleCopyList = async () => {
        if (!title.trim()) return;
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/actionLists/${listId}/copy`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({ boardId, title }),
            });
            if (!response.ok) throw new Error('Failed to copy list');

            const copiedList = await response.json();
            setBoard((prev) => ({
                ...prev,
                actionLists: [...prev.actionLists, copiedList],
            }));
            dispatch(showToast({ message: 'List copied successfully!', type: 'success' }));
            onBack();
        } catch (error) {
            dispatch(showToast({ message: 'Error copying list: ' + error.message, type: 'error' }));
        }
    };

    return (
        <>
            <h2>
                <button className="back-button" onClick={onBack}>
                    <span className="material-symbols-outlined">keyboard_backspace</span>
                </button>
                Copy List
            </h2>
            <div className="copy-list-form">
                <textarea ref={textareaRef} value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter list title"></textarea>
                <button className="confirm-button" onClick={handleCopyList}>Create</button>
            </div>
        </>
    );
};

const ListAction = ({ listId, boardId }) => {
    const [screen, setScreen] = useState('main');

    const renderScreen = () => {
        switch (screen) {
            case 'copy':
                return <CopyListScreen onBack={() => setScreen('main')} listId={listId} boardId={boardId} />;
            case 'add':
                return <AddCard onBack={() => setScreen('main')} listId={listId} boardId={boardId} />;
            default:
                return <MainScreen onNavigate={setScreen} listId={listId} boardId={boardId} />;
        }
    };

    return <div className="list-actions">{renderScreen()}</div>;
};

export default ListAction;
