import React, { useEffect, useRef } from 'react';
import './scss/MoreMembers.scss';
import { fetchMembers } from '../../redux/Slices/memberSlice';
import { useDispatch, useSelector } from 'react-redux';
import MemberImage from './MemberImage';
import MoreMembersImage from './MoreMembersImage';

const MoreMembers = ({ boardId }) => {

    const dispatch = useDispatch();

    const { boardMembers } = useSelector(state => state.member) || [];

    useEffect(() => {
        dispatch(fetchMembers({ type: 'board', id: boardId }));
    }, [boardId, dispatch]);


    return (
        <div className="more-members">
            <h2>Board Members</h2>

            <div className="group">
                <MoreMembersImage members={boardMembers} type={'board'} size={30} />
            </div>
        </div>

    );
};

export default MoreMembers;
