import React, { useState, useRef, useEffect } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';

const ZoobbeSelect = ({ options, defaultSelectedOption, onSelect, className = '',  isDisabled = false }) => {
    const isValidDefaultIndex =
        typeof defaultSelectedOption === 'number' &&
        defaultSelectedOption >= 0 &&
        defaultSelectedOption < options.length;

    // Ensure the default selection is valid; otherwise, fall back to the first option or undefined
    const [selectedOption, setSelectedOption] = useState(
        isValidDefaultIndex ? options[defaultSelectedOption] : options[0] || undefined
    );

    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();

    useOutsideClick(selectRef, () => {
        setIsOpen(false);
    });


    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onSelect(option);
    };

    // Handle when options length is zero
    if (options.length <= 0) {
        return null; // Return null to avoid rendering anything
    }


    return (
        <div className={`zoobbe-select ${className}`} ref={selectRef}>
            <div
                className={`zoobbe-select-trigger ${isOpen ? 'active' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedOption?.label || 'Select an option'}
                <span className="arrow-down">∟</span>
            </div>
            {!isDisabled && isOpen && (
                <ul className="zoobbe-select-options">
                    {options.map((option) => (
                        <li
                            key={option?.value}
                            onClick={() => handleOptionClick(option)}
                            className={`zoobbe-select-option${selectedOption?.value === option?.value ? ' selected' : ''}`}
                        >
                            {option?.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ZoobbeSelect;
