import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { config } from '../../config';
import './index.scss';

const Reset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [buttonText, setButtonText] = useState('Reset Password');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  // Password strength validation function
  const isPasswordStrong = (password) => {
    const lengthCheck = password.length >= 8;
    const uppercaseCheck = /[A-Z]/.test(password);
    const lowercaseCheck = /[a-z]/.test(password);
    const numberCheck = /\d/.test(password);
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return lengthCheck && uppercaseCheck && lowercaseCheck && numberCheck && specialCharCheck;
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);

    // Validate password strength
    if (!isPasswordStrong(value)) {
      setPasswordError(
        'Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.'
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (passwordError) return; // Prevent submission if password is not strong

    setIsSubmit(true);
    setButtonText('Resetting...');

    try {
      const response = await fetch(`${config.API_URI}/api/users/reset`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ token, newPassword })
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message);
        setMessage(null);
      } else {
        const data = await response.json();
        setMessage(data.message);
        setError(null);

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError('An error occurred while resetting the password');
      setMessage(null);
    } finally {
      setIsSubmit(false);
      setButtonText('Reset Password');
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password | Zoobbe</title>
      </Helmet>

      <div className="reset-container">
        <div className="reset-box">
          <h1 className="reset-logo">Zoobbe</h1>
          <h2 className="reset-heading">Reset Password</h2>
          <form className="reset-form" onSubmit={handleReset}>
            <input
              type="password"
              name="newPassword"
              placeholder="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              className="reset-input"
              required
            />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="reset-input"
              required
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
            <button type="submit" className="reset-button" disabled={isSubmit || passwordError}>
              {buttonText}
            </button>
          </form>
          <div className="reset-links">
            <Link to="/login" className="reset-link">Return to Log in</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
