import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async thunk for fetching personal workspaces
export const fetchWorkspaces = createAsyncThunk(
    'workspaces/fetchWorkspaces',
    async (_, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/workspaces/me`, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch workspaces');
        }

        const data = await response.json();
        return data;
    }
);

// Async thunk for fetching guest workspaces
export const fetchGuestsWorkspaces = createAsyncThunk(
    'workspaces/fetchGuestsWorkspaces',
    async (_, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/workspaces/guests/me`, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch guest workspaces');
        }

        const data = await response.json();
        return data;
    }
);

// Redux slice for managing both types of workspaces
const workspaceSlice = createSlice({
    name: 'workspaces',
    initialState: {
        workspaces: [],
        guestWorkspaces: [],
        status: 'idle',
        guestStatus: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch personal workspaces
            .addCase(fetchWorkspaces.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWorkspaces.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.workspaces = action.payload;
            })
            .addCase(fetchWorkspaces.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            // Fetch guest workspaces
            .addCase(fetchGuestsWorkspaces.pending, (state) => {
                state.guestStatus = 'loading';
            })
            .addCase(fetchGuestsWorkspaces.fulfilled, (state, action) => {
                state.guestStatus = 'succeeded';
                state.guestWorkspaces = action.payload;
            })
            .addCase(fetchGuestsWorkspaces.rejected, (state, action) => {
                state.guestStatus = 'failed';
                state.error = action.error.message;
            });
    }
});

export default workspaceSlice.reducer;
