import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Asynchronous thunk to fetch card by ID
export const fetchCardById = createAsyncThunk(
    'card/fetchCardById',
    async (cardId, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/cards/${cardId}`, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch card');
        }

        const data = await response.json();
        return data;
    }
);

// Asynchronous thunk to update card
export const updateCard = createAsyncThunk(
    'card/updateCard',
    async ({ cardId, updatedData }, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/cards/update/${cardId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        });

        if (!response.ok) {
            throw new Error('Failed to update card');
        }

        const data = await response.json();
        return data;  // Returns the updated card
    }
);

// Asynchronous thunk to fetch archived data
export const fetchArchivedData = createAsyncThunk(
    'card/fetchArchivedData',
    async ({ boardId, activeTab, page, debouncedQuery }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${config.API_URI}/api/${boardId}/${activeTab}/archived?page=${page}&limit=20&search=${debouncedQuery}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();

        console.log({ data });

        return { ...data, activeTab };
    }
);

// Asynchronous thunk to handle archiving a card
export const handleArchiveCard = createAsyncThunk(
    'card/handleArchiveCard',
    async ({ archivedStatus, cardId }, { getState }) => {

        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI + `/api/cards/archive/${cardId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ archived: archivedStatus }),
        });

        if (!response.ok) {
            throw new Error('Failed to archive the card');
        }

        const data = await response.json();
        return data;
    }
);

const cardSlice = createSlice({
    name: 'card',
    initialState: {
        card: null,
        archivedCards: [],
        archivedLists: [],
        cardsCount: 0,
        listsCount: 0,
        hasMore: true,
        activeTab: 'cards',
        status: 'idle',
        error: null
    },
    reducers: {
        cardData: (state, action) => {
            state.card = action.payload;
        },
        resetArchivedCards: (state) => {
            state.archivedCards = [];
            state.archivedLists = [];
            state.cardsCount = 0;
            state.hasMore = true;
        },
        removeArchivedCard: (state, action) => {
            state.archivedCards = state.archivedCards.filter(card => card.shortId !== action.payload);
            state.cardsCount = state.cardsCount - 1;
        },
        removeArchivedList: (state, action) => {
            state.archivedLists = state.archivedLists.filter(archivedList => archivedList._id !== action.payload);
            state.listsCount = state.listsCount - 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCardById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCardById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.card = action.payload || null; // Ensure card is set to null if action.payload is empty
            })
            .addCase(fetchCardById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            // Handling the updateCard cases
            .addCase(updateCard.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCard.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Ensure that action.payload contains a valid card object before assigning
                state.card = action.payload ? action.payload : state.card; // Update the card only if payload is valid
            })
            .addCase(updateCard.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            // Handling the fetchArchivedData cases
            .addCase(fetchArchivedData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchArchivedData.fulfilled, (state, action) => {
                state.status = 'succeeded';

                if (action.meta.arg.page === 1) {
                    if (action.payload.activeTab === 'cards') {
                        state.archivedCards = action.payload.cards;
                        state.cardsCount = action.payload.cardsCount;

                    } else {
                        state.archivedLists = action.payload.lists;
                        state.listsCount = action.payload.listsCount;
                    }
                } else {
                    if (action.payload.activeTab === 'cards') {
                        const uniqueCards = new Map([
                            ...state.archivedCards.map(card => [card._id, card]),
                            ...action.payload.cards.map(card => [card._id, card])
                        ]);
                        state.archivedCards = Array.from(uniqueCards.values());
                    } else {
                        state.archivedLists.push(...action.payload.lists);
                        state.listsCount = action.payload.listsCount;

                    }
                }

                state.hasMore = action.payload.hasMore;
            })
            .addCase(fetchArchivedData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Handling the handleArchiveCard cases
            .addCase(handleArchiveCard.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(handleArchiveCard.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.archivedCards = state.archivedCards.filter(card => card._id !== action.payload._id);
                // Update the single card's archived status if it's being viewed
                if (state.card && state.card._id === action.payload._id) {
                    state.card.archived = action.payload.archived;
                }
            })
            .addCase(handleArchiveCard.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { cardData, resetArchivedCards, removeArchivedCard, removeArchivedList } = cardSlice.actions;
export default cardSlice.reducer;