import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { config } from '../../config';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { showToast } from '../../redux/Slices/toastSlice';

import './scss/DeleteAction.scss';
import { textTransform } from '../../utils/helpers';
import { deleteCommentOptimistically, fetchActivities } from '../../redux/Slices/activitiesSlice';
import { deleteAttachment } from '../../redux/Slices/attachmentSlice';
import { deleteChecklist, localDeleteChecklist } from '../../redux/Slices/checklistSlice';
import { cardData } from '../../redux/Slices/cardSlice';
import { fetchMembers } from '../../redux/Slices/memberSlice';

const DeleteAction = (props) => {
    const inputRef = useRef(null);
    const { card } = useSelector((state) => state.card);
    const { type, id, url, cardId, listId, attachmentId, apiPath, typeId } = props;

    console.log(props);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getWarningMessage = (type) => {
        switch (type) {
            case 'CARD':
                return "Deleting this card will remove all actions from the activity feed, and you cannot re-open it. This action cannot be undone.";
            case 'ACTIONLIST':
                return "Deleting this action list will permanently remove all associated cards. This action cannot be undone.";
            case 'COMMENT':
                return "Deleting this comment cannot be undone.";
            case 'CHECKLIST':
                return "Deleting this checklist cannot be undone.";
            case 'ATTACHMENT':
                return "Deleting this attachment cannot be undone.";
            case 'MEMBER':
                return "This member will be removed from all cards on this board.";
            default:
                return "This action cannot be undone.";
        }
    };

    const handleDeleteAction = async () => {
        const token = localStorage.getItem('accessToken');

        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));

        try {
            let response;
            switch (type) {
                case 'CARD':
                    response = await fetch(url, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    break;
                case 'ACTIONLIST':
                    response = await fetch(config.API_URI + `/api/actionLists/${listId}`, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ boardId: id }),

                    });
                    break;
                case 'COMMENT':
                    response = await fetch(url, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ activityId: id }),
                    });
                    break;
                case 'CHECKLIST':
                    dispatch(deleteChecklist({ cardId, checklistId: id }));
                    break;
                case 'ATTACHMENT':
                    await dispatch(deleteAttachment({ cardId, attachmentId: id }));

                    if (card.cover.attachmentId === id) {
                        dispatch(cardData({
                            ...card,
                            cover: {}
                        }));
                    }
                    break;
                case 'MEMBER':
                    response = await fetch(url, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({ memberId: id }),
                    });
                    dispatch(fetchMembers({ type: apiPath, id: typeId }));

                    break;
                default:
                    throw new Error('Unsupported delete type');
            }

            if (response && response.ok) {
                dispatch(showToast({ message: `${textTransform(type, 'capitalize')} deleted successfully!`, type: 'success' }));
            } else {
                throw new Error('Failed to delete');
            }
        } catch (error) {
            console.error('Error deleting the item:', error);
        }
    };

    return (
        <div className="delete-action">
            <h2>{type === 'MEMBER' ? `Remove ${textTransform(type, 'lowercase')}` : `Delete ${textTransform(type, 'lowercase')}`}?</h2>

            <div className="group">
                <p className="warning-message">{getWarningMessage(type)}</p>
                <button onClick={handleDeleteAction}>
                    {type === 'MEMBER' ? 'Remove' : 'Delete'}
                </button>
            </div>
        </div>

    );
};

export default DeleteAction;
