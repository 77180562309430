import React from "react";

const Logo = ({ width = '100px', height = 'auto' }) => {
    return (
        <svg style={{ width, height }} className='zoobbe-logo' width="894" height="219" viewBox="0 0 894 219" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M153 25C153 38.8071 141.807 50 128 50C114.193 50 103 38.8071 103 25C103 11.1929 114.193 0 128 0C141.807 0 153 11.1929 153 25Z" fill="#38A800" />
            <path d="M510.4 19H532V129.4C532 142.04 535.2 151.88 541.6 158.92C548.16 165.8 557.12 169.24 568.48 169.24C580.32 169.24 589.6 165.88 596.32 159.16C603.04 152.28 606.4 142.36 606.4 129.4C606.4 117.08 602.72 107.4 595.36 100.36C588.16 93.16 578.64 89.56 566.8 89.56C556.72 89.56 548 92.44 540.64 98.2V76.6C549.44 71.8 558.96 69.4 569.2 69.4C586.16 69.4 600.16 74.92 611.2 85.96C622.4 96.84 628 111.32 628 129.4C628 148.44 622.16 163.24 610.48 173.8C598.96 184.2 584.4 189.4 566.8 189.4C550 189.4 536.4 183.96 526 173.08C515.6 162.2 510.4 147.64 510.4 129.4V19Z" fill="white" />
            <path d="M653.134 19H674.734V129.4C674.734 142.04 677.934 151.88 684.334 158.92C690.894 165.8 699.854 169.24 711.214 169.24C723.054 169.24 732.334 165.88 739.054 159.16C745.774 152.28 749.134 142.36 749.134 129.4C749.134 117.08 745.454 107.4 738.094 100.36C730.894 93.16 721.374 89.56 709.534 89.56C699.454 89.56 690.734 92.44 683.374 98.2V76.6C692.174 71.8 701.694 69.4 711.934 69.4C728.894 69.4 742.894 74.92 753.934 85.96C765.134 96.84 770.734 111.32 770.734 129.4C770.734 148.44 764.894 163.24 753.214 173.8C741.694 184.2 727.134 189.4 709.534 189.4C692.734 189.4 679.134 183.96 668.734 173.08C658.334 162.2 653.134 147.64 653.134 129.4V19Z" fill="white" />
            <path d="M852.269 69.4C879.469 70.52 893.069 81.8 893.069 103.24C893.069 129.96 875.549 143.32 840.509 143.32H830.669V124.84H842.189C852.909 124.84 860.349 123.24 864.509 120.04C868.669 116.68 870.749 111.16 870.749 103.48C870.749 98.52 868.909 94.84 865.229 92.44C861.549 89.88 856.829 88.6 851.069 88.6C839.709 88.6 830.749 92.6 824.189 100.6C817.789 108.6 814.589 118.2 814.589 129.4C814.589 141.24 818.029 150.84 824.909 158.2C831.949 165.56 841.069 169.24 852.269 169.24C862.349 169.24 870.589 166.76 876.989 161.8L891.389 175.96C880.349 184.92 867.309 189.4 852.269 189.4C834.029 189.4 819.469 184.04 808.589 173.32C797.709 162.6 792.269 148.76 792.269 131.8C792.269 112.92 797.629 97.64 808.349 85.96C819.069 74.28 833.709 68.76 852.269 69.4Z" fill="white" />
            <path d="M486 129C486 162.137 459.137 189 426 189C392.863 189 366 162.137 366 129C366 95.8629 392.863 69 426 69C459.137 69 486 95.8629 486 129Z" fill="#38A800" />
            <path d="M340 129C340 162.137 313.137 189 280 189C246.863 189 220 162.137 220 129C220 95.8629 246.863 69 280 69C313.137 69 340 95.8629 340 129Z" fill="#38A800" />
            <path fillRule="evenodd" clipRule="evenodd" d="M87.4049 50.9999C87.0132 51.6252 86.6312 52.2599 86.2592 52.9041L42.7586 128.248C26.0518 157.185 35.9661 194.186 64.903 210.892C74.7232 216.561 85.4722 219.165 96.0416 219H217C224.732 219 231 212.732 231 205C231 197.268 224.732 191 217 191H146.183C146.652 190.262 147.107 189.511 147.548 188.747L191.049 113.403C207.756 84.4667 197.841 47.4658 168.904 30.7594C157.34 24.0828 144.488 21.658 132.157 22.9999H14C6.26801 22.9999 0 29.2679 0 36.9999C0 44.7319 6.26802 50.9999 14 50.9999H87.4049ZM112.24 67.9039C120.663 53.3161 139.316 48.3178 153.904 56.7401C168.492 65.1623 173.49 83.8157 165.068 98.4035L121.567 173.747C113.145 188.335 94.4912 193.333 79.9032 184.911C65.3152 176.489 60.3171 157.836 68.7395 143.248L112.24 67.9039Z" fill="#0966FF" />
        </svg>
    )
}
export const LogoLight = ({ width = '100px', height = 'auto' }) => {
    return (
        <svg style={{ width, height }} width="894" height="219" viewBox="0 0 894 219" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M153 25C153 38.8071 141.807 50 128 50C114.193 50 103 38.8071 103 25C103 11.1929 114.193 0 128 0C141.807 0 153 11.1929 153 25Z" fill="#38A800" />
            <path d="M510.4 21H532V131.4C532 144.04 535.2 153.88 541.6 160.92C548.16 167.8 557.12 171.24 568.48 171.24C580.32 171.24 589.6 167.88 596.32 161.16C603.04 154.28 606.4 144.36 606.4 131.4C606.4 119.08 602.72 109.4 595.36 102.36C588.16 95.16 578.64 91.56 566.8 91.56C556.72 91.56 548 94.44 540.64 100.2V78.6C549.44 73.8 558.96 71.4 569.2 71.4C586.16 71.4 600.16 76.92 611.2 87.96C622.4 98.84 628 113.32 628 131.4C628 150.44 622.16 165.24 610.48 175.8C598.96 186.2 584.4 191.4 566.8 191.4C550 191.4 536.4 185.96 526 175.08C515.6 164.2 510.4 149.64 510.4 131.4V21Z" fill="black" />
            <path d="M653.134 21H674.734V131.4C674.734 144.04 677.934 153.88 684.334 160.92C690.894 167.8 699.854 171.24 711.214 171.24C723.054 171.24 732.334 167.88 739.054 161.16C745.774 154.28 749.134 144.36 749.134 131.4C749.134 119.08 745.454 109.4 738.094 102.36C730.894 95.16 721.374 91.56 709.534 91.56C699.454 91.56 690.734 94.44 683.374 100.2V78.6C692.174 73.8 701.694 71.4 711.934 71.4C728.894 71.4 742.894 76.92 753.934 87.96C765.134 98.84 770.734 113.32 770.734 131.4C770.734 150.44 764.894 165.24 753.214 175.8C741.694 186.2 727.134 191.4 709.534 191.4C692.734 191.4 679.134 185.96 668.734 175.08C658.334 164.2 653.134 149.64 653.134 131.4V21Z" fill="black" />
            <path d="M852.269 71.4C879.469 72.52 893.069 83.8 893.069 105.24C893.069 131.96 875.549 145.32 840.509 145.32H830.669V126.84H842.189C852.909 126.84 860.349 125.24 864.509 122.04C868.669 118.68 870.749 113.16 870.749 105.48C870.749 100.52 868.909 96.84 865.229 94.44C861.549 91.88 856.829 90.6 851.069 90.6C839.709 90.6 830.749 94.6 824.189 102.6C817.789 110.6 814.589 120.2 814.589 131.4C814.589 143.24 818.029 152.84 824.909 160.2C831.949 167.56 841.069 171.24 852.269 171.24C862.349 171.24 870.589 168.76 876.989 163.8L891.389 177.96C880.349 186.92 867.309 191.4 852.269 191.4C834.029 191.4 819.469 186.04 808.589 175.32C797.709 164.6 792.269 150.76 792.269 133.8C792.269 114.92 797.629 99.64 808.349 87.96C819.069 76.28 833.709 70.76 852.269 71.4Z" fill="black" />
            <path d="M486 129C486 162.137 459.137 189 426 189C392.863 189 366 162.137 366 129C366 95.8629 392.863 69 426 69C459.137 69 486 95.8629 486 129Z" fill="#38A800" />
            <path d="M340 129C340 162.137 313.137 189 280 189C246.863 189 220 162.137 220 129C220 95.8629 246.863 69 280 69C313.137 69 340 95.8629 340 129Z" fill="#38A800" />
            <path fillRule="evenodd" clipRule="evenodd" d="M87.4049 50.9999C87.0132 51.6252 86.6312 52.2599 86.2592 52.9041L42.7586 128.248C26.0518 157.185 35.9661 194.186 64.903 210.892C74.7232 216.561 85.4722 219.165 96.0416 219H217C224.732 219 231 212.732 231 205C231 197.268 224.732 191 217 191H146.183C146.652 190.262 147.107 189.511 147.548 188.747L191.049 113.403C207.756 84.4667 197.841 47.4658 168.904 30.7594C157.34 24.0828 144.488 21.658 132.157 22.9999H14C6.26801 22.9999 0 29.2679 0 36.9999C0 44.7319 6.26802 50.9999 14 50.9999H87.4049ZM112.24 67.9039C120.663 53.3161 139.316 48.3178 153.904 56.7401C168.492 65.1623 173.49 83.8157 165.068 98.4035L121.567 173.747C113.145 188.335 94.4912 193.333 79.9032 184.911C65.3152 176.489 60.3171 157.836 68.7395 143.248L112.24 67.9039Z" fill="#0966FF" />
        </svg>


    )
}

export default Logo;