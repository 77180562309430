import React from 'react';
import './scss/terms.scss';  // Import the SCSS file

const Terms = () => {
    return (
        <div className="page-container">
            <h1>Terms of Service</h1>
            <p><strong>Last Updated:</strong> Mar 7,  2025</p>
            <section>
                <p>
                    These Terms of Service (“Terms”) govern your use of Zoobbe (the “Service”), which is provided by Zoobbe, Inc. By accessing or using Zoobbe, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, do not use the Service.
                </p>
            </section>

            <section>
                <h3>1. Account Creation and Security</h3>
                <p>- You are responsible for maintaining the confidentiality of your account credentials, including your password.</p>
                <p>- You agree to notify us immediately of any unauthorized use of your account.</p>
            </section>

            <section>
                <h3>2. Use of Service</h3>
                <p>- You may use the Service for lawful purposes only and agree not to use it for any unlawful or prohibited activities.</p>
                <p>- You must comply with all local laws and regulations while using Zoobbe.</p>
            </section>

            <section>
                <h3>3. User-Generated Content</h3>
                <p>- You are solely responsible for the content you upload to Zoobbe, including profile pictures, feedback, and other personal information.</p>
                <p>- By uploading content, you grant Zoobbe a license to use, store, and display the content within the Service.</p>
            </section>

            <section>
                <h3>4. Restrictions</h3>
                <p>- You may not reverse engineer, decompile, or modify Zoobbe's software, services, or functionality.</p>
                <p>- You are prohibited from engaging in any activity that could damage, disable, or impair the Service.</p>
            </section>

            <section>
                <h3>5. Subscription Plans and Payments</h3>
                <p>- Zoobbe offers different subscription plans. Please refer to our pricing page for details.</p>
                <p>- Payments for subscription plans are non-refundable unless otherwise specified.</p>
            </section>

            <section>
                <h3>6. Termination</h3>
                <p>- We reserve the right to suspend or terminate your account if you violate these Terms.</p>
                <p>- You may terminate your account at any time by following the account cancellation process.</p>
            </section>

            <section>
                <h3>7. Limitation of Liability</h3>
                <p>- Zoobbe is provided “as-is” without warranties of any kind. We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the Service.</p>
            </section>

            <section>
                <h3>8. Changes to Terms</h3>
                <p>- We may update these Terms from time to time. Any changes will be posted on this page with the effective date. By continuing to use the Service after changes, you agree to the revised Terms.</p>
            </section>
        </div>
    );
};

export default Terms;
