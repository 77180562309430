import React, { useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import { fetchMembers, addMemberOptimistic, removeMemberOptimistic, addMember, removeMember } from '../../redux/Slices/memberSlice';
import './scss/MembersPopover.scss';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import MemberOnlineStatus from '../Global/MemberOnlineStatus';
import { sendPushNotification } from '../../utils/notifications';

// Memoized MemberItem component
const MemberItem = memo(({ member, action, handleAction }) => (
    <div className="members-popover__member" onClick={(e) => handleAction(e, member)}>
        {member.profilePicture ? (

            <div className="image-placeholder-container">
                <img src={member.profilePicture} alt={member.username} className="members-popover__avatar" loading="lazy" />
                <MemberOnlineStatus member={member} />
            </div>

        ) : (
            <ImagePlaceholder size={30} member={member} text={member.username} fontSize="14px" />
        )}
        <span>{member.username}</span>
        <div className="action-icon">
            <span className="material-symbols-outlined">
                {action === 'ADD' ? 'close' : 'add'}
            </span>
        </div>
    </div>
));

// Main MembersPopover component
const MembersPopover = () => {
    const dispatch = useDispatch();
    const { card } = useSelector((state) => state.card);
    const { boardMembers, cardMembers } = useSelector((state) => state.member) || [];

    const cardId = card?.shortId || '';
    const initialCardMembers = card?.users || [];

    // To calculate available board members, we can derive it from the cardMembers
    const availableBoardMembers = boardMembers.filter(
        (member) => !cardMembers.some((cardMember) => cardMember._id === member._id)
    );

    useEffect(() => {
        if (card?.board && boardMembers.length === 0) {
            dispatch(fetchMembers({ type: 'board', id: card.board }));
        }
    }, [card?.board, dispatch, boardMembers.length]);

    const handleAddMember = async (e, member) => {
        e.preventDefault();
        const memberId = member._id;

        console.log({ member });

        // Optimistic UI Update
        dispatch(addMemberOptimistic({ cardId, member }));

        try {
            // Server Request
            const response = await dispatch(addMember({ cardId, memberId })).unwrap();

            if (response.error) {
                console.error('Member already added:', response.error);
            } else {
                dispatch(fetchActivities({ cardId }));

                // Send Push Notification
                // sendPushNotification({
                //     memberId: member._id,
                //     type: 'ADD_MEMBER_TO_CARD',
                //     data: { cardId }, // Optional extra data
                // });
            }
        } catch (error) {
            console.error('Failed to add member:', error);
        }
    };

    const handleRemoveMember = async (e, member) => {
        e.preventDefault();
        const memberId = member._id;

        // Optimistic UI Update
        dispatch(removeMemberOptimistic({ cardId, memberId }));

        try {
            // Server Request
            const response = await dispatch(removeMember({ cardId, memberId })).unwrap();

            // Check backend response
            if (response.error) {
                console.error('Member not removed:', response.error);
            } else {
                dispatch(fetchActivities({ cardId }));

                // Send Push Notification
                // sendPushNotification({
                //     memberId: member._id,
                //     type: 'REMOVE_MEMBER_FROM_CARD',
                //     data: { cardId }, // Optional extra data
                // });

            }
        } catch (error) {
            console.error('Failed to remove member:', error);
        }
    };

    return (
        <div className="members-popover">
            <div className="members-popover__header">
                <h3>Members</h3>
            </div>
            <input type="text" className="members-popover__search" placeholder="Search members" />
            {cardMembers.length > 0 && (
                <div className="members-popover__section">
                    <h4>Card members</h4>
                    {cardMembers.map((member) => (
                        <MemberItem key={member._id} member={member} action="ADD" handleAction={handleRemoveMember} />
                    ))}
                </div>
            )}
            {availableBoardMembers.length > 0 && (
                <div className="members-popover__section">
                    <h4>Board members</h4>
                    {availableBoardMembers.map((member) => (
                        <MemberItem key={member._id} member={member} action="REMOVE" handleAction={handleAddMember} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default MembersPopover;
