import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SearchSelect from '../Global/SearchSelect';
import Spinner from '../Global/Spinner';
import './scss/MoveCard.scss';
import { fetchBoards, fetchBoardLists } from '../../redux/Slices/boardsSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { config } from '../../config';
import { fetchCardById } from '../../redux/Slices/cardSlice';
import { setCardMoveTarget } from '../../redux/Slices/moveCardSlice';

const MoveCard = () => {
    const dispatch = useDispatch();
    const boardId = useSelector(selectBoardId);
    const { boards, actionLists } = useSelector(state => state.boards);
    const { card } = useSelector(state => state.card);

    const [moveState, setMoveState] = useState({
        selectedBoard: boardId || null,
        selectedList: card.actionList._id || null,
        selectedPosition: card.shortId || null,
        targetPosition: null,
        loading: false,
    });

    useEffect(() => {
        dispatch(fetchBoards());
    }, []);

    useEffect(() => {
        if (moveState.selectedBoard) {
            dispatch(fetchBoardLists(moveState.selectedBoard));
        }
    }, []);

    useEffect(() => {
        const lists = actionLists[moveState.selectedBoard] || [];
        const selectedActionList = lists?.find(actionList => actionList._id === moveState.selectedList);
        const cardCount = selectedActionList?.cards?.length;


        if (!selectedActionList) return;

        const isCurrentList = moveState.selectedList === card.actionList._id;

        // Determine the position to select
        const defaultPosition = isCurrentList
            ? card.shortId : cardCount || 1; // Default to 1 if no cards exist in the selected list

        console.log({ cardCount })


        setMoveState(prev => ({
            ...prev,
            selectedPosition: defaultPosition,
        }));
    }, [moveState.selectedList, moveState.selectedBoard, actionLists, card.actionList._id, card.shortId]);


    const boardOptions = useMemo(() => {
        return boards.map(board => ({
            value: board.shortId,
            label: `${board.title}${board.shortId === boardId ? '<br><span className="current-option">(current)</span>' : ''}`,
            workspaceShortId: board.workspaceShortId,
            workspaceName: board.workspaceName,
        })) || [];
    }, [boards, boardId]);

    const actionListOptions = useMemo(() => {
        const lists = actionLists[moveState.selectedBoard] || [];
        return lists.map(actionList => ({
            value: actionList._id.toString(),
            label: `${actionList.title}${actionList._id.toString() === card.actionList._id ? '<br><span className="current-option">(current)</span>' : ''}`,
        }));
    }, [actionLists, moveState.selectedBoard, card.actionList._id]);

    const positionOptions = useMemo(() => {
        const lists = actionLists[moveState.selectedBoard] || [];
        const selectedActionList = lists.find(actionList => actionList._id === moveState.selectedList);

        if (!selectedActionList) return [];

        const isCurrentList = moveState.selectedList === card.actionList._id;
        const cardCount = selectedActionList.cards.length;

        console.log({ cardCount })

        return selectedActionList.cards.map((indexCard, index) => ({
            value: indexCard.shortId,
            label: `${index + 1} ${isCurrentList && indexCard.shortId === card.shortId ? '<br><span className="current-option">(current)</span>' : ''}`,
            index: index, // Start from 1
        })).concat(
            !isCurrentList
                ? [{ value: cardCount || 1, label: `${cardCount + 1}`, index: cardCount + 1 }] // Add option for x + 1
                : []
        );
    }, [actionLists, moveState.selectedBoard, moveState.selectedList, card.actionList._id, card.shortId]);


    const handleBoardSelect = useCallback((selectedBoardOption) => {
        setMoveState(prev => ({
            ...prev,
            selectedBoard: selectedBoardOption.value,
            selectedList: null,
            selectedPosition: null,
        }));
    }, []);

    const handleActionListSelect = useCallback((selectedListOption) => {
        const lists = actionLists[moveState.selectedBoard] || [];
        const actionList = lists.find(actionList => actionList._id === selectedListOption.value);
        setMoveState(prev => ({
            ...prev,
            selectedList: selectedListOption.value,
            selectedPosition: actionList?.cards?.length > 0 ? actionList.cards[actionList.cards.length - 1].shortId : card.shortId,
        }));
    }, [actionLists, moveState.selectedBoard, card.shortId]);

    const handlePositionSelect = useCallback((selectedPosition) => {
        setMoveState(prev => ({
            ...prev,
            selectedPosition: selectedPosition.value,
            targetPosition: selectedPosition.index,
        }));
    }, []);


    const handleMoveCard = useCallback(async () => {
        setMoveState((prev) => ({ ...prev, loading: true }));
        const token = localStorage.getItem("accessToken");

        const lists = actionLists[moveState.selectedBoard] || [];
        const selectedActionList = lists.find(actionList => actionList._id === moveState.selectedList);
        const cardCount = selectedActionList.cards.length;

        try {
            const targetListId = moveState.selectedList;
            const targetPosition = moveState.targetPosition !== null ? moveState.targetPosition : cardCount;

            // Call the API to update the card's position
            await fetch(`${config.API_URI}/api/boards/${boardId}/cards/order`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                credentials: 'include',
                body: JSON.stringify({
                    cardId: card._id,
                    targetListId,
                    targetPosition,
                }),
            });

            dispatch(setCardMoveTarget({ targetListId, targetPosition, card, cardAction: 'MOVE' }));
            dispatch(fetchCardById(card.shortId));
            dispatch(fetchBoardLists(card.board));


            // Close the popover
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
        } catch (error) {
            console.error("Error moving card:", error);
        } finally {
            setMoveState((prev) => ({ ...prev, loading: false }));
        }
    }, [
        boardId,
        card._id,
        moveState.selectedList,
        moveState.targetPosition,
        actionLists,
        card.actionList._id,
        dispatch,
    ]);

    return (
        <div className="move-card">
            <h2>Move Card</h2>
            <div className="group">
                <h3>Select Destination</h3>
                <SearchSelect
                    options={boardOptions}
                    onSelect={handleBoardSelect}
                    placeholder="Select a board"
                    isGrouped={true}
                    isDisabled={true}
                    defaultValue={moveState.selectedBoard}
                />
            </div>
            <div className='actionlists-group'>
                <div className="group actionlists">
                    <h3>List</h3>
                    <SearchSelect
                        options={actionListOptions}
                        onSelect={handleActionListSelect}
                        placeholder="Select a list"
                        isGrouped={false}
                        defaultValue={moveState.selectedList}
                    />
                </div>
                <div className="group position">
                    <h3>Position</h3>
                    <SearchSelect
                        options={positionOptions}
                        onSelect={handlePositionSelect}
                        placeholder="Select a position"
                        isGrouped={false}
                        defaultValue={moveState.selectedPosition}
                    />
                </div>
            </div>
            <button onClick={handleMoveCard} disabled={moveState.loading}>
                {moveState.loading ? (
                    <Spinner size={20} color="#ffffff" speed={1.5} strokeWidth={5} />
                ) : 'Move'}
            </button>
        </div>
    );
};

export default MoveCard;
