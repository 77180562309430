import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { config } from '../../config';
import Spinner from '../Global/Spinner';
import { useSelector } from 'react-redux';

const Slack = () => {
    const [status, setStatus] = useState('Connecting...');
    const navigate = useNavigate();
    const location = useLocation();
    const code = new URLSearchParams(location.search).get('code');
    const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from storage

    const { user } = useSelector((state) => state.user);


    useEffect(() => {
        if (!code) {
            setStatus('Invalid or missing authorization code.');
            return;
        }

        const exchangeCodeForToken = async () => {
            try {
                const response = await fetch(`${config.API_URI}/api/users/slack/auth/callback`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Include Bearer token
                    },
                    credentials: 'include',
                    body: JSON.stringify({ code }),
                });

                const data = await response.json();

                if (data.success) {
                    setStatus('Successfully connected to Slack!');
                    setTimeout(() => navigate('/u/' + user?.user?.username + '/settings'), 2000); // Redirect after success
                } else {
                    setStatus(`Error: ${data.message || 'Authentication failed.'}`);
                }
            } catch (error) {
                console.error('Slack OAuth Error:', error);
                setStatus('An error occurred during authentication.');
            }
        };

        exchangeCodeForToken();
    }, [code, accessToken, navigate]);

    return (
        <div className="verify-container">
            <div className="verifying-text">
                <Spinner size={18} color="var(--brand-color)" speed={2.5} strokeWidth={6} />
                <span>{status}</span>
            </div>
        </div>
    );
};

export default Slack;
