import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/Slices/modalSlice';
import { selectBoardId, setBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchBoardMembers } from '../../redux/Slices/boardMembersSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import MemberImage from '../Global/MemberImage';
import { initialFiltersState } from '../../utils/helpers';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { config } from '../../config';
import useOutsideClick from '../../hooks/useOutsideClick';
import Filter from './Filter';
import { setFilterQuery } from '../../redux/Slices/filterQuerySlice';
import { fetchMembers } from '../../redux/Slices/memberSlice';
import BoardMenu from '../BoardMenu/BoardMenu';
import NavbarLeft from './NavbarLeft';
import MoreMembersCount from '../Global/MoreMembersCount';
import { setBoardBackground, setSelectedBackground } from '../../redux/Slices/boardSlice';

const Navbar = forwardRef(({ cardCount, board, setBoard }, ref) => {
    const dispatch = useDispatch();
    const filterRef = useRef();
    const menuRef = useRef();

    const boardId = useSelector(selectBoardId);
    const [isShowFilter, setShowFilter] = useState(false);
    const [isShowMenu, setShowMenu] = useState(false);
    const [isLoading, setLoading] = useState(false);



    const location = useLocation();

    const members = useSelector(state => state.member.boardMembers);
    const boardData = useSelector(state => state.board);

    const query = useSelector((state) => state.filterQuery);


    const handleShareBoardModal = () => {
        dispatch(openModal({ modalType: 'SHARE_BOARD', modalData: {} }));
    };

    useEffect(() => {
        if (boardId) {
            dispatch(fetchMembers({ type: 'board', id: boardId }));
            dispatch(setBoardId(boardId));
        }
    }, [dispatch, boardId]);

    const handleMemberInfo = (e, contentId) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };
        dispatch(togglePopover({ contentId, position, targetId: target.id }));
    };

    const updateLastViewedBoard = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            await fetch(`${config.API_URI}/api/boards/${boardId}/last-viewed`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            });
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
        }
    };

    useEffect(() => {
        if (boardId) {
            updateLastViewedBoard();
        }
    }, [boardId]);

    useOutsideClick(filterRef, () => setShowFilter(false), isShowFilter);
    // useOutsideClick(menuRef, () => setShowMenu(false), isShowMenu);

    const updateFilters = async (newFilters) => {
        const accessToken = localStorage.getItem('accessToken');
        setLoading(true);
        try {
            const response = await fetch(`${config.API_URI}/api/users/me/filters`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                credentials: 'include',
                body: JSON.stringify({ filters: newFilters, boardId })
            });

            if (!response.ok) {
                throw new Error('Failed to update filters');
            }
            const data = await response.json();
            setBoard(data.board || { actionLists: [] });
            setLoading(false);

            console.log('Filters updated successfully:', data);
        } catch (error) {
            console.error('Error updating filters:', error);
        }
    };

    const handleClearFilters = () => {
        updateFilters(initialFiltersState);
        dispatch(setFilterQuery(initialFiltersState));

    }


    return (
        <div className="zoobbe-workspace-navbar" ref={ref}>

            <NavbarLeft boardId={boardId} board={board} setBoard={setBoard} />

            <div className="zoobbe-navbar-right" >
                <div className="filter-content" ref={filterRef} >

                    <div
                        className={`cards-count${query.filteredStatus ? ' active' : ' active'}`}
                        style={{ paddingRight: isLoading ? '2px' : '4px', cursor: 'pointer' }}
                        data-tooltip-content="Cards"
                        data-tooltip-position="top"
                    >
                        <span className="material-symbols-outlined">
                            credit_card_gear
                        </span>
                        <span className='count-number'>
                            {board.cardsCount}
                        </span>
                    </div>

                    <div
                        className="zoobbe-filters"
                        onClick={() => setShowFilter(!isShowFilter)}
                        data-popover-trigger>
                        {
                            query.filteredStatus && (
                                <div className="filter-indicator"></div>
                            )
                        }

                        <span className="material-symbols-outlined" data-tooltip-content={query.filteredStatus ? "Filters applied" : "Filters"} data-tooltip-position="top">
                            filter_list
                        </span>
                    </div>
                </div>

                <div className="zoobbe-members">
                    <MemberImage members={members} type={'board'} cardId={board.shortId} size={30} />
                    <MoreMembersCount members={members} boardId={board.shortId} uniqid={board.shortId} />
                </div>
                <div className="zoobbe-share" onClick={handleShareBoardModal}>
                    <span className="material-symbols-outlined" data-tooltip-content="Share" data-tooltip-position="top">
                        person_add
                    </span>
                    {/* Share */}
                </div>

                <button
                    className="zoobbe-board-more"
                    onClick={() => setShowMenu(!isShowMenu)}
                    data-tooltip-content='Menu'
                    data-tooltip-position='top'
                    data-popover-trigger

                >
                    <span className="material-symbols-outlined">
                        more_horiz
                    </span>
                </button>

                {
                    isShowFilter && (
                        <div ref={filterRef} style={{ position: 'absolute', right: '0', top: '0' }}>
                            <Filter
                                board={board}
                                setBoard={setBoard}
                                isLoading={isLoading}
                                setLoading={setLoading}
                                setShowFilter={setShowFilter}
                            />
                        </div>
                    )
                }
                {
                    isShowMenu && (
                        <div ref={menuRef} style={{ position: 'absolute' }}>
                            <BoardMenu
                                boardId={board.shortId}
                                setShowMenu={setShowMenu}
                                board={board}
                                setBoard={setBoard}
                                isLoading={isLoading}
                                setLoading={setLoading}
                            />

                        </div>
                    )
                }
            </div>

        </div >


    );
});

export default Navbar;
