import { Helmet } from "react-helmet";
import { config } from "../../config";

const MetaTags = ({
    title = "Zoobbe | Simplify Your Workflow",
    description = "Zoobbe helps teams and individuals stay organized and on track. Plan, track, and collaborate seamlessly with Zoobbe Board.",
    ogImage = "https://zoobbe-public.s3.ap-southeast-1.amazonaws.com/zoobbe.svg",
    ogUrl = config.DOMAIN,
}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:url" content={ogUrl} />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={ogImage} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    );
};

export default MetaTags;
