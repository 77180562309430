import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useVirtualizer } from '@tanstack/react-virtual';
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { autoScrollForElements, autoScrollWindowForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { showToast } from '../../redux/Slices/toastSlice';
import { fetchUsers } from '../../redux/Slices/thunks';
import { config } from '../../config';
import { useBoard } from '../../context/BoardContext';
import { debounce, find, getFilters, updateCardDetails } from '../../utils/helpers';
import { selectBoardId, setBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { setFilterQuery } from '../../redux/Slices/filterQuerySlice';
import ActionListPND from './ActionListPND';
import AddActionListButton from './AddActionListButton';
import Navbar from './Navbar';
import NotFound from '../400';
import useIsScrolling from '../../hooks/useIsScrolling';
import SkeletonBoard from '../Skeletons/SkeletonBoard';
import { fetchBoardById, resetBoard, setLoading } from '../../redux/Slices/boardSlice';
import { Helmet } from 'react-helmet';
import { fetchMembers } from '../../redux/Slices/memberSlice';
import { fetchCardsByListId } from '../../redux/Slices/actionlistSlice';
import { fetchBoardLists } from '../../redux/Slices/boardsSlice';
import MetaTags from '../Global/MetaTags';
import { fetchCardById } from '../../redux/Slices/cardSlice';
import { ActionTypes } from '../../sockets/ActionTypes';
import { setCardMoveTarget } from '../../redux/Slices/moveCardSlice';

import socketIOClient from 'socket.io-client';



const Board = ({ board, setBoard }) => {
  const { boardId, boardSlug, cardId } = useParams();
  const { setBoardPermalink } = useBoard();
  const _boardId = useSelector(selectBoardId);

  const dispatch = useDispatch();
  const location = useLocation();


  const [showForm, setShowForm] = useState(false);
  const [listTitle, setActionListTitle] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [openListId, setOpenListId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedLists, setFetchedLists] = useState(new Set());
  const [highlight, setHighlight] = useState(false);
  const [cardCount, setCardCount] = useState(0);
  const [navbarHeight, setNavbarHeight] = useState(52);
  const navbarRef = useRef(null);


  const { actionLists } = useSelector(state => state.boards);
  const { user } = useSelector((state) => state.user);


  const textareaRef = useRef(null);
  const parentRef = useRef(null);
  const boardRef = useRef(null);
  const formRef = useRef(null);
  const isScrolling = useIsScrolling(parentRef);

  const { boardLoading } = useSelector((state) => state.board);

  const fetchedListsRef = useRef(new Set());


  // Monitor Drag and Drop
  useEffect(() => {
    const element = boardRef.current;
    const monitorConfig = {
      element,
      onDrag({ location }) {
        const target = location.current.dropTargets[0];
        setHighlight(target && target.data.boardShortId === _boardId);
      },
      onDrop() {
        setHighlight(false);
      },
    };

    return monitorForElements(monitorConfig);
  }, [_boardId]);

  useEffect(() => {

    const updateNavbarHeight = () => {

      if (navbarRef.current) {
        const height = navbarRef.current.offsetHeight;
        setNavbarHeight(height);
      }
    };
    updateNavbarHeight(); // Initial calculation
    window.addEventListener('resize', updateNavbarHeight);
    return () => {
      window.removeEventListener('resize', updateNavbarHeight);
    };
  }, [navbarRef.current]);


  // Virtualizer configuration
  const virtualizer = useVirtualizer({
    horizontal: true,
    count: board.actionLists.length + 1,
    getScrollElement: () => parentRef.current || boardRef.current,
    estimateSize: index => {
      return board.actionLists[index]?.customWidth || 280; // Replace `customWidth` with the actual dynamic size logic
    },
  });

  // Fetch filters on component mount
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await getFilters({ boardId });

        // Validate response properties before destructuring
        if (response && response.filters && response.cardCount !== undefined) {
          const { filters, cardCount } = response;
          dispatch(setFilterQuery(filters));
          setCardCount(cardCount);
        } else {
          console.error('Invalid response:', response);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();
  }, [boardId, dispatch]);


  const prevPath = useRef(location.pathname);

  useEffect(() => {
    const fetchBoardData = async () => {
      if (!boardId && !_boardId) return;

      setIsLoading(true);

      try {
        // Reset board data before fetching
        setBoard({ actionLists: [] });
        setFetchedLists(new Set());

        const boardData = await dispatch(fetchBoardById(boardId || _boardId)).unwrap();

        setBoard((prevBoard) => ({
          ...boardData,
          actionLists: boardData.actionLists.map((list) => ({
            ...list,
            cards: prevBoard?.actionLists?.find((prevList) => prevList.id === list.id)?.cards || [],
          })),
        }));
      } catch (error) {
        console.error("Error fetching the board:", error);
      } finally {
        setIsLoading(false);
        dispatch(setLoading(false));
      }
    };

    // Avoid fetching if moving between `/b/` and `/c/`
    const isNavigatingBetweenBAndC =
      (prevPath.current?.split("/")[1] === "b" && location.pathname.split("/")[1] === "c") ||
      (prevPath.current?.split("/")[1] === "c" && location.pathname.split("/")[1] === "b");

    if (!isNavigatingBetweenBAndC) {
      fetchBoardData();
    }

    // Store the current path as previous for next render
    prevPath.current = location.pathname;
  }, [dispatch, boardId, location.pathname]);


  const fetchCardsForList = async (listId) => {
    try {
      const { cards } = await dispatch(fetchCardsByListId(listId)).unwrap();

      setBoard((prevBoard) => {
        const updatedActionLists = prevBoard.actionLists.map((list) =>
          list._id === listId ? { ...list, cards: cards || list.cards } : list
        );

        return { ...prevBoard, actionLists: updatedActionLists };
      });

    } catch (error) {
      console.error(`Error fetching cards for list ${listId}:`, error);
    }
  };


  useEffect(() => {
    if (boardId && _boardId !== boardId) {
      dispatch(setBoardId(boardId));
    }

    if (boardLoading) {
      fetchedListsRef.current.clear();
    }

  }, [boardId, _boardId, dispatch]);

  useEffect(() => {
    if (_boardId && !actionLists[_boardId]) {
      dispatch(fetchBoardLists(_boardId));
    }
  }, [_boardId]);


  useEffect(() => {
    if (!board.wShortId) return;
    dispatch(fetchMembers({ type: 'workspace', id: board.wShortId }));
  }, [dispatch, board.wShortId]);


  // Handle adding new action list
  const handleAddList = async (e) => {
    e.preventDefault();

    const newList = { id: Date.now(), title: listTitle }; // Temporary list with a unique id

    // Optimistically update the state
    setBoard((prev) => ({
      ...prev,
      actionLists: [...prev.actionLists, newList],
    }));

    setActionListTitle('');
    setShowForm(true); // Reopen form
    setTimeout(() => {
      textareaRef.current?.focus();
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Scroll into view again
    }, 0);

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(`${config.API_URI}/api/actionLists`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({ boardId: _boardId, title: listTitle }),
      });

      if (!response.ok) throw new Error('Failed to add action list');

      const data = await response.json();
      const addedList = data.actionList;

      // Replace the temporary list with the one from the server
      setBoard((prev) => ({
        ...prev,
        actionLists: prev.actionLists.map((list) =>
          list.id === newList.id ? addedList : list
        ),
      }));


      dispatch(showToast({ message: 'Action list added successfully!', type: 'success' }));
    } catch (error) {
      console.error('Error adding action list:', error);

      // Revert the optimistic update
      setBoard((prev) => ({
        ...prev,
        actionLists: prev.actionLists.filter((list) => list.id !== newList.id),
      }));

      dispatch(showToast({ message: 'Error adding action list', type: 'error' }));
    }
  };

  // Handle textarea height adjustment
  useEffect(() => {
    if (textareaRef.current) {
      const resetHeight = () => {
        textareaRef.current.style.height = '20px';
        const scrollHeight = textareaRef.current.scrollHeight - 20;
        textareaRef.current.style.height = `${scrollHeight}px`;
      };

      resetHeight();
    }
  }, [listTitle]);


  useEffect(() => {
    const element = parentRef.current;

    if (!element) return; // Early exit if ref.current is null or undefined

    const autoScroll = autoScrollForElements({
      element,
      getConfiguration: () => ({
        maxScrollSpeed: 'fast',
        allowedAxis: 'horizontal',
      }),
    });


    return () => {
      autoScroll();
    };

  }, [parentRef.current]); // Re-run effect only if actionList or ref.current changes


  const handleFetchList = useCallback((list, actualIndex) => {

    if (list && !fetchedListsRef.current.has(list._id)) {
      fetchedListsRef.current.add(list._id);
      fetchCardsForList(list._id);
    }
  }, []);


  useEffect(() => {
    if (!user) return;

    const userId = user?.user?._id;
    const username = user.user.username;
    const socket = socketIOClient(config.API_URI);

    socket.emit('joinUser', userId, username);

    socket.on('user-action', (data) => {
      const { targetListId, targetPosition, card } = data;

      console.log({ card });

      switch (data.action) {
        case ActionTypes.CARD_UPDATED:
          // dispatch(fetchCardById(card.shortId));
          // dispatch(fetchBoardLists(card.board));

          setBoard((prevBoard) => ({
            ...prevBoard,
            actionLists: prevBoard.actionLists.map((actionList) => ({
              ...actionList,
              cards: actionList.cards.map((currentCard) => {
                return currentCard.shortId === card.shortId ? { ...currentCard, title: card.title } : currentCard;
              }
              ),
            })),
          }));

          break;
        case ActionTypes.CARD_MOVED:
          dispatch(fetchCardById(card.shortId));
          dispatch(setCardMoveTarget({ targetListId, targetPosition, card, cardAction: 'MOVE' }));
          dispatch(fetchBoardLists(card.board));
          break;
        case ActionTypes.MEMBER_ADDED:
        case ActionTypes.MEMBER_REMOVED:
          // updateCardDetails(card?.shortId, setBoard, { users: card?.users });
          console.log('Member added/removed');
          break;

        default:
          console.log('Unhandled user action:', data.action);
      }
    });


    return () => {
      socket.disconnect();
    };
  }, [dispatch, user]);


  // Loading state handling
  if ((isLoading && (!board || board.actionLists.length < 1)) || boardLoading) {
    return <SkeletonBoard />;
  }


  // Event handlers for form visibility
  const handleShowForm = () => {
    setShowForm(true);
    setTimeout(() => {
      textareaRef.current?.focus();
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Scroll into view
    }, 0);
  };

  const handleHideForm = () => setShowForm(false);
  const handleToggleOptions = (listId) => setOpenListId((prev) => (prev === listId ? null : listId));
  const handleDeleteList = (listId) => setBoard((prev) => ({
    ...prev,
    actionLists: prev.actionLists.filter((list) => list._id !== listId),
  }));

  // Styles based on dragging state
  const style = {
    ...(highlight ? { border: '2px dotted #ddd' } : {})
  };


  return (
    <>
      {board.title && (
        // <Helmet>
        //   <title>{board.title} | Zoobbe</title>
        //   <meta name="description" content="Your dynamic description here" />
        // </Helmet>

        <MetaTags
          title={`${board.title} | Zoobbe`}
          ogImage={board?.cover?.url}
          ogUrl={board.url}
        />
      )}

      <Navbar
        cardCount={cardCount}
        board={board}
        setBoard={setBoard}
        isLoading={isLoading}
        ref={navbarRef}
      />
      <div
        className={`zoobbe-workspace-board-container${isScrolling ? ' scrolling' : ''}`}
        ref={parentRef}
        style={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <ol
          className='zoobbe-board'
          style={{
            // ...(highlight ? { backgroundColor: 'blue' } : { backgroundColor: 'red' }),
            width: virtualizer.getTotalSize() + (virtualizer.getVirtualItems().length + 1) * 10,
            // width: virtualizer.getTotalSize() + (virtualizer.getVirtualItems().length + 1) * 20,
            height: `calc(100vh - ${52 + navbarHeight + 35}px)`,
            position: 'relative',

          }}
          ref={boardRef}
        >
          {virtualizer.getVirtualItems().map((virtualColumn, index) => {

            const itemWidth = 280; // Your item width
            const actualIndex = Math.floor(virtualColumn.start / itemWidth);
            const list = board.actionLists[actualIndex];

            // Ensure actualIndex is within bounds
            if (actualIndex >= 0 && actualIndex < board.actionLists.length) {
              handleFetchList(list, actualIndex);
            }

            return (
              <li
                className='zoobbe-action-list'
                key={list?._id || actualIndex}
                ref={el => {
                  if (el) virtualizer.measureElement(el);
                }}
                data-index={index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: 280,
                  transform: `translateX(${virtualColumn.start + (index * 10)}px)`
                  // transform: `translateX(${virtualColumn.start}px)`
                }}
                data-listid={list?._id}

              >
                {list ? (
                  <ActionListPND
                    number={virtualColumn.index}
                    actionList={list}
                    isOpen={openListId === list._id}
                    onToggleOptions={() => handleToggleOptions(list._id)}
                    onDelete={handleDeleteList}
                    board={{ ...board }}
                    setBoard={setBoard}
                    boardId={board.shortId}
                    boardScrollRef={parentRef}

                  />
                ) : (
                  // Render the "Add List" button and form at the end
                  <div className="action-list-button-wrapper" ref={formRef}>
                    {showForm ? (
                      <form onSubmit={handleAddList} style={{ width: '100%', padding: '10px' }}>
                        <textarea
                          className="add-new-list"
                          ref={textareaRef}
                          name="add-new-list"
                          placeholder="Enter list title"
                          rows="1"
                          value={listTitle}
                          onChange={(e) => setActionListTitle(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleAddList(e);
                            }
                          }}
                          required
                        />
                        <div className="footer-buttons">
                          <button type="submit">Add List</button>
                          <button type="button" onClick={handleHideForm}>
                            <span className="material-symbols-outlined">close</span>
                          </button>
                        </div>
                      </form>
                    ) : (
                      <>
                        {
                          !isLoading && (
                            <AddActionListButton onClick={handleShowForm} />
                          )
                        }
                      </>
                    )}
                  </div>
                )}
              </li>
            )
          })}
        </ol>
      </div>

    </>
  );
};

export default Board;
