export const ActionTypes = {
    MEMBER_ADDED: 'MEMBER_ADDED',
    MEMBER_REMOVED: 'MEMBER_REMOVED',
    ROLE_CHANGED: 'ROLE_CHANGED',
    WORKSPACE_CREATED: 'WORKSPACE_CREATED',
    WORKSPACE_UPDATED: 'WORKSPACE_UPDATED',
    WORKSPACE_DELETED: 'WORKSPACE_DELETED',
    BOARD_CREATED: 'BOARD_CREATED',
    BOARD_UPDATED: 'BOARD_UPDATED',
    BOARD_DELETED: 'BOARD_DELETED',
    ACTIONLIST_CREATED: 'ACTIONLIST_CREATED',
    ACTIONLIST_UPDATED: 'ACTIONLIST_UPDATED',
    ACTIONLIST_DELETED: 'ACTIONLIST_DELETED',
    CARD_CREATED: 'CARD_CREATED',
    CARD_UPDATED: 'CARD_UPDATED',
    CARD_DELETED: 'CARD_DELETED',
    COMMENT_ADDED: 'COMMENT_ADDED',
    COMMENT_DELETED: 'COMMENT_DELETED',
    COMMENT_UPDATED: 'COMMENT_UPDATED',
    CHECKLIST_ADDED: 'CHECKLIST_ADDED',
    CHECKLIST_DELETED: 'CHECKLIST_DELETED',
    CHECKLIST_UPDATED: 'CHECKLIST_UPDATED',
    CHECKLIST_ITEM_ADDED: 'CHECKLIST_ITEM_ADDED',
    CHECKLIST_ITEM_DELETED: 'CHECKLIST_ITEM_DELETED',
    CHECKLIST_ITEM_UPDATED: 'CHECKLIST_ITEM_UPDATED',
    ATTACHMENT_ADDED: 'ATTACHMENT_ADDED',
    ATTACHMENT_DELETED: 'ATTACHMENT_DELETED',
    ATTACHMENT_UPDATED: 'ATTACHMENT_UPDATED',
    DUEDATE_ADDED: 'DUEDATE_ADDED',
    DUEDATE_REMOVED: 'DUEDATE_REMOVED',
    DUEDATE_UPDATED: 'DUEDATE_UPDATED',
    LABEL_ADDED: 'LABEL_ADDED',
    LABEL_DELETED: 'LABEL_DELETED',
    LABEL_UPDATED: 'LABEL_UPDATED',
    CARD_ARCHIVED: 'CARD_ARCHIVED',
    CARD_UNARCHIVED: 'CARD_UNARCHIVED',
    CARD_MOVED: 'CARD_MOVED',
    LIST_MOVED: 'LIST_MOVED',

};
