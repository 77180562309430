import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyB3yg_3PfZLgOwFbXJh_U7BV1jukE95R-0",
    authDomain: "zoobbe-3c098.firebaseapp.com",
    projectId: "zoobbe-3c098",
    storageBucket: "zoobbe-3c098.firebasestorage.app",
    messagingSenderId: "635946153936",
    appId: "1:635946153936:web:4b159228c5616be819ebd5",
    measurementId: "G-21224880KZ"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
