import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import './scss/CardTable.scss';
import ProfileNavbar from './ProfileNavbar';
import { config } from '../../config';
import Spinner from '../Global/Spinner';

const CardTable = () => {
    const { user, loading: userLoading } = useSelector((state) => state.user);
    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef(null);
    const { userName } = useParams();

    const fetchCards = useCallback(async (pageNumber) => {
        if (!hasMore) return;

        const token = localStorage.getItem('accessToken');
        if (!token) {
            setIsLoading(false);
            return;
        }

        if (pageNumber === 1) {
            setIsLoading(true);
        } else {
            setIsFetchingMore(true); // Show spinner for additional fetches
        }

        try {
            const response = await fetch(`${config.API_URI}/api/${userName}/cards?page=${pageNumber}&limit=20`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch cards');
            }

            const data = await response.json();
            setCards((prev) => [...prev, ...data.cards]);
            setHasMore(data.hasMore);
        } catch (error) {
            console.error('Error fetching cards:', error);
        } finally {
            setIsLoading(false);
            setIsFetchingMore(false);
        }
    }, [userName, hasMore]);

    useEffect(() => {
        fetchCards(page);
    }, [page, fetchCards]);

    const lastCardRef = useCallback(
        (node) => {
            if (isFetchingMore) return;

            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage((prevPage) => prevPage + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [isFetchingMore, hasMore]
    );

    return (
        <div className={`profile-page${isLoading ? ' card-loading' : ''}`}>
            <ProfileNavbar />
            {isLoading && (
                <div className="loading-more" style={{ display: 'flex', justifyContent: 'center', height: '200px', alignItems: 'center' }}>
                    <Spinner size={30} color="#3498db" speed={1.5} />
                </div>
            )}

            {!isLoading && cards.length > 0 && (
                <div className="card-table">
                    <div className="table-header">
                        <div className="header-item">Card</div>
                        <div className="header-item">List</div>
                        <div className="header-item">Labels</div>
                        <div className="header-item">Due date</div>
                        <div className="header-item">Board</div>
                    </div>

                    <div className="table-body">
                        {cards.map((card, index) => {
                            const isLastItem = index === cards.length - 1;

                            return (
                                <div
                                    ref={isLastItem ? lastCardRef : null}
                                    key={index}
                                    className="table-row"
                                >
                                    <Link to={card.permalink}>
                                        <div className="table-cell">{card.title}</div>
                                    </Link>
                                    <div className="table-cell">{card.actionList?.title || 'N/A'}</div>
                                    <div className="table-cell">
                                        <div className="labels">
                                            {card.labels.map((label, labelIndex) => (
                                                <span
                                                    key={labelIndex}
                                                    style={{ backgroundColor: label.color }}
                                                    className="label"
                                                ></span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="table-cell">
                                        {card.dueDate?.date && (
                                            <>
                                                {format(new Date(card.dueDate.date), 'MMM dd')}, {card.dueDate.dueTime}
                                            </>
                                        )}
                                    </div>
                                    <Link to={`${card.boardLink}`}>
                                        <div className="table-cell board-info">
                                            <span>{card.boardTitle}</span>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {isFetchingMore && (
                <div className="loading-more" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner size={30} color="#3498db" speed={1.5} />
                </div>
            )}

            {!isLoading && !userLoading && cards.length === 0 && (
                <div className="empty-activity">
                    <h2>No cards found</h2>
                    <p>You haven't performed any activities yet.</p>
                </div>
            )}
        </div>
    );
};

export default CardTable;
