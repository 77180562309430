import React from 'react';
import './scss/terms.scss';  // Import the SCSS file

const Policy = () => {
    return (
        <div className="page-container">
            <h1>Privacy Policy</h1>
            <p><strong>Last Updated:</strong> Mar 7,  2025</p>
            <section>
                <p>
                    This Privacy Policy explains how Zoobbe collects, uses, and protects your personal information when you use our Service. By using Zoobbe, you agree to the collection and use of your information as outlined in this policy.
                </p>
            </section>

            <section>
                <h3>1. Information We Collect</h3>
                <p>- **Personal Information:** When you create an account, we collect personal information such as your name, email address, and any profile pictures or data you upload.</p>
                <p>- **Usage Data:** We may collect information on how you use the Service, such as device information, IP addresses, browser type, and usage patterns.</p>
            </section>

            <section>
                <h3>2. How We Use Your Information</h3>
                <p>- To provide and improve the Service.</p>
                <p>- To communicate with you regarding your account or updates to the Service.</p>
                <p>- To send marketing communications (if you opt-in).</p>
                <p>- To ensure security and prevent fraud.</p>
            </section>

            <section>
                <h3>3. Sharing Your Information</h3>
                <p>- We do not share your personal information with third parties unless required by law or for business operations (e.g., payment processors, email services).</p>
                <p>- We may share anonymous or aggregated data for analytics purposes.</p>
            </section>

            <section>
                <h3>4. Cookies and Tracking Technologies</h3>
                <p>- We use cookies and similar technologies to enhance your experience on Zoobbe. You can control cookie preferences in your browser settings.</p>
            </section>

            <section>
                <h3>5. Data Security</h3>
                <p>- We take reasonable measures to protect your personal data, but no method of transmission over the internet is 100% secure. You use the Service at your own risk.</p>
            </section>

            <section>
                <h3>6. Your Rights</h3>
                <p>- You have the right to access, update, or delete your personal information. To exercise these rights, please contact us at [support email].</p>
            </section>

            <section>
                <h3>7. Children’s Privacy</h3>
                <p>- Zoobbe is not intended for children under the age of 13. We do not knowingly collect personal information from children. If we discover we have collected such information, we will delete it promptly.</p>
            </section>

            <section>
                <h3>8. Changes to Privacy Policy</h3>
                <p>- We may update this Privacy Policy from time to time. The updated version will be posted on this page with the effective date. Continued use of the Service constitutes your acceptance of any changes.</p>
            </section>

            <section>
                <h3>9. Contact Information</h3>
                <p>- For any questions or concerns regarding this Privacy Policy, please contact us at [support email].</p>
            </section>
        </div>
    );
};

export default Policy;
