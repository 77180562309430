import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { config } from '../../config';
import Spinner from '../Global/Spinner';

const Verify = () => {
    const [status, setStatus] = useState('Verifying...');
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        if (token) {
            fetch(`${config.API_URI}/api/users/verify`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ token }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.accessToken) {
                        // ✅ Store token just like in login
                        localStorage.setItem('accessToken', data.accessToken);

                        // ✅ Fetch user details
                        fetch(`${config.API_URI}/api/users/me`, {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': `Bearer ${data.accessToken}`,
                            },
                            credentials: 'include',
                        })
                            .then(res => res.json())
                            .then(userData => {
                                if (userData.user) {
                                    setStatus('Verification successful! Redirecting...');
                                    setTimeout(() => {
                                        window.location.href = `/u/${userData.user.username}/boards`;
                                    }, 2000);
                                }
                            });
                    } else {
                        setStatus('Verification failed. Token may be invalid or expired.');
                    }
                })
                .catch(error => {
                    console.error('Verification error:', error);
                    setStatus('An error occurred during verification.');
                });
        } else {
            setStatus('Invalid token.');
        }
    }, [token, navigate]);

    return (
        <div className="verify-container">
            <div className="verifying-text">
                <Spinner size={18} color="var(--brand-color)" speed={2.5} strokeWidth={6} />
                <span>{status}</span>
            </div>
        </div>
    );
};

export default Verify;
