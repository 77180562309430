import React from 'react';
import './scss/EditorInfo.scss';
import { Link } from 'react-router-dom';

const EditorInfo = () => {
    return (
        <div className="zoobbe-info">
            <h2>Zoobbe Editor Guide</h2>

            <div className="group">
                <p><strong>Getting Started</strong></p>
                <p>The Zoobbe Editor is a real-time text editor for seamless collaboration.</p>
            </div>

            <div className="group">
                <p><strong>Features</strong></p>
                <ul>
                    <li>Rich Text Editing – Format with bold, italics, etc.</li>
                    <li>Mentions & Tags – Use <code>@</code> and <code>#</code>.</li>
                    <li>Markdown Support – Use Markdown syntax.</li>
                    <li>Embeds & Media – Add images, links, and more.</li>
                </ul>
            </div>

            <div className="group">
                <p><strong>Keyboard Shortcuts</strong></p>
                <ul>
                    <li><code>Ctrl + B</code> – Bold</li>
                    <li><code>Ctrl + I</code> – Italics</li>
                    <li><code>Ctrl + K</code> – Add Link</li>
                    <li><code>Ctrl + Z</code> – Undo</li>
                    <li><code>Ctrl + Y</code> – Redo</li>
                </ul>
            </div>

            <div className="group">
                <p><strong>Need Help?</strong></p>
                <p>Contact support or visit our <Link to="/help">help center</Link>.</p>
            </div>
        </div>
    );
};

export default EditorInfo;
