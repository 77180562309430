import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './scss/SkeletonCardDetails.scss';

const SkeletonCardDetails = () => {
    return (
        <div className="zoobbe-card-wrapper skeleton">
            <div className='zoobbe-card-modal-container'>
                <div className="zoobbe-card-details">
                    <div className="zoobbe-card-content">
                        <div className="zoobbe-card-details-body">
                            <div className="zoobbe-card-details-header">
                                <div className='card-heading'>
                                    <Skeleton className="skeleton-avatar" circle height={35} />
                                    <Skeleton className="skeleton-title" height={30} />
                                    <div className="close-card-container" style={{ position: 'static' }}>
                                        <Skeleton className="skeleton-close" height={30} />
                                    </div>
                                </div>
                                <div className="zoobbe-card-details-status">
                                    <Skeleton className="skeleton-status" />
                                </div>
                            </div>

                            <div className="zoobbe-card-details-info">
                                <div className='zoobbe-card-details-info-left'>
                                    <div className='card-details-top'>
                                        <div className='card-members'>
                                            <Skeleton className="skeleton-member" circle height={30} />
                                            <Skeleton className="skeleton-member" circle height={30} />
                                            <Skeleton className="skeleton-member" circle height={30} />
                                        </div>

                                        <div className='watchers'>
                                            <Skeleton className="skeleton-watcher-text" height={30} />
                                            <Skeleton className="skeleton-watcher-icon" height={30} />
                                        </div>

                                        <Skeleton className="skeleton-watchers-extra" height={30} />
                                    </div>

                                    <div className="zoobbe-duedate">
                                        <Skeleton className="skeleton-duedate-label" height={20} />
                                        <Skeleton className="skeleton-duedate-value" height={30} />
                                    </div>

                                    <div className="zoobbe-description">
                                        <Skeleton className="skeleton-description-title" height={15} />
                                        <Skeleton className="skeleton-description-content" count={5} height={8} />
                                    </div>

                                    <div className="zoobbe-attachment">
                                        <Skeleton className="skeleton-attachment-thumbnail" height={50} />
                                        <Skeleton className="skeleton-attachment-text" count={3} height={8} />
                                    </div>
                                    <div className="zoobbe-attachment">
                                        <Skeleton className="skeleton-attachment-thumbnail" height={50} />
                                        <Skeleton className="skeleton-attachment-text" count={3} height={8} />
                                    </div>

                                    <Skeleton className="skeleton-checklist" height={20} />

                                    <div className="zoobbe-card-details-footer-content">
                                        <div className="zoobbe-card-content" style={{ paddingLeft: '0px' }}>
                                            <Skeleton className="skeleton-comment-avatar" height={30} />
                                            <Skeleton className="skeleton-comment-box" height={30} />
                                        </div>
                                        <Skeleton className="skeleton-comment-lines" height={8} count={3} />
                                    </div>
                                </div>

                                <div className='zoobbe-card-details-info-right'>
                                    <Skeleton className="skeleton-sidebar-item" height={30} />
                                    <Skeleton className="skeleton-sidebar-item" height={30} />
                                    <Skeleton className="skeleton-sidebar-item" height={30} />
                                    <Skeleton className="skeleton-sidebar-item" height={30} />
                                    <Skeleton className="skeleton-sidebar-item" height={30} />
                                    <Skeleton className="skeleton-sidebar-item" height={30} />
                                    <Skeleton className="skeleton-sidebar-item" height={30} />
                                    <Skeleton className="skeleton-sidebar-item" height={30} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonCardDetails;
