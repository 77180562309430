import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toSlug, find } from '../../utils/helpers';

import { openModal } from '../../redux/Slices/modalSlice';
import { config } from '../../config';

import CreateWorkspace from './CreateWorkspace';

import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';

import WorkspaceSidebar from './WorkspaceSidebar';

import './scss/workspace.scss';
import './scss/Boards.scss';

import BoardSection from './BoardSection';

const Boards = () => {
    const { workspaces, status, error } = useSelector(state => state.workspaces);
    const { shortId } = useParams();
    const dispatch = useDispatch();

    // Find the current workspace and boards based on the shortId
    const boards = find.get(workspaces, { name: 'boardsByShortName', workspace: shortId });
    const workspace = find.get(workspaces, { name: 'workspace', workspace: shortId });

    // Dispatch fetchWorkspaces on component mount
    useEffect(() => {
        dispatch(fetchWorkspaces());
    }, [dispatch]);


    const workspaceName = workspace?.name || '';


    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
    };

    const [openWorkspaces, setOpenWorkspaces] = useState({});

    useEffect(() => {
        const initialOpenWorkspaces = {};
        workspaces.slice(0, 2).forEach(workspace => {
            initialOpenWorkspaces[workspace.name] = true;
        });
        setOpenWorkspaces(initialOpenWorkspaces);
    }, [workspaces]);

    const toggleOptions = (workspaceName) => {
        setOpenWorkspaces(prevState => ({
            ...prevState,
            [workspaceName]: !prevState[workspaceName]
        }));
    };

    return (
        <div className="zppbbe-workspace-wrapper">
            <div className="zoobbe-workspace-container">
                <WorkspaceSidebar
                    openWorkspaces={openWorkspaces}
                    toggleOptions={toggleOptions}
                    handleCreateWorkspace={handleCreateWorkspace}
                />

                <div className="zoobbe-main-content">
                    {workspace ? (
                        <>
                            <div className="board-header">
                                <div className="board-icon">
                                    <span className="icon-text">Z</span>
                                </div>
                                <div className="board-details">
                                    <div className="board-name">
                                        <span>{workspaceName}</span>
                                        <span className="material-symbols-outlined">
                                            edit
                                        </span>
                                    </div>
                                    <div className="board-visibility">
                                        <span className="material-symbols-outlined">
                                            lock
                                        </span>
                                        <span>Private</span>
                                    </div>
                                </div>
                            </div>

                            <div className='boards-content-header'>
                                <span className='material-symbols-outlined'>
                                    person
                                </span>
                                <h4 className='header-title'>
                                    Your boards
                                </h4>
                            </div>

                            <BoardSection workspace={workspace} defaultSelectedOption={0} />
                        </>
                    ) : (
                        <p>Loading workspace...</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Boards;
