import React, { createContext, useState, useEffect } from "react";
import io from "socket.io-client";
import { config } from "../config";
import { useDispatch, useSelector } from "react-redux";

const socket = io(config.API_URI, { autoConnect: false });

export const OnlineStatusContext = createContext();

const OnlineStatusProvider = ({ children }) => {
    const [onlineUsers, setOnlineUsers] = useState({});
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        if (!user?.user?._id) return;

        const userId = user.user._id;
        socket.connect();
        socket.emit("join", userId);

        const handleUserStatus = (data) => {
            setOnlineUsers((prev) => ({
                ...prev,
                [data.userId]: data.online,
            }));
        };

        socket.on("user-online-status", handleUserStatus);

        // Send heartbeat every 5 seconds
        const heartbeat = setInterval(() => {
            socket.emit("heartbeat", userId);
        }, 5000);

        return () => {
            clearInterval(heartbeat);
            socket.emit("leave", userId); // Ensure proper cleanup
            socket.off("user-online-status", handleUserStatus);
        };
    }, [user]);

    return (
        <OnlineStatusContext.Provider value={onlineUsers}>
            {children}
        </OnlineStatusContext.Provider>
    );
};

export default OnlineStatusProvider;
