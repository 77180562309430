import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ReactCloudflareTurnstile from "react-cloudflare-turnstile";

import { config } from '../../config';
import './index.scss';
import { fetchUsers } from '../../redux/Slices/thunks';
import Google from '../icons/Google';
import Logo, { LogoLight } from '../Header/Logo';
import { useGoogleLogin } from '@react-oauth/google';
import { useTheme } from '../../context/ThemeContext';

const Signup = () => {
  const [signUpData, setSignUpData] = useState({
    name: '',
    email: '',
    password: ''
  });

  const [passwordError, setPasswordError] = useState(''); // New state for password error
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [buttonText, setButtonText] = useState('Continue');
  const [recaptchaToken, setCaptchaToken] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();


  // Password validation function
  const isPasswordStrong = (password) => {
    const lengthCheck = password.length >= 8;
    const uppercaseCheck = /[A-Z]/.test(password);
    const lowercaseCheck = /[a-z]/.test(password);
    const numberCheck = /\d/.test(password);
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return lengthCheck && uppercaseCheck && lowercaseCheck && numberCheck && specialCharCheck;
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignUpData((prevData) => ({ ...prevData, [name]: value }));
    setMessage(null);
    setError(null);

    // Check password strength on change
    if (name === 'password') {
      if (!isPasswordStrong(value)) {
        setPasswordError('Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.');
      } else {
        setPasswordError('');
      }
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (passwordError) return; // Prevent submission if password is not strong



    setIsSubmit(true);
    setButtonText('Signing up...');

    try {

      const response = await fetch(config.API_URI + '/api/users/register', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ ...signUpData, recaptchaToken })
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message);
        setMessage(null);
        setIsSubmit(false);
        setButtonText('Continue');
      } else {
        setMessage("Thank you for signing up! We've sent a verification email to your inbox. Please check your email and click the link to verify your account.");
        setError(null);
        dispatch(fetchUsers());

        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError('An error occurred while signing up');
      setMessage(null);
      setIsSubmit(false);
      setButtonText('Continue');
    }
  };

  const handleGoogleAuth = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        // Call the unified backend endpoint
        const res = await fetch(config.API_URI + '/api/users/auth/google', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ token: response.access_token, action: 'signup' }),
        });

        if (!res.ok) {
          const errorData = await res.json();
          throw new Error(errorData.message || 'Google authentication failed');
        }

        const data = await res.json();

        // Save access token and handle redirection
        localStorage.setItem('accessToken', data.accessToken);

        setMessage(data.message); // Displays either "Signup successful" or "Login successful"
        // setIsLoginSuccess(true);

        // Fetch user details if needed
        const userResponse = await fetch(config.API_URI + '/api/users/me', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${data.accessToken}`,
          },
          credentials: 'include',
        });

        if (!userResponse.ok) {
          throw new Error('Failed to fetch user data');
        }

        const userData = await userResponse.json();
        const username = userData.user.username;

        setTimeout(() => {
          window.location.href = `/u/${username}/boards`;
        }, 2000);
      } catch (error) {
        console.error('Error during Google authentication:', error);
        setMessage(error.message || 'Google authentication failed. Please try again.');
        setTimeout(() => setMessage(''), 5000);
      }
    },
    onError: () => {
      setMessage('Google authentication failed. Please try again.');
      setTimeout(() => setMessage(''), 5000);
    },
  });


  const { theme } = useTheme();

  const [systemTheme, setSystemTheme] = useState('light'); // Default to light

  useEffect(() => {
    // Check the system theme and set it
    const updateSystemTheme = () => {
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setSystemTheme(isDarkMode ? 'dark' : 'light');
    };

    // Initial check
    updateSystemTheme();

    // Add a listener for changes in the system theme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateSystemTheme);

    // Cleanup the event listener on unmount
    return () => {
      mediaQuery.removeEventListener('change', updateSystemTheme);
    };
  }, []);

  // Determine which logo to display
  const currentTheme = theme === 'system' ? systemTheme : theme;

  return (
    <>
      <Helmet>
        <title>Signup - Zoobbe</title>
      </Helmet>

      <div className="login-container">
        <div className="login-box">
          <h1 className="login-logo">
            {currentTheme === 'dark' && <Logo />}
            {currentTheme === 'light' && <LogoLight />}
          </h1>
          <h2 className="login-heading">Sign up to continue</h2>
          <form className="signup-form" onSubmit={handleSignup}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={signUpData.name}
              onChange={handleChange}
              className="login-input"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={signUpData.email}
              onChange={handleChange}
              className="login-input"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={signUpData.password}
              onChange={handleChange}
              className="login-input"
              required
            />
            {/* Display password error message if password is weak */}
            {passwordError && <p className="error-message">{passwordError}</p>}

            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}


            <div className="login-links">
              Already have an account?
              <span> · </span>
              <Link to="/login" className="login-link">Login</Link>
            </div>

            <p className="legal-message">
              By signing up, I accept the Zoobbe, Inc.{' '}
              <Link to="/legal/terms-of-service" target="_blank" >
                Terms of Service
              </Link>{' '}
              and acknowledge the{' '}
              <Link to="/legal/privacy-policy" target="_blank" >
                Privacy Policy
              </Link>.
            </p>

            <ReactCloudflareTurnstile
              turnstileSiteKey={config.CLOUDFLARE_RECAPTCHA_SITE_KEY}
              callback={(token) => {
                console.log({ token });
                setCaptchaToken(token)
              }}
            />

            <button type="submit" className="login-button" disabled={isSubmit || passwordError}>
              {buttonText}
            </button>
          </form>

          <p className="login-or">Or continue with:</p>
          <div className="login-social-buttons">

            <button className="social-button google" onClick={() => handleGoogleAuth()}> <Google /> Google</button>

            {/* <GoogleLogin onSuccess={handleSuccess} onError={handleError} /> */}

            {/* <button className="social-button microsoft">Microsoft</button>
            <button className="social-button apple">Apple</button>
            <button className="social-button slack">Slack</button> */}
          </div>

        </div>
      </div>
    </>
  );
};

export default Signup;
