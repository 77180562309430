import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/Attachment.scss';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { cardData, fetchCardById } from '../../redux/Slices/cardSlice';

import { fetchAttachments, uploadAttachment, resetStatus, setUploadProgress } from '../../redux/Slices/attachmentSlice';
import { hideSnackBar, showSnackBar } from '../../redux/Slices/snackbarSlice';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_IMAGE_COMMAND } from '../LexicalEditor/plugins/ImagesPlugin';

import {
    $getSelection,
    $createTextNode,
    $insertNodes
} from "lexical";

import { LinkNode } from '@lexical/link';
import { config } from '../../config';
import { isValidFile } from '../../utils/helpers';

const Attachment = ({ type, editor }) => {
    const [title, setTitle] = useState('Attachment');
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [isUrlValid, setIsUrlValid] = useState(true);
    const [isTextareaFocused, setIsTextareaFocused] = useState(false); // New focus state
    const { status, error } = useSelector((state) => state.attachments);

    const urlRef = useRef(null);
    const fileInputRef = useRef(null);

    const { card } = useSelector((state) => state.card);
    const cardId = card.shortId;
    const dispatch = useDispatch();

    // Set title based on type
    useEffect(() => {
        setTitle(type === 'IMAGE' ? 'Image' : type === 'COVER' ? 'Cover' : 'Attachment');
    }, [type]);


    useEffect(() => {
        if (urlRef.current) {
            urlRef.current.style.height = '22px';
            urlRef.current.style.height = `${urlRef.current.scrollHeight}px`;
        }
    }, [attachmentUrl]);

    useEffect(() => {
        if (status === 'uploadSucceeded') {
            dispatch(fetchAttachments(cardId));
        }
    }, [status, dispatch, cardId]);

    // Image URL validation function
    const validateImageUrl = (url) => {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
        return imageExtensions.test(url);
    };

    const handleUrlChange = (e) => {
        const url = e.target.value;
        setAttachmentUrl(url);
        setIsUrlValid(validateImageUrl(url));
    };

    const fetchAttachment = async (cardId, attachmentId) => {
        const token = localStorage.getItem('accessToken');

        try {
            const response = await fetch(`${config.API_URI}/api/cards/attachments/${cardId}/${attachmentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch attachment');
            }

            const data = await response.json();

            setAttachmentUrl(data.attachment.url);

            return data.attachment; // Return the fetched attachment data
        } catch (error) {
            console.error('Error fetching attachment:', error);
            throw error; // Re-throw the error for further handling if needed
        }
    };

    const handleFileUpload = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return;

            // Validate file type and size
            const validation = isValidFile(file);
            if (!validation.valid) {
                return dispatch(showSnackBar({ message: validation.error, type: "error" }));
            }

            dispatch(showSnackBar({ message: "Uploading files...", type: "uploading" }));
            dispatch(setUploadProgress({ percentage: "0", remainingTime: "" }));

            const response = await dispatch(uploadAttachment({ file, cardId, type }));

            if (response.meta.requestStatus !== "fulfilled") {
                throw new Error(response.payload?.message || "File upload failed.");
            }

            const { attachment } = response.payload;
            const fetchedUrl = (await fetchAttachment(cardId, attachment._id)).url;

            if (editor) {
                file.type.startsWith("image/")
                    ? insertImageInEditor(fetchedUrl, file.name)
                    : insertAttachmentLink(fetchedUrl, file.name);
            }

            dispatch(fetchCardById(cardId));
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));

        } catch (error) {
            console.error("File upload error:", error);
            dispatch(showSnackBar({ message: error.message || "An error occurred while uploading the file.", type: "error" }));
        }
    };



    const insertAttachment = (attachmentUrl) => {
        if (validateImageUrl(attachmentUrl)) {
            insertImageInEditor(attachmentUrl);
        } else {
            const fileName = attachmentUrl.split('/').pop();
            insertAttachmentLink(attachmentUrl, fileName);
        }
    };

    // Insert image node into the editor
    const insertImageInEditor = (imageUrl, fileName) => {
        const payload = {
            altText: fileName || "Uploaded Image",
            src: imageUrl,
        };
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);

        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));

    };

    // Insert link to attachment into the editor
    const insertAttachmentLink = (fileUrl, fileName) => {
        editor?.update(() => {
            const selection = $getSelection();
            if (selection) {
                // Create a text node for the file name
                const textNode = $createTextNode(fileName);

                // Create a link node and set its URL
                const linkNode = new LinkNode(fileUrl);
                linkNode.append(textNode);

                $insertNodes([linkNode]);

                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
            }
        });
    };


    const attachmentAcceptType = type === 'IMAGE' || type === 'COVER' ? 'image/*' : 'file';


    return (
        <div className="add-attachment">
            <h2>{title}</h2>

            <div className="group">
                <h3>{type === 'IMAGE' ? 'Image URL' : type === 'COVER' ? 'Cover URL' : 'Attach URL'}</h3>
                <div className={`textarea-wrapper ${isTextareaFocused ? 'focused' : ''}`}>
                    <textarea
                        ref={urlRef}
                        type="url"
                        placeholder={type === 'IMAGE' ? 'Enter image URL' : type === 'COVER' ? 'Enter cover URL' : 'Enter attachment URL'}
                        value={attachmentUrl}
                        onChange={handleUrlChange}
                        rows={1}
                        style={{ overflow: 'hidden' }}
                        spellCheck={false}
                        onFocus={() => setIsTextareaFocused(true)}
                        onBlur={() => setIsTextareaFocused(false)}
                    />
                </div>
                {type === 'IMAGE' && !isUrlValid && <p className="error-message">Please enter a valid image URL (e.g., .bmp, .gif, .jpg, .jpeg, .png, or .webp).</p>}
                <button onClick={() => insertAttachment(attachmentUrl)} disabled={type === 'IMAGE' && !isUrlValid}>Submit</button>
            </div>

            <div className="group">
                <h3>{type === 'IMAGE' ? 'or Upload an image from your computer' : type === 'COVER' ? 'or Upload a cover image from your computer' : 'or Attach a file from your computer'}</h3>
                <p>Click the 'Choose a file' button to browse and select a file from your computer.</p>
                <button onClick={() => fileInputRef.current.click()} className='attach-file-btn'>
                    {status === 'loading' ? 'Uploading...' :
                        status === 'uploadSucceeded' ? 'Upload Successful' :
                            type === 'IMAGE' ? 'Choose an image' : type === 'COVER' ? 'Choose a cover image' : 'Choose a file'
                    } (max 20mb)
                </button>

                <input
                    type="file"
                    accept={attachmentAcceptType}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
            </div>
        </div>
    );

};

export default Attachment;
