import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '../../context/ThemeContext';
import { showToast } from '../../redux/Slices/toastSlice';
import { config } from '../../config';

import './scss/PreferredTheme.scss';

const PreferredTheme = () => {
    const { theme, setTheme } = useTheme();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const handleThemeChange = async (newTheme) => {
        setTheme(newTheme);  // Update UI immediately

        setLoading(true);
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await fetch(`${config.API_URI}/api/users/me/settings`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                credentials: 'include',
                body: JSON.stringify({ preferredTheme: newTheme }),  // Ensure correct field name
            });

            if (!response.ok) {
                throw new Error('Failed to update theme');
            }

            const data = await response.json();  // Get response data

            // Save the selected theme in localStorage
            localStorage.setItem('theme', data.settings.preferredTheme);

            // Show success toast
            dispatch(showToast({ message: 'Theme updated successfully!', type: 'success' }));
        } catch (error) {
            console.error('Error updating theme:', error);
            dispatch(showToast({ message: 'Failed to update theme.', type: 'error' }));
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="preferred-theme">

            <div className="theme-options">
                <ul>
                    <li
                        className={theme === 'system' ? 'selected' : ''}
                        onClick={() => handleThemeChange('system')}
                    >
                        <span className="material-symbols-outlined">
                            contrast
                        </span>
                        System
                    </li>
                    <li
                        className={theme === 'light' ? 'selected' : ''}
                        onClick={() => handleThemeChange('light')}
                    >
                        <span className="material-symbols-outlined">
                            light_mode
                        </span>
                        Light
                    </li>
                    <li
                        className={theme === 'dark' ? 'selected' : ''}
                        onClick={() => handleThemeChange('dark')}
                    >
                        <span className="material-symbols-outlined">
                            dark_mode
                        </span>
                        Dark
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PreferredTheme;
