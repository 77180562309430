import { config } from "../config";

export const sendPushNotification = async ({ memberId, type, data = {} }) => {
    const authToken = localStorage.getItem('accessToken');
    if (!authToken) {
        return false;
    }

    if (!memberId) {
        console.warn('No member if provided, notification not sent.');
        return;
    }

    try {
        const response = await fetch(config.API_URI + '/api/notifications/push/send', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}` // Authentication token
            },
            body: JSON.stringify({
                memberId,
                type,
                data // Optional additional data (e.g., cardId, boardId, etc.)
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to send push notification: ${response.statusText}`);
        }

        console.log('Push notification sent successfully');
    } catch (error) {
        console.error('Error sending push notification:', error);
    }
};


export const updatePushToken = async ({ pushToken }) => {
    try {
        const authToken = localStorage.getItem('accessToken');
        if (!authToken) {
            return false;
        }
        const response = await fetch(config.API_URI + '/api/notifications/push/update', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({ pushToken })
        });

        const data = await response.json();
        if (data.success) {
            console.log('Push token updated successfully');
        } else {
            console.error('Error updating push token:', data.error);
        }
    } catch (error) {
        console.error('Failed to update push token:', error);
    }
};
