// DynamicPopover.js
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImagePlaceholder from './ImagePlaceholder';
import './scss/ProfileCard.scss';
import { useNavigate } from 'react-router-dom';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { removeMember, removeMemberOptimistic } from '../../redux/Slices/memberSlice';
import { fetchActivities } from '../../redux/Slices/activitiesSlice';
import Spinner from '../Global/Spinner';

import { io } from "socket.io-client";
import { config } from '../../config';

const socket = io(config.API_URI);


// import useOnlineStatus from '../../hooks/useOnlineStatus';

const MemberStatus = ({ member }) => {
    return <div className={`online-indicator ${member.online ? 'online' : 'offline'}`}>{member.online}</div>;
};

const ProfileCard = ({ type, cardId }) => {
    const [size, setSize] = useState(100);
    const [isRemoving, setIsRemoving] = useState(false);

    const { member } = useSelector((state) => state.member);
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleProfileCard = () => {
        if (user.user._id === member._id) {
            navigate(`/u/${member.username}/profile`);
        } else {
            navigate(`/u/${member.username}/activity`);
        }
        dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
    };

    const handleRemoveMember = async (e, member) => {
        e.preventDefault();
        setIsRemoving(true);
        const memberId = member._id;

        try {
            const response = await dispatch(removeMember({ cardId, memberId })).unwrap();
            if (response.error) {
                console.error('Member not removed:', response.error);
            } else {
                dispatch(fetchActivities({ cardId }));
                dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, targetId: null }));
            }
        } catch (error) {
            console.error('Failed to remove member:', error);
        } finally {
            setIsRemoving(false);
        }
    };

    if (!member) {
        return null;
    }

    return (
        <div className="profile-card">
            <div className='profile-cover'></div>
            <div className="profile-card__content">
                {member?.profilePicture ? (
                    <div className="image-placeholder-container">
                        <img style={{ width: size, height: size }} key={member._id} src={member.profilePicture} alt={member.name} loading="lazy" />
                        <MemberStatus member={member} />
                    </div>
                ) : (
                    <ImagePlaceholder key={member._id} size={size} member={member} text={member.username} />
                )}
                <div className="profile-card__info">
                    <h2 className="profile-card__name">
                        {member.name}
                        <span className="material-symbols-outlined">verified</span>
                    </h2>
                    <p className="profile-card__username">@{member.username}</p>
                </div>
            </div>
            <div className="profile-card__actions">
                <button className="profile-card__action edit-profile" onClick={handleProfileCard}>
                    <span className="material-symbols-outlined">
                        {user.user._id === member._id ? 'drive_file_rename_outline' : 'visibility'}
                    </span>
                    {user.user._id === member._id ? 'Edit profile' : 'View profile'}
                </button>
                {type === "card" && (
                    <button className="profile-card__action remove-from-card" onClick={(e) => handleRemoveMember(e, member)} disabled={isRemoving}>
                        {isRemoving ? (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Spinner size={20} color="#0966ff" strokeWidth={5} />
                            </div>
                        ) : (
                            user.user._id === member._id ? 'Leave from card' : 'Remove from card'
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

export default ProfileCard;
