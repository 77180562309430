// redux/Slices/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

export const fetchUserByUsername = createAsyncThunk(
    'user/fetchUserByUsername',
    async (username, { rejectWithValue }) => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            return rejectWithValue('No token available');
        }

        try {
            const response = await fetch(`${config.API_URI}/api/users/username/${username}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Invalid token or user not found');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        userInfo: null,
        loading: false,
        error: null,
    },
    reducers: {
        fetchUserStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchUserSuccess: (state, action) => {
            state.loading = false;
            state.user = action.payload;
        },
        fetchUserFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserByUsername.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserByUsername.fulfilled, (state, action) => {
                state.loading = false;
                state.userInfo = action.payload;
            })
            .addCase(fetchUserByUsername.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { fetchUserStart, fetchUserSuccess, fetchUserFailure } = userSlice.actions;
export default userSlice.reducer;
