// src/redux/slices/attachmentSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

export const fetchAttachments = createAsyncThunk(
    'attachments/fetchAttachments',
    async (cardId, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/attachments/${cardId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                const errorMessage = errorData?.error || response.statusText || 'Failed to fetch attachments';
                throw new Error(errorMessage);
            }

            const data = await response.json();
            return data.attachments;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const uploadAttachment = createAsyncThunk(
    'attachments/uploadAttachment',
    async ({ file, cardId, type }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', type);

            const token = localStorage.getItem('accessToken');

            const response = await fetch(config.API_URI + `/api/cards/attachments/${cardId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include',
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                const errorMessage = errorData?.error || response.statusText || 'Failed to upload file';
                throw new Error(errorMessage);
            }

            const data = await response.json();
            return { attachment: data[data.length - 1] };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const uploadBackground = createAsyncThunk(
    'attachments/uploadBackground',
    async ({ file, id, type, apiPath }, { rejectWithValue }) => {
        try {
            if (!file || !id || !apiPath) {
                throw new Error('Invalid parameters: file, id, and apiPath are required');
            }

            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', type);

            const token = localStorage.getItem('accessToken');
            if (!token) {
                throw new Error('Authentication token is missing');
            }

            const response = await fetch(config.API_URI + `/api/${apiPath}/${id}/background`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => null);
                const errorMessage = errorData?.error || response.statusText || 'Failed to upload background';
                throw new Error(errorMessage);
            }

            const data = await response.json();
            if (!Array.isArray(data) || data.length === 0) {
                throw new Error('Unexpected response format: no attachments found');
            }

            return { attachment: data[data.length - 1] };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);




export const editAttachment = createAsyncThunk(
    'attachments/editAttachment',
    async ({ cardId, attachmentId, newName }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI + `/api/cards/attachments/${cardId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ attachmentId, newName })
        });

        if (!response.ok) {
            throw new Error('Failed to update attachment');
        }

        const data = await response.json();
        return data.attachment;
    }
);

export const deleteAttachment = createAsyncThunk(
    'attachments/deleteAttachment',
    async ({ cardId, attachmentId }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI + `/api/cards/attachments/${cardId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ attachmentId })
        });

        if (!response.ok) {
            throw new Error('Failed to delete attachment');
        }

        return attachmentId;
    }
);

const attachmentSlice = createSlice({
    name: 'attachments',
    initialState: {
        attachments: [],
        uploadProgress: { percentage: '0', remainingTime: '' },  // Add this new state variable
        status: 'idle',
        error: null
    },
    reducers: {
        resetStatus(state) {
            state.status = 'idle';
        },
        updateAttachments(state, action) {
            state.attachments = action.payload;
        },
        setUploadProgress(state, action) {  // Add this new reducer
            state.uploadProgress = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAttachments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAttachments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.attachments = action.payload;
            })
            .addCase(fetchAttachments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch attachments';
            })
            .addCase(uploadAttachment.pending, (state) => {
                state.status = 'uploading'; // Set status to uploading when the upload starts
            })
            .addCase(uploadAttachment.fulfilled, (state, action) => {
                const newAttachment = action.payload.attachment;
                if (newAttachment) {
                    state.attachments.push(newAttachment);
                }
                state.status = 'uploadSucceeded'; // Update status to succeeded on success
            })
            .addCase(uploadAttachment.rejected, (state, action) => {
                state.status = 'uploadFailed';
                state.error = action.payload || 'Failed to upload file';
            })
            .addCase(uploadBackground.pending, (state) => {
                state.status = 'uploading'; // Set status to uploading when the upload starts
            })
            .addCase(uploadBackground.fulfilled, (state, action) => {
                state.status = 'uploadSucceeded'; // Update status to succeeded on success
            })
            .addCase(uploadBackground.rejected, (state, action) => {
                state.status = 'uploadFailed';
                state.error = action.payload || 'Failed to upload background';
            })

            .addCase(editAttachment.fulfilled, (state, action) => {
                const updatedAttachment = action.payload;
                const existingIndex = state.attachments.findIndex(att => att._id === updatedAttachment._id);
                if (existingIndex >= 0) {
                    state.attachments[existingIndex] = updatedAttachment;
                }
            })
            .addCase(deleteAttachment.fulfilled, (state, action) => {
                const attachmentId = action.payload;
                state.attachments = state.attachments.filter(att => att._id !== attachmentId);
            });
    }
});

export const { resetStatus, updateAttachments, setUploadProgress } = attachmentSlice.actions;

export default attachmentSlice.reducer;