import React, { useRef } from 'react';
import useHandlePopoverClick from '../../hooks/useHandlePopoverClick';
import { uniqid } from '../../utils/helpers';

const MoreMembersCount = ({ members, uniqid, boardId }) => {

    const { handlePopoverClick } = useHandlePopoverClick();

    return (
        <>
            {
                members.length > 3 && (
                    <span id={`popover-more-members-${uniqid}`} onClick={(e) => handlePopoverClick(e, 'moreMembers', { boardId })} className="zoobbe-more-members member-count" data-popover-trigger>+{members.length - 3}</span>
                )
            }
        </>
    );
};

export default MoreMembersCount;
