import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Provider } from 'react-redux';

import store from './redux/store';
import { getUser, refreshAccessToken } from './utils/helpers';

import Header from './components/Header';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Workspaces from './components/Workspaces';
import UserProfile from './components/Profile/UserProfile';
import Workspace from './components/Profile/Workspace';
import Boards from './components/Profile/Boards';
import Cards from './components/Profile/CardTable';
import Collaborators from './components/Profile/Collaborators';
import Settings from './components/Profile/Settings';
import Notifications from './components/Workspaces/Notifications';

import Modal from './components/Global/ModalManager';
import Toast from './components/Global/Toast';
import PopOver from './components/Global/PopOver';

import { BoardProvider } from './context/BoardContext';

import './index.css';
import ExportImport from './components/Profile/ExportImport';
import BoardWrapper from './components/Workspaces/BoardWrapper';
import Activity from './components/Profile/Activity';

import './App.scss';
import SnackBar from './components/Global/SnackBar';
import VerificationSuccess from './components/Auth/VerificationSuccess';
import VerificationError from './components/Auth/VerificationError';
import VerificationRequired from './components/Auth/VerificationRequired';
import { fetchUser } from './redux/Slices/thunks';
import Reset from './components/Auth/Reset';
import RecoveryMail from './components/Auth/RecoveryMail';
import Verify from './components/Auth/Verify';
import JoinBoard from './components/Members/JoinBoard';
import AcceptInvitationRequest from './components/Members/AcceptInvitationRequest';
import SetPassword from './components/Auth/SetPassword';
import SetPasswordBanner from './components/Auth/PasswordBanner';
import { fetchWorkspaces } from './redux/Slices/workspaceSlice';
import FeedbackButton from './components/Global/FeedbackButton';
import Home from './pages/Home';
import Tooltip from './components/Global/Tooltip';
import MetaTags from './components/Global/MetaTags';
import OnlineStatusProvider from './context/OnlineStatusContext';
import PushNotification from './components/Global/PushNotification';
import Slack from './components/Auth/Slack';
import Policy from './pages/Policy';
import Terms from './pages/Terms';

const ProtectedRoute = ({ element: Component, loggedIn, verified, redirectTo = "/login", ...rest }) => {
  if (!loggedIn) return <Navigate to={redirectTo} />;
  if (!verified) return <Navigate to="/verification-required" />;
  return <Component {...rest} />;
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [verified, setVerified] = useState();
  const [loading, setLoading] = useState(true);
  const [userProfileBoardsLink, setUserProfileBoardsLink] = useState('');
  const isCheckingLoginStatus = useRef(false);
  const { user } = useSelector((state) => state.user);
  const { settings } = user?.user || {};

  const dispatch = useDispatch();

  useEffect(() => {
    const checkLoginStatus = async () => {
      if (isCheckingLoginStatus.current) return;

      isCheckingLoginStatus.current = true;
      const data = await getUser();

      if (data?.user?._id) {
        setLoggedIn(true);
        setVerified(data.user.verified || false);
        setUserProfileBoardsLink(`/u/${data.user.username}/boards`);
      }

      setLoading(false);
    };

    checkLoginStatus();
  }, [dispatch, user]);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch]);

  const { workspaces } = useSelector(state => state.workspaces);

  useEffect(() => {
    if (loggedIn && workspaces.length === 0) {
      dispatch(fetchWorkspaces()).unwrap().then((fetchedWorkspaces) => {
        if (!fetchedWorkspaces || fetchedWorkspaces.length === 0) {
          console.log('Failed to load workspaces or user has no workspaces');
        }
      }).catch((error) => {
        console.error('Error fetching workspaces:', error);
      });
    }
  }, [dispatch, workspaces.length]);

  useEffect(() => {
    if (!settings) return;
    const root = document.documentElement;
    root.setAttribute("data-theme-color", settings.themeColor);

  }, [user]);


  if (loading) {
    return null; // You might want to display a loading spinner here
  }

  return (
    <Provider store={store}>
      <Router>
        <OnlineStatusProvider>
          <AppContent loggedIn={loggedIn} verified={verified} userProfileBoardsLink={userProfileBoardsLink} user={user} />
        </OnlineStatusProvider>
      </Router>
    </Provider>
  );
}

const AppContent = ({ loggedIn, verified, userProfileBoardsLink, user }) => {
  const location = useLocation();

  // Extract the first segment after the domain
  const firstPart = location.pathname.split('/')[1];

  // Define a mapping for the first part to the desired pageId
  const pageIdMapping = {
    'c': 'card-page',
    'w': 'workspace-page',
    'u': 'user-page',
    'b': 'board-page',
  };

  // Get the pageId based on the first segment, or fallback to 'home' if not found
  const pageId = pageIdMapping[firstPart] || 'home';

  return (
    <main id={pageId}>
      {loggedIn && <Header />}

      {user && !user?.user?.isDefaultPasswordSet && !user?.user?.registeredWithGoogle && <SetPasswordBanner />}

      <MetaTags />

      <BoardProvider>
        <Routes>
          <Route path="/workspace" element={<ProtectedRoute element={Workspace} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/" element={loggedIn ? <ProtectedRoute element={() => <Navigate to={userProfileBoardsLink} />} loggedIn={loggedIn} verified={verified} /> : <Home />} />
          <Route path="/u/:userName/profile" element={<ProtectedRoute element={UserProfile} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/u/:userName/boards" element={<ProtectedRoute element={Workspace} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/u/:userName/cards" element={<ProtectedRoute element={Cards} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/u/:userName/activity" element={<ProtectedRoute element={Activity} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/u/:userName/settings" element={<ProtectedRoute element={Settings} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/w/:workspaceSlug" element={<ProtectedRoute element={Workspaces} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/b/:boardId/:boardSlug" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/b/:boardId/" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/c/:cardId/:cardSlug" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/c/:cardId/" element={<ProtectedRoute element={BoardWrapper} loggedIn={loggedIn} verified={verified} />} />

          <Route path="/w/:shortId/boards" element={<ProtectedRoute element={Boards} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/w/:shortId/members" element={<ProtectedRoute element={Collaborators} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/w/:shortId/settings" element={<ProtectedRoute element={Settings} loggedIn={loggedIn} verified={verified} />} />
          <Route path="/notifications" element={<ProtectedRoute element={Notifications} loggedIn={loggedIn} verified={verified} />} />

          <Route path="/login" element={!loggedIn ? <Login /> : <Navigate to={userProfileBoardsLink} />} />
          <Route path="/signup" element={!loggedIn ? <Signup /> : <Navigate to={userProfileBoardsLink} />} />
          <Route path="/verify" element={<Verify />} /> {/* Only one verify route */}
          <Route path="/recovery" element={!loggedIn ? <RecoveryMail /> : <Navigate to={userProfileBoardsLink} />} />
          <Route path="/reset" element={!loggedIn ? <Reset /> : <Navigate to={userProfileBoardsLink} />} />
          <Route path="/set-password" element={loggedIn ? <SetPassword /> : <Navigate to={'/login'} />} />

          <Route path="/slack/auth" element={loggedIn ? <Slack /> : <Navigate to={userProfileBoardsLink} />} />

          <Route path="/invite/b/:boardId" element={<AcceptInvitationRequest />} />
          <Route path="/invite/w/:shortId" element={<AcceptInvitationRequest />} />

          <Route path="/legal/privacy-policy" element={<Policy />} />
          <Route path="/legal/terms-of-service" element={<Terms />} />

          {
            !verified && (
              <>
                <Route path="/verification-error" element={<VerificationError />} />
                <Route path="/verification-required" element={<VerificationRequired />} />
              </>
            )
          }
          {
            verified && (
              <Route path="/verification-success" element={<VerificationSuccess />} />
            )
          }

          <Route path="/import-export" element={<ProtectedRoute element={ExportImport} loggedIn={loggedIn} verified={verified} />} />
        </Routes>

        <Modal />
        <Toast />
        <SnackBar />
        <PopOver />
        <Tooltip />
        <PushNotification />

        {
          loggedIn && (
            <FeedbackButton />
          )
        }
      </BoardProvider>

    </main>
  );
};

export default App;