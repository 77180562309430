import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import { config } from '../../../src/config';

import './index.scss';
import Logo, { LogoLight } from '../Header/Logo';
import Google from '../icons/Google';
import { useTheme } from '../../context/ThemeContext';
import Spinner from '../Global/Spinner';

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });
  const [message, setMessage] = useState('');
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isGLoginSuccess, setIsGLoginSuccess] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [buttonText, setButtonText] = useState('Continue');

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setMessage('');
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setSubmit(true);
    setButtonText('Signing in...');

    try {
      const response = await fetch(config.API_URI + '/api/users/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(loginData),

      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      localStorage.setItem('accessToken', data.accessToken);
      setMessage('Login successful. Redirecting...');
      setIsLoginSuccess(true);

      const userResponse = await fetch(config.API_URI + '/api/users/me', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${data.accessToken}`,
        },
        credentials: 'include',
      });

      if (!userResponse.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await userResponse.json();
      const username = userData.user.username;

      setTimeout(() => {
        window.location.href = `/u/${username}/boards`;
      }, 2000);
    } catch (error) {
      console.error('Error:', error);
      setMessage('Login failed. Invalid credentials.');
      setSubmit(false);
      setButtonText('Continue');
      setTimeout(() => setMessage(''), 5000);
    }
  };


  const handleGoogleAuth = useGoogleLogin({
    onSuccess: async (response) => {

      try {
        // Call the unified backend endpoint
        const res = await fetch(config.API_URI + '/api/users/auth/google', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ token: response.access_token, action: 'login' }),
        });

        if (!res.ok) {
          const errorData = await res.json();
          throw new Error(errorData.message || 'Google authentication failed');
        }

        const data = await res.json();

        // Save access token and handle redirection
        localStorage.setItem('accessToken', data.accessToken);

        setMessage(data.message); // Displays either "Signup successful" or "Login successful"
        setIsGLoginSuccess(true);

        // Fetch user details if needed
        const userResponse = await fetch(config.API_URI + '/api/users/me', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${data.accessToken}`,
          },
          credentials: 'include',
        });

        if (!userResponse.ok) {
          throw new Error('Failed to fetch user data');
        }

        const userData = await userResponse.json();
        const username = userData.user.username;

        setTimeout(() => {
          window.location.href = `/u/${username}/boards`;
        }, 2000);
      } catch (error) {
        console.error('Error during Google authentication:', error);
        setMessage(error.message || 'Google authentication failed. Please try again.');
        setTimeout(() => setMessage(''), 5000);
      }
    },
    onError: () => {
      setMessage('Google authentication failed. Please try again.');
      setTimeout(() => setMessage(''), 5000);
    },
  });


  const { theme } = useTheme();

  const [systemTheme, setSystemTheme] = useState('light'); // Default to light

  useEffect(() => {
    // Check the system theme and set it
    const updateSystemTheme = () => {
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setSystemTheme(isDarkMode ? 'dark' : 'light');
    };

    // Initial check
    updateSystemTheme();

    // Add a listener for changes in the system theme
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateSystemTheme);

    // Cleanup the event listener on unmount
    return () => {
      mediaQuery.removeEventListener('change', updateSystemTheme);
    };
  }, []);

  // Determine which logo to display
  const currentTheme = theme === 'system' ? systemTheme : theme;


  return (
    <>
      <Helmet>
        <title>Login - Zoobbe</title>
      </Helmet>
      <div className="login-container">
        <div className="login-box">
          <h1 className="login-logo">
            {currentTheme === 'dark' && <Logo />}
            {currentTheme === 'light' && <LogoLight />}
          </h1>
          <h2 className="login-heading">Log in to continue</h2>
          <form className="login-form" onSubmit={handleLogin}>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={loginData.email}
              onChange={handleChange}
              className="login-input"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={loginData.password}
              onChange={handleChange}
              className="login-input"
              required
            />
            <button type="submit" disabled={isSubmit} className="login-button">
              {buttonText}
            </button>

            {message && !isGLoginSuccess && <p className={isLoginSuccess ? 'success-message' : 'error-message'}>{message}</p>}
          </form>
          <p className="login-or">Or continue with:</p>
          <div className="login-social-buttons">
            <button className="social-button google" disabled={isGLoginSuccess} onClick={handleGoogleAuth}>
              {
                isGLoginSuccess ? (
                  <Spinner size={20} strokeWidth={5} />
                ) : (
                  <><Google /> Google</>
                )
              }
            </button>
          </div>
          <div className="login-links">
            <Link to="/recovery" className="login-link">Can't log in?</Link>
            <span> · </span>
            <Link to="/signup" className="login-link">Create an account</Link>
          </div>
          <footer className="login-footer">
            <p className="login-atlassian">Zoobbe, Inc.</p>
            <p className="login-footer-text">One account for all Zoobbe, Inc. products</p>
            <div className="login-privacy">
              <Link to="/legal/privacy-policy" target='_blank' className="login-privacy-link">Privacy Policy</Link>
              <span> · </span>
              <Link to="/legal/terms-of-service" target='_blank' className="login-privacy-link">Terms</Link>
            </div>
            <p className="login-footer-note">
              This site is protected by reCAPTCHA and the Google <Link to="https://policies.google.com/privacy" target="_blank" className="login-privacy-link">Privacy Policy</Link> and <Link to="https://policies.google.com/terms" target='_blank' className="login-privacy-link">Terms of Service</Link> apply.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Login;
